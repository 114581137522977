import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "./../../../shared/containers/containerStepsState";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "./../../../shared/services/service.step";
import { sexData } from "./static.sexData";
import { steps } from "./../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./sex.module.scss";

class Sex extends Component {
  constructor() {
    super();

    this.state = {
      isOpenSex: false,
    };
    this.indexSex = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.sex) &&
      !this.state.isOpenSex
    )
      this.setState({ isOpenSex: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, onSetStepData, data } = this.props;
    const { isOpenSex } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.sex,
      isOpen: isOpenSex,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenSex && !has(data, ["sex"])) onSetStepData("sex");

    updateOpenStepState({ params });

    if(isOpenSex && this.indexSex === 0) {
      this.indexSex = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenSex) {
      this.indexSex = 0;
    }

  }

  handleChangeOpen(state) {
    this.setState({
      isOpenSex: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'gender',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "gender",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenSex } = this.state;
    const params = {
      className: "wrapperSex",
      currentIndex: currentIndex,
      stepIndex: steps.sex,
    };
    let startTime = new Date();

    return isOpenSex ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <h1 className={styles.firstTitle}>Select your gender</h1>
          <div className={styles.genderWrapper}>
            {Object.keys(sexData).map((key, index) => {
              return (
                <RadioCheckButton
                  key={index}
                  isChecked={
                    get(data, ["sex", "value"], null) === sexData[key].value
                  }
                  onClick={
                    get(data, ["sex", "value"], null) !== sexData[key].value
                      ? onUpdateStepData.bind(
                          this,
                          "sex",
                          "value",
                          get(sexData, [key, "value"])
                        )
                      : () => {}
                  }
                  onClickNextStep={onToNextStep !== false ? () => {
                    setTimeout(() => {
                      onToNextStep(); 
                      this.dataLayerAddEvent(get(sexData, [key, "value"]), startTime
                      )}, 400); } : () => {}
                  }

                >
                  <img src={sexData[key].img} alt="gender" />
                  <div className={styles.genderText}>{sexData[key].title}</div>
                </RadioCheckButton>
              );
            })}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withStepsState(Sex);
