import React, { Component } from 'react';
import { get } from "lodash";

import { withStepsState } from "../../../shared/containers/containerStepsState";
import { withLoader } from "../../../shared/containers/containerLoader";

import { checkIsValidMedications } from "./static.medicationsData";
import ButtonFill from "../../../shared/items/controls/buttonFill";
import styles from "./medications.module.scss";

class MedicationsNextStepButton extends Component {
  componentDidUpdate() {
    const { data, onSetSuccessValidate, onSetFailValidate, medicationsData } = this.props

    if (checkIsValidMedications(data) && !get(data, ["medications", "isValid"], false))
      onSetSuccessValidate("medications");

    if (!checkIsValidMedications(data) && get(data, ["medications", "isValid"], false))
      onSetFailValidate("medications");
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();
    let medications = [];

    if(this.props.data.medications.vitamins) {
      medications.push(this.props.medicationsData.vitamins.value)
    }
    if(this.props.data.medications.hormones) {
      medications.push(this.props.medicationsData.hormones.value)
    }
    if(this.props.data.medications.antibiotics) {
      medications.push(this.props.medicationsData.antibiotics.value)
    }
    if(this.props.data.medications.other) {
      medications.push(this.props.medicationsData.other.value)
    }
    if(this.props.data.medications.notMedications) {
      medications.push(this.props.medicationsData.notMedications.value)
    }

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'medications',
      'quiz_answer': medications,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  render() {
    const { data, onToNextStep, startTimeMedications } = this.props;

    return (
      <div className={styles.medicationsBth}>
        <ButtonFill disabled={!get(data, ["medications", "isValid"], false)} onClick={
          () => {
            onToNextStep();
            this.dataLayerAddEvent(startTimeMedications);
          }
        }>
          {this.props.children}
        </ButtonFill>
      </div>

    )
  }
}

export default withStepsState(MedicationsNextStepButton);