import React, { Component } from 'react'
import { has } from "lodash"

import styles from "./radioСhoiceWrapper.module.scss"

class RadioСhoiceWrapper extends Component {
  componentDidMount() {
    const { name, handleSetDefault, defaultValue, valueStore } = this.props

    if (!has(valueStore, [name]) && handleSetDefault)
      handleSetDefault(name, defaultValue)
  }

  render() {
    return (
      <div className={styles.radioСhoiceWrapper}>
        {this.props.children}
      </div>
    )
  }
}

export default React.memo(RadioСhoiceWrapper)