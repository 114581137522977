import React, { Component } from "react";
import { withStepsState } from "./../../../shared/containers/containerStepsState";
import { withAppStepsState } from "../../../shared/containers/containerAppStepsState";
import { withClearStepsData } from "../../../shared/containers/containerClearStepsData";
import { withResultPageState } from "../../../shared/containers/containerResultPageState";
import { isCurrentStep } from "./../../../shared/services/service.step";
import { steps } from "./../../../shared/static.steps";
import HeaderAppSteps from "./headerAppSteps";
import Sex from "./../sex/sex";
import Result from "./../result/result";
import Ailments from "./../ailments/ailments";
import Old from "./../old/old";
import Height from "./../height/height";
import CurrentWeight from "../currentWeight/currentWeight";
import GoalWeight from "../goalWeight/goalWeight";
import BodyType from "../bodyType/bodyType";
import FitnesLevel from "../fitnesLevel/fitnesLevel";
import TypicalDay from "../typicalDay/typicalDay";
import WorkHard from "../workHard/workHard";
import WorkTime from "../workTime/workTime";
import Mail from "./../mail/mail";
import Sleep from "../sleep/sleep";
import Water from "../water/water";
import JobActive from "../jobActive/jobActive";
import VisibleResultProgress from "../visibleResultProgress/visibleResultProgress";
import ImportantEvent from "../importantEvent/importantEvent";
import ImportantEventDate from "../importantEventDate/importantEventDate";

import Lifestyle from "../lifestyle/lifestyle";
import Helps from "../helps/helps";
import IdealWeight from "../idealWeight/idealWeight";
import RelateToLevel from "../relateToLevel/relateToLevel";
import RelateToSurrounding from "../relateToSurrounding/relateToSurrounding";
import RelateToTime from "../relateToTime/relateToTime";
import RelateToWeight from "../relateToWeight/relateToWeight";
import RelateToMotivation from "../relateToMotivation/relateToMotivation";
import AchievedProgress from "../achievedProgress/achievedProgress";

import Medications from "../medications/medications";
import GoalsPlanProgress from "../goalsPlanProgress/goalsPlanProgress";
import GoalsPlan from "../goalsPlan/goalsPlan";
import Workouts from "../workouts/workouts";
import SuccessStory from "../successStory/successStory";
import SuccessStoryResult from "../successStoryResult/successStoryResult";
import variables from "./../../../assets/styles/values.scss";
import styles from "./stepsWrapper.module.scss";

function getParameterFromURL(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

class AppStepsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpenAppSteps, onClearData } = this.props;
    const { isOpen } = this.state;

    if (prevProps.isOpenAppSteps && !isOpenAppSteps)
      setTimeout(
        function () {
          this.setState({ isOpen: false });
        }.bind(this),
        variables.animateSteps
      );

    if (!prevProps.isOpenAppSteps && isOpenAppSteps)
      this.setState({ isOpen: true });

    if (prevState.isOpen && !isOpen) onClearData();
  }

  render() {
    const { onOpenApp, openResultPage } = this.props;
    const wrapperClassName = onOpenApp
      ? openResultPage
        ? styles.appStepsWrapperFinished
        : styles.appStepsWrapper
      : styles.appStepsWrapperHidden;

    const stepResult = isCurrentStep(this.props.currentIndex, steps.result);
    const stepEmail = isCurrentStep(this.props.currentIndex, steps.mail);
    const successStoryResult = isCurrentStep(
      this.props.currentIndex,
      steps.successStoryResult
    );

    return this.state.isOpen ? (
      <div className={wrapperClassName}>
        {(!openResultPage && !stepResult && this.props.currentIndex < 28) ||
        (this.props.currentIndex === 37 && !stepEmail) ? (
          <div className={styles.container_herader}>
            <HeaderAppSteps
              currentIndex={this.props.currentIndex}
              stepResult={stepResult}
              stepEmail={stepEmail}
              successStoryResult={successStoryResult}
            />
          </div>
        ) : null}

        {!openResultPage ? (
          <div
            className={`${styles.container_stepContent} ${styles.container_stepContent_v2}`}
          >
            <Result />
            <FitnesLevel />
            <WorkHard />
            <WorkTime />
            <TypicalDay />
            <Sleep />
            <Water />
            <JobActive />
            <Sex />
            <VisibleResultProgress />
            <Old />
            <BodyType />
            <Height />
            <CurrentWeight />
            <GoalWeight />
            <ImportantEvent />
            <ImportantEventDate />
            <Lifestyle />
            <Helps />
            <IdealWeight />
            <RelateToLevel />
            <RelateToSurrounding />
            <RelateToTime />
            <RelateToWeight />
            <RelateToMotivation />
            <AchievedProgress />
            <Ailments />
            <Medications />
            <GoalsPlanProgress />
            <GoalsPlan />
            <Workouts />
            <SuccessStory />
            <SuccessStoryResult />
            <Mail />
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

export default withStepsState(
  withAppStepsState(withClearStepsData(withResultPageState(AppStepsWrapper)))
);
