import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { bodyTypeData } from "./static.bodyTypeData";
import { sexData } from "./../sex/static.sexData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./bodyType.module.scss";

class BodyType extends Component {
  constructor() {
    super();

    this.state = {
      isOpenBodyType: false,
      itemsData: {},
    };
    this.indexBodyType = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.bodyType) &&
      !this.state.isOpenBodyType
    )
      this.setState({ isOpenBodyType: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenBodyType } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.bodyType,
      isOpen: isOpenBodyType,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenBodyType && !has(data, ["bodyType"])) onSetStepData("bodyType");

    updateOpenStepState({ params });

    if(isOpenBodyType && this.indexBodyType === 0) {
      this.indexBodyType = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenBodyType) {
      this.indexBodyType = 0;
    }
  }

  handleChangeOpen(state) {
    const { data } = this.props;
    this.setState({
      isOpenBodyType: state,
      itemsData: this.switchResultItems(
        get(data, ["sex", "value"], sexData.female.value)
      ),
    });
  }

  switchResultItems(value) {
    switch (value) {
      case sexData.male.value:
        return bodyTypeData.male;
      case sexData.female.value:
        return bodyTypeData.female;
    }
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'bodyType',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "bodyType",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenBodyType, itemsData } = this.state;
    const params = {
      className: "wrapperBodyType",
      currentIndex: currentIndex,
      stepIndex: steps.bodyType,
    };
    let startTime = new Date();

    return isOpenBodyType ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>What’s your body type?</FirstTitle>

          {Object.keys(itemsData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["bodyType", "value"], null) ===
                  itemsData[key].value
                }
                onClick={
                  get(data, ["bodyType", "value"], null) !==
                  itemsData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "bodyType",
                        "value",
                        itemsData[key].value
                      )
                    : () => {}
                }
                img={true}
                onClickNextStep={onToNextStep !== false ? () => {
                  
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(itemsData, [key, "value"]), startTime
                    ) } : () => {}
                }
              >
                <div className={styles.buttonText}>
                  <div className={styles.title}>{itemsData[key].title}</div>
                  <div className={styles.description}>
                    {itemsData[key].description}
                  </div>
                </div>

                <img
                  className={styles.image}
                  src={itemsData[key].icon}
                  alt="icon"
                />
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(BodyType);
