import { has, get } from "lodash"

import h1 from "./img/h1.png"
import h2 from "./img/h2.png"
import h3 from "./img/h3.png"
import h4 from "./img/h4.png"
import h5 from "./img/h5.png"
import h6 from "./img/h6.png"

export const checkIsValidHelps = data => {
    let isValid = false

    Object.keys(helpsData).map((key) => {
        if (has(data, ["helps", key]) && get(data, ["helps", key], false))
            isValid = true
    })

    return isValid
}

export const helpsData = {
    tips: {
        value: "tips",
        title: "Healthy lifestyle tips",
        icon: h1,
    },
    seeing: {
        value: "seeing",
        title: "Seeing my body change",
        icon: h2,
    },
    training: {
        value: "training",
        title: "Training progress",
        icon: h3,
    },
    supportive: {
        value: "supportive",
        title: "Supportive community",
        icon: h4,
    },
    guidelines: {
        value: "guidelines",
        title: "Coach's guidelines",
        icon: h5,
    },
    appleRings: {
        value: "appleRings",
        title: "Apple Activity rings",
        icon: h6,
    },
}

export const defaultFasting = false