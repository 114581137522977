import React, { Component } from 'react'
import { get, has, isNull } from "lodash"
import { InputNumber } from 'antd';
import { debounce } from 'throttle-debounce';

import variables from "./../../../assets/styles/values.scss"
import styles from "./inputNumberWrapper.module.scss"

class InputNumberWrapper extends Component {
  constructor(props) {
    super(props);
    this.inputFocusRef = React.createRef();    
    this.handleChangeDebounce = debounce(variables.delayInputTrigger, !!this.props.onChange ? this.props.onChange.bind(this) : () => { })
  }

  componentDidUpdate() {
    this.inputFocusRef.current.focus();
  }

  componentDidMount() {
    const { name, onChange, keyName, focus } = this.props
    let tag = document.getElementById(name)

    tag.setAttribute("type", "tel")
    tag.setAttribute("pattern", "[0-9]*")
    tag.setAttribute("spellcheck", "false")

    if (!has(this.props, "valueStore") && onChange)
      onChange(name, keyName, get(this.props, "defaultValue", ""))

    // if (focus)
    //   tag.focus()
    this.inputFocusRef.current.focus();
  }

  handleChange(value) {
    const { name, keyName, valueStore } = this.props

    if (value !== valueStore && !isNull(value)) {
      this.handleChangeDebounce(name, keyName, value)
    }

  }

  render() {
    const { name } = this.props

    return (
      <div className={styles.inputNumberWrapper}>
        <InputNumber
          className={styles.input}
          id={name}
          placeholder={get(this.props, "placeholder", "")}
          onChange={this.handleChange.bind(this)}
          value={get(this.props, "valueStore", "")}
          disabled={get(this.props, "disabled", false)}
          ref={this.inputFocusRef}
          // autoFocus
        />
      </div>
    )
  }
}

export default React.memo(InputNumberWrapper)