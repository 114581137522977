import { isNull, get } from "lodash"

const BOTTOM_LINE = 2
const TOP_LINE = 63
const BELOW_BOTTOM_LINE_MESSAGE = "The mail is too-short or empty."
const ABOVE_TOP_LINE_MESSAGE = "The mail is too long."

export const checkIsValidMail = data => {
    return validateMailStep(get(data, ["mail", "value"], "")) && !data.mail.isValid
}

export const isFailureMailValidation = data => {
    return !validateMailStep(get(data, ["mail", "value"], ""))
        && get(data, ["mail", "validString"], "") !== getValidateMailMessage(get(data, ["mail", "value"], ""))
}

function validateEmail(email) {
    if (email && (email.length >= TOP_LINE)) return false;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const validateMailStep = value => {
    return validateEmail(value)
}

export const getValidateMailMessage = value => {
    let message = "Please fill in a valid email address."
    

    if (!isNull(value))
        // if (value.length <= BOTTOM_LINE)
        //     message = BELOW_BOTTOM_LINE_MESSAGE
        // else if (value.length >= TOP_LINE)
        //     message = ABOVE_TOP_LINE_MESSAGE

        if (value.length < 1)
        message = ""

    return message
}