import React, { Component } from 'react'
import { get } from "lodash"

import { withStepsState } from "../../../shared/containers/containerStepsState"
import { withLoader } from "../../../shared/containers/containerLoader"

import { 
  checkIsValidImportantEventDate,
  validateImportantEventDateStep } from "./static.importantEventDateData"
import ButtonFill from "../../../shared/items/controls/buttonFill"

class ImportantEventDateNextStepButton extends Component {
  componentDidUpdate() {
    const { data, onSetSuccessValidate, onSetFailValidate } = this.props

    if (checkIsValidImportantEventDate(data)) {
      onSetSuccessValidate("importantEventDate")
    } 
    
    if(!validateImportantEventDateStep(get(data, ['importantEventDate', 'value']), "") && data.importantEventDate.isValid) {
      onSetFailValidate("importantEventDate")
    }
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'EventDate',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
    // fixFocus(false);
  }

  render() {
    const { data, onToNextStep, startTimeImportantEventDate } = this.props;

    return (
      <ButtonFill disabled={!get(data, ["importantEventDate", "isValid"], false)} onClick={
        () => {
          setTimeout(() => {
            onToNextStep(); 
            this.dataLayerAddEvent(get(data, ["importantEventDate", "value"]), startTimeImportantEventDate);
          }, 100); }
      }>
        {this.props.children}
      </ButtonFill>
    )
  }
}

export default withStepsState(withLoader(ImportantEventDateNextStepButton))