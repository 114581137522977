import React from 'react'

import { withMenuState } from "./../../containers/containerMenuState"

import styles from "./menuButton.module.scss"

const MenuButton = props => {
  const { isOpenMenu, onOpenMenu, onCloseMenu } = props

  return (
    <div
      className={isOpenMenu ? styles.menuButtonOpen : styles.menuButton}
      onClick={isOpenMenu ? onCloseMenu : onOpenMenu}
    >
      <span className={styles.line1}></span>
      <span className={styles.line2}></span>
      <span className={styles.line3}></span>
    </div>
  )
}

export default withMenuState(MenuButton)