import React from 'react';
import { Link } from "react-router-dom"

import FirstTitle from "./../../shared/items/static/firstTitle"

import styles from './termsPolicies.module.scss'

const TermsPolicies = () => {
  return (
    <div className={styles.termsPolicies}>
      <div className={styles.container_firstTitle}>
        <FirstTitle>Terms & Policies</FirstTitle>
      </div>

      <div className={styles.container_termsPolicies}>
        <h2><strong>Effective 1 May 2019</strong></h2>
        <p>We are committed to respecting your data privacy and providing transparency about our data practices. This Privacy Policy (this &ldquo;Privacy Policy&rdquo;) explains how Verv Inc. (&ldquo;Company&rdquo; or &ldquo;we&rdquo; or &ldquo;us&rdquo;) collects, stores, uses, transfers and discloses Personal Data from our users (&ldquo;you&rdquo;) in connection with the Company mobile application and related services (collectively, the &ldquo;App&rdquo;).</p>
        <p>We reserve the right to and may change this Privacy Policy from time to time. If we make any material changes, we will notify you by email (if you provide us any email address), through the App, or by publishing a notice on our website, or by presenting you with a new version of this Privacy Policy for you to accept, if we, for example, add new processing activities or collect some additional Personal Data from you.</p>
        <p>Your continued use of the App after the effective date of an updated version of the Privacy Policy will indicate your acceptance of the Privacy Policy as modified. In some cases, you will have to explicitly accept changes to Privacy Policy to continue using the App. We strongly encourage you to review our website from time to time for the latest information on our data privacy practices. If you do not accept the terms of the Privacy Policy, we ask that you do not use the App. Please exit the App immediately and delete it if you do not agree to the terms of this Privacy Policy.</p>
        <h2><strong>1. PERSONAL DATA AND INFORMATION WE COLLECT ABOUT YOU</strong></h2>
        <p><strong>We collect the following Personal data and information from you:</strong></p>
        <ul>
          <li>Contact Information such as name and email address.</li>
          <li>Preferences Information you provide such as product or content interests, or communication or marketing preferences.</li>
          <li>Usage activity about how you interact with us such as purchase history, what content you viewed, and which areas of our app you visited.</li>
          <li>Fitness or Health information such as your weight, height, consumption of water.</li>
          <li>Device and Usage Information that may include data specific to your mobile device (e.g., make, model, operating system, advertising identifier and similar information); information about your use of features, functions, or notifications on the device; and signal strength relating to Wi-Fi or Bluetooth functionality, temperature, battery level, and similar technical data.</li>
          <li>Demographic information such as age and gender.</li>
          <li>Information about your mobile device such as your mobile device id (similar to a website IP address), device type, and mobile service carrier.</li>
        </ul>
        <p>We may collect this Personal data and information even if you are not logged in to the application. </p>
        <p>You will also have an option to permit us to import and export the App data from third-party services such as Apple HealthKit, among others. Such information may include: weight, gender, height, heartbeat rate, number of steps/distance travelled, and other information about your health.</p>
        <ul>
          <li>We do not use the information obtained via the HealthKit framework for advertising or similar purposes.</li>
          <li>We do not disclose any information obtained via HealthKit to third parties without expressed permission from the users.</li>
          <li>We do not sell information obtained via HealthKit to advertising platforms, data brokers or information resellers.</li>
        </ul>
        <p>Permitting us to access third party information can help you to maximize your App experience, and we will handle any such third-party information in full accordance with this Privacy Policy.</p>
        <p>If the information covered by this Section is aggregated or de-identified so it is no longer reasonably associated with an identified or identifiable natural person, we may use it for any business purpose. To the extent information covered by this Section is associated with an identified or identifiable natural person and is protected as personal data under applicable data protection laws, it is referred to in this Privacy Policy as <strong>&ldquo;Personal Data&rdquo;</strong>. We use pseudonymization for particular types of Personal Data. Please bear in mind that provisions of Section 3 do not apply to pseudonymized Personal Data.</p>
        <p><strong>YOUR CONSENT FOR PROCESSING OF YOUR PERSONAL DATA.</strong> By installing the App and creating a profile in the App, (or by explicitly accepting this Privacy Policy and explicitly consenting to the processing of your Personal Data) you explicitly consent that:</p>
        <ul>
          <li>WE MAY STORE AND PROCESS YOUR PERSONAL DATA YOU PROVIDE THROUGH THE USAGE OF THE APP AND THROUGH THE ACCOUNT CREATION PROCESS SOLELY FOR THE PURPOSE OF PROVIDING SERVICES TO YOU, TO IMPROVE OUR SERVICE FEATURES AND OTHER PURPOSES INDICATED IN SECTION 2 OF THIS PRIVACY POLICY. SUCH SERVICES MAY INCLUDE SENDING YOU INFORMATION AND REMINDERS THROUGH THE APP OR TO THE EMAIL ADDRESS YOU PROVIDED TO US.</li>
          <li>PERSONAL DATA YOU PROVIDE TO US THROUGH THE USE OF THE APP INCLUDES PERSONAL DATA YOU ENTER INTO THE APP, SUCH AS YOUR ACCOUNT DATA (E.G. YOUR NAME AND EMAIL ADDRESS), AND YOUR HEALTH DATA (E.G. BODY MEASUREMENTS, PHYSICAL ACTIVITY AND OTHERS). DEPENDING ON THE DATA YOU PROVIDE, IT MAY ALSO CONTAIN INFORMATION ABOUT YOUR GENERAL HEALTH (E.G. WEIGHT, AND OTHERS).</li>
          <li>WE WILL NOT TRANSMIT ANY OF YOUR HEALTH DATA TO THIRD PARTIES UNLESS WE HAVE ASKED FOR YOUR EXPLICIT CONSENT.</li>
        </ul>
        <p>YOU CAN ALWAYS WITHDRAW YOUR CONSENT TO PROCESSING OF YOUR PERSONAL DATA AND (OR) ITS TRANSMISSION TO THIRD PARTIES BY CONTACTING US AT <a href="mailto:SUPPORT@VERV.COM">SUPPORT@VERV.COM</a>.</p>
        <h2><strong>2. HOW WE USE YOUR PERSONAL DATA AND INFORMATION</strong></h2>
        <p>We may use your information, including your Personal Data, and by accepting this Privacy Policy you provide us with your explicit consent, for the following purposes:</p>
        <ul>
          <li>to analyze, operate, maintain and improve the App and marketing activities, to add new features and services to the App;</li>
          <li>to assess your needs to determine suitable products or services;</li>
          <li>to send product updates or warranty information;</li>
          <li>to respond to your comments, questions and requests and provide customer service;</li>
          <li>to send you push notifications, both internal and external. IF YOU DO NOT WANT TO RECEIVE THESE NOTIFICATIONS, YOU CAN MANAGE YOUR PREFERENCE EITHER THROUGH YOUR DEVICE OR APP SETTINGS DEPENDING ON YOUR DEVICE TYPE. YOU CAN ALSO CONTACT US AT <a href="mailto:support@verv.com"><strong>support@verv.com</strong></a></li>
          <li>to send you marketing communications;</li>
          <li>to improve our App and marketing efforts, to conduct research and analysis, including for scientific and academic research purposes;</li>
          <li>for any other purposes disclosed to you at the time we collect Personal Data or any other purposes indicated in this Privacy Policy.</li>
        </ul>
        <p><strong>Choice/Opt-Out.</strong> You may opt-out of receiving our newsletter or marketing emails from us by following the instructions on how to unsubscribe included in each newsletter or marketing email, or you can contact us at <a href="mailto:support@verv.com">support@verv.com</a>.</p>
        <p><strong>Social Networks.</strong> We enable you to create a profile, and share information such as messages, photos, and videos with others within your network. We cannot control the actions of others with whom you may choose to share your pages and information. Also, we cannot guarantee that the content you post on our App will not be viewed by others outside your network.</p>
        <p>Any information (including Personal Data) you share in any online community area or online discussion is by design open to the public and is not private, unless provided differently in the App settings. You should think carefully before posting any Personal Data in any public forum. What you post can be seen, disclosed to, or collected by third parties and may be used by others in ways we cannot control or predict, including to contact you for unauthorized purposes. As with any public forum on any site, the information you post may also show up in third-party search engines.</p>
        <p>If you mistakenly post Personal Data in our community areas and would like it removed, you can send us an email as listed below to request that we remove it. In some cases, we may not be able to remove your Personal Data, e.g. for technical reasons.</p>
        <h2><strong>3. YOUR RIGHTS</strong></h2>
        <p><strong>Modification, correction, and erasure.</strong> You are able to modify, correct, erase and update your Personal Data in the App account settings or, if that is impossible, by writing to us at <a href="mailto:support@verv.com">support@verv.com</a>.</p>
        <p><strong>Opt-out.</strong> If you do not want any of your Personal Data to be processed by us or any third parties, you may opt out at any time by contacting us at <a href="mailto:support@verv.com">support@verv.com</a>. For other options for third party tracking limitation please also refer to Section 4 of this Privacy Policy. </p>
        <p><strong>Access.</strong> You have a right to access your Personal Data you insert into the App and ask us about what kind of Personal Data we have about you. You can do this by using the app settings or by writing to <a href="mailto:support@verv.com">support@verv.com</a>.</p>
        <p><strong>EU residents.</strong> Individuals residing in the countries of the European Union have certain statutory rights in relation to their personal data introduced by the General Data Protection Regulation (the &ldquo;GDPR&rdquo;). Subject to any exemptions provided by law, you may have the right to request access to Personal data (including in a structured and portable form), as well as to seek to update, delete or correct Personal data:</p>
        <ul>
          <li><strong>Rectification of Personal Data and Restriction of Processing.</strong> You are responsible for ensuring the accuracy of your Personal Data that you submit to the App. Inaccurate information will affect your experience when using the App Web Sites and tools and our ability to contact you as described in this Privacy Policy. If you believe that your Personal Data is inaccurate, you have right to contact us and ask us to correct such Personal Data by contacting us at <a href="mailto:support@verv.com">support@verv.com</a>. You shall also have the right to request restriction of processing of your Personal Data, if you contest the accuracy of the Personal Data, and we need some time to verify its accuracy.</li>
          <li><strong>Access to your Personal Data and Data Portability.</strong> The App gives you the ability to access and update Personal Data within the App and your account settings. You shall be entitled to request information about whether we have any Personal Data about you, to access your Personal data (including in a structured and portable form) by using your app settings or by simply writing to us at <a href="mailto:support@verv.com">support@verv.com</a>.</li>
          <li><strong>Erasure of your Personal Data.</strong> If you believe that your Personal Data is no longer necessary in relation to the purposes for which it was collected or otherwise processed, or in cases where you have withdrawn your consent or object to the processing of your Personal Data, or in cases where the processing of your Personal Data does not otherwise comply with the GDPR, you have right to contact us and ask us to erase such Personal Data as described above. You can simply write to us at <a href="mailto:support@verv.com">support@verv.com</a>. Please be aware that erasing some Personal Data inserted by you may affect your ability to use the App and its features. Erasure of some Personal Data may also take some time due to technical reasons.</li>
          <li><strong>Right to object processing of your Personal Data.</strong> You can object to processing your Personal Data and stop us from processing your Personal data by simply writing to us at <a href="mailto:support@verv.com">support@verv.com</a>. Please be aware that erasing some Personal Data inserted by you may affect your ability to use the App and its features.</li>
          <li><strong>Notification requirements.</strong> We commit to notify you within a reasonable period of time and your data protection authority within the timeframe specified in applicable law (72 hours) about any personal data breaches in the App.</li>
          <li><strong>Data Protection Authorities. </strong>Subject to GDPR requirements, you also have the right to (i) restrict our use of Personal Data and (ii) lodge a complaint with your local data protection authority about any of our activities that you deem are not compliant with GDPR.</li>
        </ul>
        <p>Please keep in mind that in case of a vague access, erasure, objection request or any other request in exercise of the mentioned rights we may engage the individual in a dialogue so as to better understand the motivation for the request and to locate responsive information. In case this is impossible, we reserve the right to refuse granting your request.</p>
        <p>Following the provisions of GDPR we might also require you to prove your identity (for example, by requesting an ID or any other reasonable proof of identity) in order for you to invoke the mentioned rights, specifically if you exercise them in respect to special categories of Personal Data like data about health. This is made to ensure that no rights of third parties are violated by your request, and the rights described in this section are exercised by an actual Personal Data subject or an authorized person.</p>
        <h2><strong>4. SHARING YOUR PERSONAL DATA AND INFORMATION</strong></h2>
        <p><strong>Sharing with third parties.</strong> We will share your Personal Data and information with third parties only in the ways that are described in this Privacy Policy.</p>
        <p><strong>We may disclose your Personal Data:</strong></p>
        <ul>
          <li>as required by law, such as to comply with a subpoena, or similar legal process.</li>
          <li>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</li>
          <li>if we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</li>
          <li>to any other third party with your prior explicit consent to do so.</li>
        </ul>
        <p>We share the information we collect with third-party business partners who commit to comply with applicable laws concerning their usage of your Personal Data (for example, GDPR for the EU residents). Our third-party business partners may share your Personal Data with their own third-party business partners, granted they have appropriate data protection safeguards in place, and use the information either for themselves or on behalf of their own business partners to:</p>
        <ul>
          <li>operate and improve their services;</li>
          <li>provide you with advertisements and information for products and services in this app or on other apps or websites based on variables such as your location, and assess your level of engagement with them;</li>
          <li>carry out other purposes that are disclosed to you and to which you consent.</li>
        </ul>
        <p>Depending on a service you use, we may share some of your Personal Data and information with third-party business partners who perform analytical and other tasks for us (in some jurisdictions such parties may qualify for being a &lsquo;processor&rsquo; under applicable laws). These are the third-party services with whom, among others, we may share some of your Personal Data&nbsp;and information:</p>
        <ol>
          <li><strong>Appsflyer.</strong>&nbsp;Appsflyer is a mobile marketing platform. We may share certain non-identifiable information about you and some Personal Data (but never any data related to health without your explicit consent) in order to carry out marketing activities and provide you with better and more targeted service. We share the following data with Appsflyer: your IDFA or Android advertising ID, IP address, your user sessions, device information (model, location, OS version, CPU, screen size, etc.), application information (version, build number, etc.), information on launching, updating, installing the App. Once data is collected, it is not merged with any other data for personal identification purposes. You may choose at any time that such data shall no longer be collected in the future. If so, please simply use the&nbsp;<a href="https://www.appsflyer.com/optout">opt-out option of Appsflyer</a>.</li>
          <li><strong>MoPub.</strong>&nbsp;Mopub is a subsidiary of Twitter, Inc. and a monetization platform. We may share non-identifiable information about you and some Personal Data (but never any data related to health without your explicit consent) in order to carry out marketing and adds activities. Learn more about MoPub opt-out information&nbsp;<a href="https://www.mopub.com/optout/">here</a>.</li>
          <li><strong>Facebook.</strong>&nbsp;We use Facebook as our advertising partner to display advertising in our App or to manage our advertising on other sites. We are using the following Facebook services: Ads manager, Lookalike audience, Campaign Planner, Creative Hub, Business Manager, Page Posts, Ads Reporting, Monetization Manager, Pixels, Audience Insights.&nbsp;We use Facebook&rsquo;s Software Development Kit (SDK) within our apps in order to link various Facebook services with our apps. For example, this enables users to share content from our apps within their Facebook timeline. Further information about the Facebook SDK within iOS can be found&nbsp;<a href="https://developers.facebook.com/docs/ios">here</a>; for Android&nbsp;<a href="https://developers.facebook.com/docs/android">here</a>.&nbsp;We may share certain non-identifiable information about you and some Personal Data (but never any data related to health without your explicit consent) for the purposes described above, including to carry out marketing activities and provide you with better and more targeted service. We share the following data with Facebook: your IDFA or Android advertising ID, your user sessions, device information (model, location, OS version, CPU, screen size, etc.), application information (version, build number, etc.), information on launching, updating, installing the App and attribution source. Learn more about Facebook ads <a href="https://www.facebook.com/help/516147308587266/?helpref=hc_fnav">here</a>. Facebook may use data we provide for its own advertising purposes in accordance with the&nbsp;<a href="https://www.facebook.com/about/privacy/">Facebook Data Policy</a>.</li>
          <li><strong>Fabric.</strong>&nbsp;We use Fabric, an analytics company and a Google subsidiary, to better understand your use of the App. For example, Fabric may use device identifiers that are stored on your mobile device and allow us to analyze your use of the App in order to improve our App features. We never share with Fabric data related to health without your explicit consent. Read more in&nbsp;<a href="https://fabric.io/dpst.pdf">Fabric Data Processing and Security Terms.</a></li>
          <li><strong>Crashlytics.&nbsp;</strong>Crashlytics, a Google business division, provides us crash reporting solution that we use in order to track when the App crashes or works improperly on your device. Crashlytics may collect certain device identifiers in order to understand on what devices and under which circumstances the App does not work or crashes. We share the following data with Crashlytics: your application information (version, build number, etc.), device information (model, location, country, OS version, installation date, time zone, carrier, etc.), data on crushes (frequency, number, etc.), and your user session. We never share with Crashlytics data related to health without your explicit consent. Diagnostic information is subject to the Crashlytics Privacy Policy which is available&nbsp;<a href="https://try.crashlytics.com/terms/privacy-policy.pdf">here</a>.</li>
          <li><strong>Zendesk.&nbsp;</strong>We use Zendesk as an intermediary for user support services. Zendesk may collect some of your Personal data (like contact information) in order to connect us with you. We never share with Zendesk data related to health without your explicit consent.&nbsp;<a href="https://www.zendesk.com/company/customers-partners/privacy-policy/">See more</a>&nbsp;about Zendesk and its Privacy Policy.</li>
          <li><strong>Firebase.&nbsp;</strong>Firebase is an analytics product from Google, Inc. that allows us to track crashes of the App, monitor events in the App, provide us stats regarding the use of the App. To track and analyze behavior of our App&rsquo;s users (in particular, how they react to changes of the App structure, text or any other component), we also use Firebase Remote Config. Firebase Remote Config is an A/B testing and configuration service provided by Google, which also enables us to tailor the content that our App&rsquo;s users see (for example, it allows us to show different onboarding screens to different users). Firebase may collect certain device identifiers in order to understand on what devices and under which circumstances particular incidents and events happen. We share the following data with Firebase: your IDFA or Android advertising ID, data on installing the App, your actions in the App (for example, tapping particular buttons like whether to receive notifications or not), including actions with additional parameters, opening particular screens, starting and cancelling a trial period, starting and cancelling subscription, passing the onboarding screen, completing registration, different technical events (for example, whether you have read a manual or not). We never share with Firebase data related to health without your explicit consent. Read about Privacy and Security in Firebase&nbsp;<a href="https://firebase.google.com/support/privacy/">here</a>.</li>
          <li><strong>Leanplum.&nbsp;</strong>We use Leanplum, which is a mobile marketing and analytics platform that allows us to customize and send messages to our users (in particular, via email) and notifications right to your phone. Leanplum enables us to choose the categories of users, which will receive such notification (for example, we can send notifications directly to users that chose &ldquo;Workout Hero&rdquo; fitness activity status). Leanplum also provides you with an opportunity to&nbsp;<a href="https://www.leanplum.com/privacy/">opt out</a>&nbsp;at any time. Among others, we share the following data with Leanplum: your IDFA or Android advertising ID, your application information (version, build number, etc.), device information (model, location, country, OS version, installation date, time zone, carrier, etc.), data on installing the App, your actions in the App (for example, tapping particular buttons like whether to receive notifications or not), including actions with additional parameters, your actions with particular screens and information on visiting particular pages, starting and cancelling a trial period, starting and cancelling subscription, passing the onboarding screen, completing registration, different technical events (for example, whether the user has read a manual or not), language, login method, date of birth, gender, details of your interaction with content. We never share with Leanplum data related to health without your explicit consent.</li>
          <li><strong>RockMyRun</strong>. We partner with RockMyRun, a music provider to integrate music services into the App. At the App start, we initialize RockMyRun&rsquo;s SDK to check if it works properly. We also transmit data about your music preferences to be able to create best-matching popular music lists for your motivation. Read more info&nbsp;<a href="https://www.rockmyrun.com/">here</a>.</li>
        </ol>
        <p>Our third-party partners are either EU-based or certified under EU-US Privacy Shield Framework that ensures that European data protection requirements are met. We commit to execute formal data processing agreements with any of our partners to ensure your privacy rights are observed. The privacy policy of these services can be found on their respective websites. Be informed that some third-party service providers may have their servers located in the United States.</p>
        <p>You can use your <strong>device settings</strong> in iOS or Android in order to <strong>stop sharing</strong> your IDFA or Android advertising ID with any third parties. In any case, unless otherwise is provided by this Section, sharing of your IDFA or Android advertising ID does not result in any advertising that might be shown to you by the above services. The use of these identifiers is strictly limited to the purposes indicated in this Section of the Privacy Policy.</p>
        <p>BY USING THE APP, YOU CONSENT THAT WE MAY USE COOKIES AND THIRD-PARTY SERVICES AND COLLECT YOUR USAGE (INCLUDING FOR PROCESSING BY THIRD-PARTY SERVICE PROVIDERS) AND SOME PERSONAL DATA UNDER A UNIQUE IDENTIFIER, FOR THE PURPOSES OF TRACKING, ANALYSIS, AND IMPROVEMENT OF THE APP. BY USING OUR SERVICE, YOU EXPLICITLY CONSENT TO THE USE AND PROCESSING OF YOUR DATA COLLECTED AS DESCRIBED ABOVE.</p>
        <p><strong>Aggregated Information.</strong> We may also share aggregated, anonymized or de-identified information, which cannot reasonably be used to identify you. For example, we may share, including, without limitation, in articles, blog posts and scientific publications, general age demographic information and aggregate statistics about certain activities or symptoms from data collected to help identify patterns across users.</p>
        <h2><strong>5. DATA SECURITY</strong></h2>
        <p>Security of your Personal Data is important to us. When you provide your Personal data that is considered to be sensitive or falls under &ldquo;categories of special data&rdquo; under applicable laws to us, we encrypt the transmission of that information using secure socket layer technology (SSL). We follow generally accepted industry standards to protect the Personal Data submitted to us, both during transmission and once we receive it.</p>
        <p>Among others, we utilize the following security measures to protect your Personal Data:</p>
        <ul>
          <li>Pseudonymization and tokenization of certain categories of your Personal Data;</li>
          <li>Protection of data integrity;</li>
          <li>Encryption of your Personal Data in transit and in rest;</li>
          <li>Systematic vulnerability scanning and penetration testing;</li>
          <li>Organizational and legal measures. For example, our employees have different levels of access to your Personal Data, and only those in charge of data management get access to your Personal Data and only for limited purposes required for the operation of the App. We impose strict liability on our employees for any disclosures, unauthorized accesses, alterations, destructions, misuses of your Personal Data.</li>
          <li>Conducting periodical data protection impact assessments in order to ensure that the App fully adheres to the principles of &lsquo;privacy by design&rsquo;, &lsquo;privacy by default&rsquo; and other internationally accepted data protection principles. We also commit to undertake privacy audit in case of Company&rsquo;s merger or takeover.</li>
        </ul>
        <p>Bear in mind that no method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security. If you have any questions about security on our App, you can contact us at <a href="mailto:support@verv.com">support@verv.com</a>.</p>
        <h2><strong>6. RETENTION AND STORAGE OF YOUR PERSONAL DATA</strong></h2>
        <p>We will retain your Personal Data for as long as the application is installed on your mobile device or as needed to provide you service.&nbsp;If you remove the application from your phone or (and) deactivate your subscription, we will remove all your Personal Data within 12 months and use copies of your Personal Data only as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.&nbsp;We retain your Personal data for the indicated period of time in order for you to be able to restore all your information and settings, if you like to return to the App. If you wish to cancel your account or request that we no longer use your information to provide you services, or wish to delete all your information and Personal Data permanently, contact us at&nbsp;<u><a href="mailto:support@verv.com">support@verv.com</a></u>.</p>
        <p>In some cases, after we may anonymize you Personal Data in such a way that it will never identify you, actually or potentially. We may use such anonymized data for various purposes like training our AI built into the App.</p>
        <p>We use our own servers&nbsp;located in the European Union (Germany) to transfer, process, and store your Personal Data.</p>
        <h2><strong>7. CHILDREN&rsquo;S PRIVACY</strong></h2>
        <p><strong>General age limitation.</strong> We are committed to protecting the privacy of children. The App is not intended for children and we do not intentionally collect information about children under 16 years old. The App does not collect Personal Data from any person the Company actually knows is under the age of 16. If you are aware of anyone under 16 using the App, please contact us at <a href="mailto:support@verv.com">support@verv.com</a> and we will take required steps to delete such information and (or) delete their account.</p>
        <h2><strong>8. DATA PROTECTION OFFICER</strong></h2>
        <p>To communicate with our Data Protection Officer, please email at <a href="mailto:dpo@verv.com">dpo@verv.com</a>.</p>
        <h2><strong>9. CONTACTING US</strong></h2>
        <p><strong>General contact details.</strong> If you have any questions or concerns about your privacy, any provisions of this Privacy Policy or any of your rights, you may contact us at:</p>
        <p>Verv Inc.</p>
        <p>3773 Howard Hughes Parkway, South Tower, Suite 500, Las Vegas, NV 89169-6014</p>
        <p>Email: <a href="mailto:support@verv.com">support@verv.com</a></p>
        <p><strong>Our EU representative:</strong></p>
        <p><strong>ANGOLIACO INVESTMENTS LTD</strong></p>
        <p>Karaiskaki, 38 KANIKA ALEXANDER CENTER, 1st floor, Office 113B</p>
        <p>BLOCK 1, 3032, Limassol, Cyprus</p>
        <p>E-mail: <a href="mailto:angoliaco.investments@gmail.com">angoliaco.investments@gmail.com</a></p>
      </div>
    </div>
  )
}

export default TermsPolicies;