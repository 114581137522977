import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { lifestyleData } from "./static.lifestyleData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import "./lifestyle.scss";

class Lifestyle extends Component {
  constructor() {
    super();

    this.state = {
      isOpenLifestyle: false,
    };
    this.indexLifestyle = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.lifestyle) &&
      !this.state.isOpenLifestyle
    )
      this.setState({ isOpenLifestyle: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenLifestyle } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.lifestyle,
      isOpen: isOpenLifestyle,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenLifestyle && this.indexLifestyle === 0) {
      this.indexLifestyle = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenLifestyle) {
      this.indexLifestyle = 0;
    }

    if (isOpenLifestyle && !has(data, ["lifestyle"])) onSetStepData("lifestyle");

    updateOpenStepState({ params });
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenLifestyle: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'lifestyleDescription',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "lifestyleDescription",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenLifestyle } = this.state;
    const params = {
      className: "wrapperLifestyle",
      currentIndex: currentIndex,
      stepIndex: steps.lifestyle,
    };
    let startTime = new Date();

    return isOpenLifestyle ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>
            Which of the following describes your lifestyle?
          </FirstTitle>

          <div className="lifestyleContent">
            <p>Sometimes it might be difficult to maintain healthy habits. Our personal program will help you to acheive your well-being goals.</p>
          </div>

          {Object.keys(lifestyleData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["lifestyle", "value"], null) ===
                  lifestyleData[key].value
                }
                onClick={
                  get(data, ["lifestyle", "value"], null) !==
                  lifestyleData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "lifestyle",
                        "value",
                        lifestyleData[key].value
                      )
                    : () => {}
                }
                icon={lifestyleData[key].icon}
                identify={`lifestyle${index}`}
                onClickNextStep={onToNextStep !== false ? () => {
                  setTimeout(() => {
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(lifestyleData, [key, "title"]), startTime
                    )}, 400); } : () => {}
                }
              >
                {lifestyleData[key].title}
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(Lifestyle);
