import React from "react";
import { Route, Switch } from "react-router";
import StyleGuides from "./styleGuides";
import Home from "./steps/stepsWrapper/stepsWrapper";
import Faq from "./pages/faq";
import Contacts from "./pages/contacts";
import TermsPolicies from "./pages/termsPolicies";
import "./app.scss";

function getParameterFromURL(name, url) {
  if (!url) url = window.location.href;

  name = name.replace(/[\[\]]/g, "\\$&");

  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

let url = 'onboardingV1';

let time = new Date();
window.addEventListener('load', () => {
  let time2 = new Date();
  let time3 = (time2.getTime() - time.getTime()) / 1000;
  dataLayerAddEventStartTime(time3);
});

function dataLayerAddEventStartTime(time) {
  window.dataLayer.push({
    event: "dev_event",
    page_url: url,
    loading_time: time,
  });
  // console.log(window.dataLayer);
}

window.initHeight = window.visualViewport.height;
let done = false;
let interval;
let timeout;

function setButtonPosition() {
  document.querySelector("html").style.height =
    window.visualViewport.height + "px";
  document.documentElement.style.setProperty(
    "--viewportHeight",
    window.visualViewport.height
  );

  if (document.querySelector(".stepContentWrapper")) {
    document.querySelector(".stepContentWrapper").style.minHeight =
      window.visualViewport.height - 50 + "px";
  }

  if (document.querySelector(".application")) {
    document.querySelector(".application").style.minHeight =
      window.visualViewport.height + "px";
  }

  if (document.querySelector(".stepControl")) {
    if (window.initHeight > window.visualViewport.height) {
      document.querySelector(".stepControl").style.top =
        window.visualViewport.height - 50 + "px";
    } else {
      document.querySelector(".stepControl").style.top =
        window.visualViewport.height - 50 + "px";
    }
  }
}

function setValidationMessage(value) {
  if (document.getElementById("height")) {
    document.getElementById("height").addEventListener("blur", () => {
      document.getElementById(`messageIdheight`).style.display = "block";

      clearInterval(interval);
      timeout = setTimeout(() => {
        document.querySelector(".application").scrollTo(0, -50);
        window.scrollTo(0, -50);
      }, 100);
    });

    document.getElementById("height").addEventListener("focus", () => {
      document.getElementById(`messageIdheight`).style.display = "none";

      interval = setInterval(() => {
        if (done) return;
        if (window.scrollY !== 0) {
          window.scrollTo(0, 0);
        }

        if (window.scrollY === 0) {
          document.querySelector(".application").scrollTo(0, 50);
        }
      }, 1);
    });
  }

  if (document.getElementById("height2")) {
    document.getElementById("height2").addEventListener("blur", () => {
      document.getElementById(`messageIdheight`).style.display = "block";

      clearInterval(interval);
      timeout = setTimeout(() => {
        document.querySelector(".application").scrollTo(0, -50);
        window.scrollTo(0, -50);
      }, 100);
    });

    document.getElementById("height2").addEventListener("focus", () => {
      document.getElementById(`messageIdheight`).style.display = "none";

      interval = setInterval(() => {
        if (done) return;
        if (window.scrollY !== 0) {
          window.scrollTo(0, 0);
        }

        if (window.scrollY === 0) {
          document.querySelector(".application").scrollTo(0, 50);
        }
      }, 1);
    });
  }

  if (document.getElementById("goalWeight")) {
    document.getElementById("goalWeight").addEventListener("blur", () => {
      document.getElementById(`messageIdgoalWeight`).style.display = "block";

      clearInterval(interval);
      timeout = setTimeout(() => {
        document.querySelector(".application").scrollTo(0, -50);
        window.scrollTo(0, -50);
      }, 100);
    });
    document.getElementById("goalWeight").addEventListener("focus", () => {
      document.getElementById(`messageIdgoalWeight`).style.display = "none";

      interval = setInterval(() => {
        if (done) return;
        if (window.scrollY !== 0) {
          window.scrollTo(0, 0);
        }

        if (window.scrollY === 0) {
          document.querySelector(".application").scrollTo(0, 50);
        }
      }, 1);
    });
  }

  if (document.getElementById("currentWeight")) {
    document.getElementById("currentWeight").addEventListener("blur", () => {
      document.getElementById(`messageIdcurrentWeight`).style.display = "block";

      clearInterval(interval);
      timeout = setTimeout(() => {
        document.querySelector(".application").scrollTo(0, -50);
        window.scrollTo(0, -50);
      }, 100);
    });
    document.getElementById("currentWeight").addEventListener("focus", () => {
      document.getElementById(`messageIdcurrentWeight`).style.display = "none";

      interval = setInterval(() => {
        if (done) return;
        if (window.scrollY !== 0) {
          window.scrollTo(0, 0);
        }

        if (window.scrollY === 0) {
          document.querySelector(".application").scrollTo(0, 50);
        }
      }, 1);
    });
  }

  if (document.getElementById("mail")) {
    document.getElementById("mail").addEventListener("blur", () => {
      document.getElementById(`messageIdmail`).style.display = "block";

      clearInterval(interval);
      timeout = setTimeout(() => {
        document.querySelector(".application").scrollTo(0, -50);
        window.scrollTo(0, -50);
      }, 100);
    });
    document.getElementById("mail").addEventListener("focus", () => {
      document.getElementById(`messageIdmail`).style.display = "none";

      interval = setInterval(() => {
        if (done) return;
        if (window.scrollY !== 0) {
          window.scrollTo(0, 0);
        }

        if (window.scrollY === 0) {
          document.querySelector(".application").scrollTo(0, 50);
        }
      }, 1);
    });
  }
}

function setDisabledContent() {
  const buttons = document.querySelectorAll("button") || null;
  if (buttons) {
    for (const button of buttons) {
      let count = 0;
      button.addEventListener("click", () => {
        count++;
        if (count === 1) {
          for (const button of buttons) {
            button.classList.add("disabled");
          }
          setTimeout(() => {
            for (const button of buttons) {
              button.classList.remove("disabled");
            }
          }, 400);
        }
      });
    }
  }
}

setValidationMessage();
setButtonPosition();
setDisabledContent();

setInterval(setValidationMessage, 1);
setInterval(setButtonPosition, 2);
setInterval(setDisabledContent, 1);

const App = () => {
  return (
    <div className="application">
      <>
        <Switch>
          <Route exact path={"/"} component={Home} />
          <Route exact path={"/faq"} component={Faq} />
          <Route exact path={"/contacts"} component={Contacts} />
          <Route exact path={"/terms-and-policies"} component={TermsPolicies} />
          <Route exact path={"/styleGuides"} component={StyleGuides} />
        </Switch>
      </>
    </div>
  );
};

export default App;
