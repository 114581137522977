import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import StepControl from "../stepControl";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./workouts.module.scss";
import WorkoutsNextStepButton from "./workoutsNextStepButton";
import img1 from "./img/img1.jpg";
import img2 from "./img/img2.jpg";
import img3 from "./img/img3.jpg";

class Workouts extends Component {
  constructor() {
    super();

    this.state = {
      isOpenWorkouts: false,
      dataLayerAddEvent: this.dataLayerAddEvent.bind(this),
    };
    this.indexWorkouts = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.workouts) &&
      !this.state.isOpenWorkouts
    )
      this.setState({ isOpenWorkouts: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenWorkouts } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.workouts,
      isOpen: isOpenWorkouts,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenWorkouts && this.indexWorkouts === 0) {
      this.indexWorkouts = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen();
      });
    }
    if (!isOpenWorkouts) {
      this.indexWorkouts = 0;
    }

    if (isOpenWorkouts && !has(data, ["workouts"])) onSetStepData("workouts");

    updateOpenStepState({ params });
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenWorkouts: state,
    });
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      event: "user_answers",
      quiz_question: "infoFamousOrg",
      quiz_answer: "continue",
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "infoFamousOrg",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenWorkouts } = this.state;
    const params = {
      className: "wrapperWorkouts",
      currentIndex: currentIndex,
      stepIndex: steps.workouts,
    };

    return isOpenWorkouts ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <h1 className={styles.title}>
            What you need to know about Running workouts
          </h1>

          <div className={styles.contentWrapper}>
            <div className={styles.contentItem}>
              <img className={styles.contentItemImage} src={img1} alt="img1" />
              <div className={styles.contentItemText}>
                <p className={styles.contentItemTitle}>
                  Decrease risk for heart disease
                </p>
                <p className={styles.contentItemDescription}>
                  Those who start running on a regular basis decrease their risk
                  for heart disease by 35 to 55 percent.
                </p>
              </div>
            </div>

            <div className={styles.contentItem}>
              <img className={styles.contentItemImage} src={img2} alt="img2" />
              <div className={styles.contentItemText}>
                <p className={styles.contentItemTitle}>Extend your life</p>
                <p className={styles.contentItemDescription}>
                  A study in the Journal of the AСС finds that even 5-10 min a
                  day of low-intensity running is enough to extend life by
                  several years.
                </p>
              </div>
            </div>

            <div className={styles.contentItem}>
              <img className={styles.contentItemImage} src={img3} alt="img3" />
              <div className={styles.contentItemText}>
                <p className={styles.contentItemTitle}>Improve mood</p>
                <p className={styles.contentItemDescription}>
                  Even a 30-minute run can can improve mood and help fight
                  depression.
                </p>
              </div>
            </div>
          </div>
        </div>
        <StepControl>
          <WorkoutsNextStepButton
            dataLayerAddEvent={this.state.dataLayerAddEvent}
          >
            Got it
          </WorkoutsNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(Workouts);
