import { isNull, get } from "lodash";

import jd1 from "./img/jd1.png";
import jd2 from "./img/jd2.png";
import jd3 from "./img/jd3.png"

export const checkIsValidJobActive = data => {
    return validateJobActiveStep(get(data, ["jobActive", "value"], "")) && !data.jobActive.isValid
}

export const validateJobActiveStep = value => {
    return !isNull(value)
}

export const jobActiveData = {
    sedentary: {
        value:"Sedentary",
        title: "Sedentary",
        icon: jd1,
    },
    moderately: {
        value:"Moderately active",
        title: "Moderately active",
        icon: jd2,
    },
    very: {
        value:"Very active",
        title: "Very active",
        icon: jd3,
    },
}

export const defaultFasting = false