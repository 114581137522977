import React, { Component, useState } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./successStory.module.scss";
import animation from "./img/animation.mp4";
import SuccessStoryModal from "./successStoryModal";

class SuccessStory extends Component {
  constructor() {
    super();

    this.state = {
      isOpenModalOne: false,
      isOpenSuccessStory: false,
      classIconImage2: styles.featureIconImage,
      classText2: styles.featureText,
      classActivWrapper2: styles.stripeActiveWrapper,
      closeModal: this.closeModal.bind(this),
      isOpenModalTwo: false,
      closeModalTwo: this.closeModalTwo.bind(this),
      classIcon3: styles.featureIcon,
      classShadow3: styles.featureIconShadow,
      classIconImage3: styles.featureIconImage,
      classText3: styles.featureText,
      classActivWrapper3: styles.stripeActiveWrapper,
      classIcon4: styles.featureIcon,
      classShadow4: styles.featureIconShadow,
      classIconImage4: styles.featureIconImage,
      classText4: styles.featureText,
    };
    this.indexSuccessStory = 0;
    this.startTimeSuccessStory = {};

  }

  closeModal(cont) {
    this.dataLayerAddEvent(cont);
    this.setState({ 
      isOpenModalOne: false,
      classIconImage2: styles.featureIconImageAnim,
      classText2: styles.featureTextAnim,
      classActivWrapper2: styles.stripeActiveWrapperAnim,
      classIcon3: styles.featureIconAnim,
      classShadow3: styles.featureIconShadowAnim
    })
  }

  closeModalTwo(cont) {
    this.dataLayerAddEventTwo(cont);
    this.setState({ 
      isOpenModalTwo: false,
      classIconImage3: styles.featureIconImageAnim,
      classText3: styles.featureTextAnim,
      classActivWrapper3: styles.stripeActiveWrapperAnim,
      classIcon4: styles.featureIconAnim,
      classShadow4: styles.featureIconShadowAnim,
      classIconImage4: styles.featureIconImageAnim,
      classText4: styles.featureTextAnim,
      })
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.successStory) &&
      !this.state.isOpenSuccessStory
    )
      this.setState({ isOpenSuccessStory: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData, onToNextStep } = this.props;
    const { isOpenSuccessStory } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.successStory,
      isOpen: isOpenSuccessStory,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenSuccessStory && this.indexSuccessStory === 0) {
      this.indexSuccessStory = 1;
      this.startTimeSuccessStory = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenSuccessStory) {
      this.indexSuccessStory = 0;
    }

    if (isOpenSuccessStory && !has(data, ["successStory"])) onSetStepData("successStory");

    updateOpenStepState({ params });

    function addTouchstart () {
      document.body.addEventListener('touchstart', function () { }, false);
    }
  
    addTouchstart();
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenSuccessStory: state,
    });
  }

  dataLayerAddEvent(answer) {
    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'whenDoExercises',
      'quiz_answer': answer
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventTwo(answer) {
    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'readyChangeLifestyle',
      'quiz_answer': answer
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventThree(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'run_result_loader',
      'quiz_answer': 'continueAuto',
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "run_result_loader",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenSuccessStory } = this.state;
    const params = {
      className: "wrapperSuccessStory",
      currentIndex: currentIndex,
      stepIndex: steps.successStory,
    };

    const modalTwo = [
      { cont: "Yes" },
      { cont: "No" },
    ];

    const modalOne = [
      { cont: "In the morning" },
      { cont: "During the day" },
      { cont: "In the evening" },
      { cont: "I’m not sure" },
    ];

    return isOpenSuccessStory ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.successStory}>
          <div className={styles.animation}>
            <video autoPlay muted playsInline loop preload="auto">
              <source src={animation} type="video/mp4" />
            </video>
          </div>
          <h2 className={styles.title}>Picking the right workout routine</h2>
          <div className={styles.features}>
            <div className={styles.feature}>
              <div className={styles.featureIconWrapper}>
                <div className={styles.featureIconAnim}></div>
                <div className={styles.featureIconImageAnim} ></div>
                <div className={styles.featureIconShadowAnim}></div>
              </div>
              <div className={styles.featureTextAnim}>Analyzing received data</div>
              <div className={styles.stripe}></div>
              <div className={styles.stripeActiveWrapperAnim}>
                <div className={styles.stripeActive}></div>
              </div>
            </div>
            <div className={styles.feature}>
              <div className={styles.featureIconWrapper}>
                <div className={styles.featureIconAnim}></div>
                <div className={this.state.classIconImage2}></div>
                <div className={styles.featureIconShadowAnim} onAnimationEnd={() => {
                  this.setState({ isOpenModalOne: true })
                }}></div>
              </div>
              <div className={this.state.classText2}>
                Searching for a perfect plan
              </div>
              <div className={styles.stripe}></div>
              <div className={this.state.classActivWrapper2}>
                <div className={styles.stripeActive}></div>
              </div>
            </div>
            <div className={styles.feature}>
              <div className={styles.featureIconWrapper}>
                <div className={this.state.classIcon3}></div>
                <div className={this.state.classIconImage3}></div>
                <div className={this.state.classShadow3} onAnimationEnd={() => {
                  this.setState({ isOpenModalTwo: true })
                }}></div>
              </div>
              <div className={this.state.classText3}>
                Tally up daily calories intake
              </div>
              <div className={styles.stripe}></div>
              <div className={this.state.classActivWrapper3}>
                <div className={styles.stripeActive}></div>
              </div>
            </div>
            <div className={styles.feature}>
              <div className={styles.featureIconWrapper}>
                <div className={this.state.classIcon4}></div>
                <div className={this.state.classIconImage4} onAnimationEnd={() => {
                  this.dataLayerAddEventThree(this.startTimeSuccessStory);
                  onToNextStep();
                }}></div>
                <div className={this.state.classShadow4}></div>
              </div>
              <div className={this.state.classText4}>
                Calculating needed water intake
              </div>
            </div>
          </div>
        </div>
        {this.state.isOpenModalOne && (
          <SuccessStoryModal
            props={modalOne} title="When do you prefer to do running exercises?"
            onClick={this.state.closeModal}
          />
        )}
        {this.state.isOpenModalTwo && (
          <SuccessStoryModal
            props={modalTwo} title = "Are you ready to change your body and lifestyle?"
            onClick={this.state.closeModalTwo}
          />
        )}
      </div>
    ) : null;
  }
}

export default withStepsState(SuccessStory);
