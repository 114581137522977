import { get } from 'lodash';
import { connect } from 'react-redux'

import {
  openChart as onOpenChart,
  closeChart as onCloseChart,
} from '../../actions/stepsStateActions'

const mapStateToProps = (state) => ({
  openChart: get(state, ["stepsState", "openChart"], false),
  closeChart: get(state, ["stepsState", "closeChart"], false),
});

const mapDispatchToProps = {
  onOpenChart,
  onCloseChart,
}

export const withLoader = Component => connect(mapStateToProps, mapDispatchToProps)(Component)