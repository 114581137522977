import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import InputNumberWrapper from "../../../shared/items/controls/inputNumberWrapper";
import InputNumberWrapperFt from "../../../shared/items/controls/inputNumberWrapperFt";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import RadioСhoice from "../../../shared/items/controls/radioСhoice";
import RadioСhoiceWrapper from "../../../shared/radioСhoiceWrapper";
import HeightNextStepButton from "./heightNextStepButton";
import ValidateMessage from "./../../../shared/items/static/validateMessage";
import StepContentWrapper from "./../stepContentWrapper";
import StepControl from "./../stepControl";
import { steps } from "../../../shared/static.steps";
import { heightTypeData } from "./static.heightData";
import fixFocus from "./../../../utils/fixFocus"
import "./../../../shared/services/service.step.scss";
import styles from "./height.module.scss";

class Height extends Component {
  constructor() {
    super();

    this.state = {
      isOpenHeight: false,
      isHeightType: 'ft',
      // isShowErrorMessage: false,
    };
    this.indexHeight = 0;
    this.startTimeHeight = {};
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.height) &&
      !this.state.isOpenHeight
    )
      this.setState({ isOpenHeight: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenHeight } = this.state;
    if(isOpenHeight && this.indexHeight === 0) {
      this.indexHeight = 1;
      this.startTimeHeight = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }

    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.height,
      isOpen: isOpenHeight,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenHeight && !has(data, ["height"])) {
      onSetStepData("height");

      fixFocus("height");
      document.getElementById("height").focus();

    }

    updateOpenStepState({ params });
    if(!isOpenHeight) {
      this.indexHeight = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenHeight: state,
    });
  }

  // validationOnBlur() {
  //   document.getElementById("height").addEventListener("blur", () => {
  //     console.log(true);
  //     this.setState({ isShowErrorMessage: true });
  //   });
  //   document.getElementById("height").addEventListener("focus", () => {
  //     console.log(false);
  //     this.setState({ isShowErrorMessage: false });
  //   });
  // }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "height",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData } = this.props;
    const { isOpenHeight, isShowErrorMessage } = this.state;
    const params = {
      className: "wrapperHeight",
      currentIndex: currentIndex,
      stepIndex: steps.height,
    };

    return isOpenHeight ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>Your height</FirstTitle>

          <RadioСhoiceWrapper
            name="type"
            valueStore={get(data, ["height"], null)}
            defaultValue={heightTypeData.ft.value}
            handleSetDefault={onUpdateStepData.bind(this, "height")}
          >
            {Object.keys(heightTypeData).map((key, index) => {
              return (
                <RadioСhoice
                  key={index}
                  isChecked={
                    heightTypeData[key].value ===
                    get(data, ["height", "type"], null)
                  }
                  onClick={() => {
                      onUpdateStepData('height', 'type', heightTypeData[key].value);
                      this.setState({isHeightType: heightTypeData[key].value})
                    }
                  }
                >
                  {heightTypeData[key].title}
                </RadioСhoice>
              );
            })}
          </RadioСhoiceWrapper>

          
          {this.state.isHeightType === 'ft' ? 
            (<div className={styles.container_inputFt}>
              <InputNumberWrapperFt
                name="height"
                id="height"
                keyName="value"
                placeholder="0"
                placehheighter="Your height?"
                onChange={onUpdateStepData.bind(this)}
                valueStore={get(data, ["height", "value"], null)}
              />
              <InputNumberWrapperFt
                name="height"
                id="height2"
                keyName="value2"
                placeholder="0"
                placehheighter="Your height?"
                onChange={onUpdateStepData.bind(this)}
                valueStore={get(data, ["height", "value2"], null)}
              />
            </div>) : 
              (<div className={styles.container_input}>
                <InputNumberWrapper
                  name="height"
                  keyName="value"
                  placehheighter="Your height?"
                  onChange={onUpdateStepData.bind(this)}
                  valueStore={get(data, ["height", "value"], null)}
                />
              </div>)
          }
          

          <div id="messageIdheight" className={styles.container_validateMessage}>
              <ValidateMessage
                message={get(data, ["height", "validString"], null)}
              />
          </div>
        </StepContentWrapper>

        <StepControl>
          <HeightNextStepButton
          startTimeHeight={this.startTimeHeight}
          >Done</HeightNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(Height);
