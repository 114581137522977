import { isNull, get } from "lodash"

export const checkIsValidRelateToWeight = data => {
    return validateRelateToWeightStep(get(data, ["relateToWeight", "value"], "")) && !data.relateToWeight.isValid
}

export const validateRelateToWeightStep = value => {
    return !isNull(value)
}

export const relateToWeightData = {
    one: {
        value:"1",
        title: "1",
    },
    two: {
        value:"2",
        title: "2",
    },
    three: {
        value:"3",
        title: "3",
    },
    four: {
        value:"4",
        title: "4",
    },
    five: {
        value:"5",
        title: "5",
    },
}

export const defaultFasting = false