import React, { Component } from 'react';
import { get } from "lodash";

import { withStepsState } from "../../../shared/containers/containerStepsState";
import { withLoader } from "../../../shared/containers/containerLoader";

import { checkIsValidSuccessStoryResult } from "./static.successStoryResultData";
import ButtonFill from "../../../shared/items/controls/buttonFill";
import styles from "./successStoryResult.module.scss";
import blockContent from "./../../../utils/blockContent"

class SuccessStoryResultNextStepButton extends Component {
  componentDidUpdate() {
    const { data, onSetSuccessValidate } = this.props

    if (checkIsValidSuccessStoryResult(data))
      onSetSuccessValidate("successStoryResult")
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'disclaimer',
      'quiz_answer': 'continue',
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
  }

  render() {
    const { data, onToNextStep } = this.props;
    let startTime = new Date();

    return (
      <div className={styles.successStoryResultButtonWrapper}>
        <button
          className={styles.successStoryResultButtonFill}
          onClick={onToNextStep !== false ? () => {
            blockContent();
            setTimeout(() => {
              this.dataLayerAddEvent(startTime);
              onToNextStep();
              }, 400); } : () => {}
          }
        >
          {this.props.children}
        </button>
      </div>
    )
  }
}

export default withStepsState(withLoader(SuccessStoryResultNextStepButton))