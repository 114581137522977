import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { fitnesLevelData } from "./static.fitnesLevelData";
import { steps } from "../../../shared/static.steps";
import styles from "./fitnesLevel.module.scss";
import "./../../../shared/services/service.step.scss";
import blockContent from "./../../../utils/blockContent";

class FitnesLevel extends Component {
  constructor() {
    super();

    this.state = {
      isOpenFitnesLevel: false,
    };
    this.indexFitnesLevel = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.fitnesLevel) &&
      !this.state.isOpenFitnesLevel
    )
      this.setState({ isOpenFitnesLevel: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenFitnesLevel } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.fitnesLevel,
      isOpen: isOpenFitnesLevel,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenFitnesLevel && !has(data, ["fitnesLevel"]))
      onSetStepData("fitnesLevel");

    updateOpenStepState({ params });

    if(isOpenFitnesLevel && this.indexFitnesLevel === 0) {
      this.indexFitnesLevel = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenFitnesLevel) {
      this.indexFitnesLevel = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenFitnesLevel: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'runningLevel',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "runningLevel",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenFitnesLevel } = this.state;
    const params = {
      className: "wrapperFitnesLevel",
      currentIndex: currentIndex,
      stepIndex: steps.fitnesLevel,
    };

    let startTime = new Date();

    return isOpenFitnesLevel ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>What’s your current running level?</FirstTitle>

          {Object.keys(fitnesLevelData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["fitnesLevel", "value"], null) ===
                  fitnesLevelData[key].value
                }
                onClick={
                  get(data, ["fitnesLevel", "value"], null) !==
                  fitnesLevelData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "fitnesLevel",
                        "value",
                        fitnesLevelData[key].value
                      )
                    : () => {}
                }
                icon={fitnesLevelData[key].icon}
                identify={`level${index}`}
                onClickNextStep={onToNextStep !== false ? () => {
                  blockContent();
                  setTimeout(() => {
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(fitnesLevelData, [key, "value"]), startTime
                    )}, 400); } : () => {}
                }
              >
                <div className={styles.texts}>
                  <div className={styles.title}>
                    {fitnesLevelData[key].title}
                  </div>
                  <div className={styles.description}>
                    {fitnesLevelData[key].description}
                  </div>
                </div>
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(FitnesLevel);
