import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import InputNumberWrapper from "../../../shared/items/controls/inputNumberWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import RadioСhoice from "../../../shared/items/controls/radioСhoice";
import RadioСhoiceWrapper from "../../../shared/radioСhoiceWrapper";
import GoalWeightNextStepButton from "./goalWeightNextStepButton";
import ValidateMessage from "../../../shared/items/static/validateMessage";
import StepContentWrapper from "./../stepContentWrapper";
import StepControl from "./../stepControl";
import { steps } from "../../../shared/static.steps";
import { goalWeightTypeData } from "./static.goalWeightData";
import { currentWeightTypeData } from "./../currentWeight/static.currentWeightData";
import "./../../../shared/services/service.step.scss";
import fixFocus from "./../../../utils/fixFocus"
import styles from "./goalWeight.module.scss";

class GoalWeight extends Component {
  constructor() {
    super();

    this.state = {
      isOpenGoalWeight: false,
      isWeightType: 'lbs',
      index: 1,
    };
    this.indexGoalWeight = 0;
    this.startTimeGoalWeight = {};
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.goalWeight, steps.sex) &&
      !this.state.isOpenGoalWeight
    )
      this.setState({ isOpenGoalWeight: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenGoalWeight } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.goalWeight,
      isOpen: isOpenGoalWeight,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenGoalWeight && this.indexGoalWeight === 0) {
      this.indexGoalWeight = 1;
      this.startTimeGoalWeight = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }

    if (isOpenGoalWeight && !has(data, ["goalWeight"])) {
      onSetStepData("goalWeight");

      fixFocus("goalWeight");
      // document.getElementById("goalWeight").focus();

    }

    if(isOpenGoalWeight) { 
      setTimeout(()=> {
        if(data.height.type === 'cm' && this.state.index === 1) {
          this.setState({isWeightType: 'kg'})
          this.setState({index: 2})
        }
        
      })
      
    }

    if(isOpenGoalWeight) {
      if(window.scrollY !== 0) {
        window.scrollTo(0, 0);
      }
    }

    updateOpenStepState({ params });

    if(!isOpenGoalWeight) {
      this.indexGoalWeight = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenGoalWeight: state,
    });
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "goalWeight",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData } = this.props;
    const { isOpenGoalWeight } = this.state;
    const params = {
      className: "wrapperGoalWeight",
      currentIndex: currentIndex,
      stepIndex: steps.goalWeight,
    };
    const defaultType = !has(data, ["goalWeight", "type"])
      ? get(data, ["currentWeight", "type"]) === currentWeightTypeData.kg.value
        ? goalWeightTypeData.kg.value
        : goalWeightTypeData.lbs.value
      : goalWeightTypeData.lbs.value;

    return isOpenGoalWeight ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>Your goal weight</FirstTitle>

          <RadioСhoiceWrapper
            name="type"
            valueStore={get(data, ["goalWeight"], null)}
            defaultValue={defaultType}
            handleSetDefault={onUpdateStepData.bind(this, "goalWeight")}
          >
            {Object.keys(goalWeightTypeData).map((key, index) => {
              return (
                <RadioСhoice
                  key={index}
                  isChecked={
                    goalWeightTypeData[key].value ===
                    get(data, ["goalWeight", "type"], null)
                  }
                  onClick={()=> {
                    onUpdateStepData("goalWeight", "type",  goalWeightTypeData[key].value)
                    this.setState({isWeightType: goalWeightTypeData[key].value})
                  }}
                >
                  {goalWeightTypeData[key].title}
                </RadioСhoice>
              );
            })}
          </RadioСhoiceWrapper>


          {this.state.isWeightType === 'lbs' ?
            (<div className={styles.container_inputLBS}>
              <InputNumberWrapper
                name="goalWeight"
                keyName="value"
                placehgoalWeight="Your goalWeight?"
                onChange={onUpdateStepData.bind(this)}
                valueStore={get(data, ["goalWeight", "value"], null)}
              />
            </div>) : 
            <div className={styles.container_inputKG}>
              <InputNumberWrapper
                name="goalWeight"
                keyName="value"
                placehgoalWeight="Your goalWeight?"
                onChange={onUpdateStepData.bind(this)}
                valueStore={get(data, ["goalWeight", "value"], null)}
              />
            </div>
          }

          <div id="messageIdgoalWeight" className={styles.container_validateMessage}>
            <ValidateMessage
              message={get(data, ["goalWeight", "validString"], null)}
            />
          </div>
        </StepContentWrapper>

        <StepControl>
          <GoalWeightNextStepButton
          startTimeGoalWeight={this.startTimeGoalWeight}
          >Done</GoalWeightNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(GoalWeight);
