import React from 'react'
import { isNull } from "lodash"

import styles from "./validateMessage.module.scss"

const ValidateMessage = props => {
  const { message } = props
  const isView = !isNull(message)
    ? message.length !== 0
    : false

  return isView
    ? <div className={styles.validateMessage}>
      <p className={styles.message}>
        {message}
      </p>
    </div>
    : null
}

export default React.memo(ValidateMessage)