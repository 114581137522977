import { has, get } from "lodash"

import mi1 from "./img/mi1.png";
import mi2 from "./img/mi2.png";
import mi3 from "./img/mi3.png";
import mi4 from "./img/mi4.png";
import mi5 from "./img/mi5.png"

export const checkIsValidMedications = data => {
    let isValid = false

    Object.keys(medicationsData).map((key) => {
        if (has(data, ["medications", key]) && get(data, ["medications", key], false))
            isValid = true
    })

    return isValid;
}

export const medicationsData = {
    vitamins: {
        value:"Vitamins",
        title: "Vitamins",
        icon: mi1,
    },
    hormones: {
        value:"Hormones",
        title: "Hormones",
        icon: mi2,
    },
    antibiotics: {
        value:"Antibiotics",
        title: "Antibiotics",
        icon: mi3,
    },
    other: {
        value:"Other",
        title: "Other",
        icon: mi4,
    },
    notMedications: {
        value:"I am not taking any medications",
        title: "I am not taking any medications",
        icon: mi5,
    },
}

export const defaultFasting = false