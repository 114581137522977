import { isNull, get } from "lodash"

import o1 from "./img/o1.png"
import o2 from "./img/o2.png"
import o3 from "./img/o3.png"
import o4 from "./img/o4.png"

import om1 from "./img/om1.png"
import om2 from "./img/om2.png"
import om3 from "./img/om3.png"
import om4 from "./img/om4.png"

export const checkIsValidOld = data => {
    return validateOldStep(get(data, ["old", "value"], "")) && !data.old.isValid
}

export const validateOldStep = value => {
    return !isNull(value)
}

const oldFemale = {
    old1: {
        value: "25",
        title: "18 - 29",
        icon: o1,
    },
    old2: {
        value: "35",
        title: "30 - 39",
        icon: o2,
    },
    old3: {
        value: "45",
        title: "40 - 49",
        icon: o3,
    },
    old4: {
        value: "55",
        title: "50 - 59+",
        description: "Wide bust and hips, a narrow waist",
        icon: o4,
    },
}

const oldMale = {
    old1: {
        value: "25",
        title: "18 - 29",
        description: "Naturally wide torso and broad shoulders",
        icon: om1,
    },
    old2: {
        value: "35",
        title: "30 - 39",
        description: "Naturally slimmer shoulders and thicker thighs",
        icon: om2,
    },
    old3: {
        value: "45",
        title: "40 - 49",
        description: "Naturally wide shoulders and thighs",
        icon: om3,
    },
    old4: {
        value: "55",
        title: "50 - 59+",
        description: "Wide bust and hips, a narrow waist",
        icon: om4,
    },
}

export const defaultFasting = false

export const oldData = {
    female: oldFemale,
    male: oldMale,
}