import { isNull, get } from "lodash"

const BOTTOM_LINE_LBS = 44
const TOP_LINE_LBS = 659.9
const BELOW_BOTTOM_LINE_LBS_MESSAGE = "We cannot promise you to lose more than 25% of the weight. Enter a lower target weight."
const ABOVE_TOP_LINE_LBS_MESSAGE = "Your target weight should be less than your current weight. Please double check. You might have used metric system instead of imperial. You can change preferred unit system at the top of this page."

const BOTTOM_LINE_KG = 20
const TOP_LINE_KG = 299.9
const BELOW_BOTTOM_LINE_KG_MESSAGE = "We cannot promise you to lose more than 25% of the weight. Enter a lower target weight."
const ABOVE_TOP_LINE_KG_MESSAGE = "Your target weight should be less than your current weight. Please double check. You might have used metric system instead of imperial. You can change preferred unit system at the top of this page."

export const checkIsValidLbsGoalWeight = data => {
    return validateGoalWeightLbsStep(get(data, ["goalWeight", "value"], "")) && !data.goalWeight.isValid
}

export const checkIsValidKgGoalWeight = data => {
    return validateGoalWeightKgStep(get(data, ["goalWeight", "value"], "")) && !data.goalWeight.isValid
}

export const checkIsChoosedGoalWeightType = (data, type) => {
    return get(data, ["goalWeight", "type"], null) === type
}

export const isFailureLbsValidation = data => {
    return !validateGoalWeightLbsStep(get(data, ["goalWeight", "value"], ""))
        && get(data, ["goalWeight", "validString"], "") !== getValidateLbsMessage(get(data, ["goalWeight", "value"], ""))
}

export const isFailureKgValidation = data => {
    return !validateGoalWeightKgStep(get(data, ["goalWeight", "value"], ""))
    && get(data, ["goalWeight", "validString"], "") !== getValidateKgMessage(get(data, ["goalWeight", "value"], ""))
}

export const validateGoalWeightKgStep = value => {
    return !isNull(value)
        ? value >= BOTTOM_LINE_KG && value <= TOP_LINE_KG
        : false
}

export const getValidateKgMessage = value => {
    let message = ""

    if (!isNull(value))
        if (value <= BOTTOM_LINE_KG)
            message = BELOW_BOTTOM_LINE_KG_MESSAGE
        else if (value >= TOP_LINE_KG)
            message = ABOVE_TOP_LINE_KG_MESSAGE

    return message
}

export const validateGoalWeightLbsStep = value => {
    return !isNull(value)
        ? value >= BOTTOM_LINE_LBS && value <= TOP_LINE_LBS
        : false
}

export const getValidateLbsMessage = value => {
    let message = ""

    if (!isNull(value))
        if (value <= BOTTOM_LINE_LBS)
            message = BELOW_BOTTOM_LINE_LBS_MESSAGE
        else if (value >= TOP_LINE_LBS)
            message = ABOVE_TOP_LINE_LBS_MESSAGE

    return message
}

export const goalWeightTypeData = {
    lbs: {
        value: "lbs",
        title: "LBS",
    },
    kg: {
        value: "kg",
        title: "KG",
    },
}