import React, { Component } from 'react';
import { get, has } from "lodash"
import { withStepsState } from "../../../shared/containers/containerStepsState"
import FirstTitle from '../../../shared/items/static/firstTitle';
import RadioCheckButton from '../../../shared/items/controls/radioCheckButton';
import HelpsNextStepButton from "./helpsNextStepButton"
import StepControl from "./../stepControl"
import { isCurrentStep, buildStepClassName, updateOpenStepState } from "../../../shared/services/service.step"
import { helpsData } from "./static.helpsData"
import { steps } from "../../../shared/static.steps"
import "./../../../shared/services/service.step.scss"
import styles from "./helps.module.scss";

class Helps extends Component {
  constructor() {
    super();
    this.state = {
      isOpenHelps: false,
    }
    this.indexHelps = 0;
    this.startTimeHelps = {};
  }

  componentDidMount() {
    if (isCurrentStep(this.props.currentIndex, steps.helps) && !this.state.isOpenHelps)
      this.setState({ isOpenHelps: true })
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props
    const { isOpenHelps } = this.state
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.helps,
      isOpen: isOpenHelps,
      handleChange: this.handleChangeOpen.bind(this),
    }

    if(isOpenHelps && this.indexHelps === 0) {
      this.indexHelps = 1;
      this.startTimeHelps = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }

    if (isOpenHelps && !has(data, ["helps"]))
      onSetStepData("helps")

    updateOpenStepState({ params });
    if(!isOpenHelps) {
      this.indexHelps = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenHelps: state,
    })
  }

  dataLayerAddEvent(answers) {
    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'stayOnTrack',
      'quiz_answer': answers
    })
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "stayOnTrack",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData } = this.props
    const { isOpenHelps } = this.state
    const params = {
      className: "wrapperHelps",
      currentIndex: currentIndex,
      stepIndex: steps.helps,
    }

    return isOpenHelps ?
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <FirstTitle>What helps you stay on track?</FirstTitle>
          {
            Object.keys(helpsData).map((key, index) => {
              return (
                <RadioCheckButton
                  key={index}
                  isChecked={get(data, ["helps", key], false)}
                  onClick={
                    !get(data, ["helps", key], false)
                      ? onUpdateStepData.bind(this, "helps", key, true)
                      : onUpdateStepData.bind(this, "helps", key, false)
                  }
                  icon={helpsData[key].icon}
                  identify={`helps${index}`}
                  clickCheckerDisabled={true}
                >
                  {helpsData[key].title}
                </RadioCheckButton>
              )
            })
          }
        </div>
        <StepControl>
          <HelpsNextStepButton
           startTimeHelps={this.startTimeHelps}
           helpsData={helpsData}>Next</HelpsNextStepButton>
        </StepControl>
      </div>
      : null
  }
}

export default withStepsState(Helps);