import { createActionTypesOf } from '../utils/helpers';

export const TO_NEXT_STEP = createActionTypesOf("TO_NEXT_STEP")
export const TO_PREV_STEP = createActionTypesOf("TO_PREV_STEP")
export const OPEN_LOADER = createActionTypesOf("OPEN_LOADER")
export const OPEN_SUCCESS_STORY = createActionTypesOf("OPEN_SUCCESS_STORY")
export const CLOSE_SUCCESS_STORY = createActionTypesOf("CLOSE_SUCCESS_STORY")
export const OPEN_CHART = createActionTypesOf("OPEN_CHART")
export const CLOSE_CHART = createActionTypesOf("CLOSE_CHART")
export const OPEN_RESULT_PAGE = createActionTypesOf("OPEN_RESULT_PAGE")

export const CLEAR_STEP = createActionTypesOf("CLEAR_STEP")
export const CLEAR_STEP_DATA = createActionTypesOf("CLEAR_STEP_DATA")
export const SET_STEP_DATA = createActionTypesOf("SET_STEP_DATA")
export const UPDATE_STEP_DATA = createActionTypesOf("UPDATE_STEP_DATA")
export const VALIDATE_STEP_DATA = createActionTypesOf("VALIDATE_STEP_DATA")

export const toNextStep = () => ({
  type: TO_NEXT_STEP.REQUEST,
})

export const toPrevStep = () => ({
  type: TO_PREV_STEP.REQUEST,
})

export const openLoader = () => ({
  type: OPEN_LOADER.REQUEST,
})

export const openSuccessStory = () => ({
  type: OPEN_SUCCESS_STORY.REQUEST,
})

export const closeSuccessStory = () => ({
  type: CLOSE_SUCCESS_STORY.REQUEST,
})

export const openChart = () => ({
  type: OPEN_CHART.REQUEST,
})

export const closeChart = () => ({
  type: CLOSE_CHART.REQUEST,
})

export const openResultPage = () => ({
  type: OPEN_RESULT_PAGE.REQUEST,
})

export const setStepData = (name) => ({
  type: SET_STEP_DATA.REQUEST,
  payload: {
    name
  }
})

export const updateStepData = (name, key, value) => ({
  type: UPDATE_STEP_DATA.REQUEST,
  payload: {
    name,
    key,
    value,
  }
})

export const setFailValidate = (name, message) => ({
  type: VALIDATE_STEP_DATA.ERROR,
  payload: {
    name,
    message,
  }
})

export const setSuccessValidate = (name) => ({
  type: VALIDATE_STEP_DATA.SUCCESS,
  payload: {
    name,
  }
})

export const clearData = () => ({
  type: CLEAR_STEP_DATA.REQUEST,
})

export const clearStep = (name) => ({
  type: CLEAR_STEP.REQUEST,
  payload: {
    name,
  }
})