import { get } from 'lodash';
import { connect } from 'react-redux'

import {
  openSuccessStory as onOpenSuccessStory,
  closeSuccessStory as onCloseSuccessStory,
} from '../../actions/stepsStateActions'

const mapStateToProps = (state) => ({
  openSuccessStory: get(state, ["stepsState", "openSuccessStory"], false),
  closeSuccessStory: get(state, ["stepsState", "closeSuccessStory"], false),
});

const mapDispatchToProps = {
  onOpenSuccessStory,
  onCloseSuccessStory,
}

export const withLoader = Component => connect(mapStateToProps, mapDispatchToProps)(Component)