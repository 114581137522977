import React from 'react'
import { get, has } from "lodash";
import { withStepsState } from "./containers/containerStepsState"
import { withAppStepsState } from "./containers/containerAppStepsState"
import styles from "./toNextStepButton.module.scss"
import next from './img/next.svg'

const ToNextStepButton = props => {
const { onToNextStep, currentIndex, data } = props

  let stepsWithInput;

  function checkstepsWithInput() {

    if
      ( 
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "height") &&
        (has(data, ["currentWeight"]))
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

    else if
      ( 
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "currentWeight") &&
        (data[Object.keys(data)[currentIndex]]) &&
        (has(data, ["goalWeight"]))
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

    else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "goalWeight") &&
        (has(data, ["importantEvent"]))
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

      else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "importantEventDate") &&
        (has(data, ["lifestyle"])) 
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

    else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "mail")
      ) 
      {
        stepsWithInput = false;
        return stepsWithInput;
      }


      else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "visibleResultProgress") &&
        (has(data, ["old"])) 
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

      else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "achievedProgress") &&
        (has(data, ["ailments"])) 
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

      else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "helps") &&
        (has(data, ["idealWeight"])) 
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

      else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) === "goalsPlanProgress") &&
        (has(data, ["goalsPlan"]))  
      ) 
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

    else if
      (
        (data[Object.keys(data)[currentIndex]]) &&
        ((Object.keys(data)[currentIndex]) !== "height") &&
        ((Object.keys(data)[currentIndex]) !== "goalWeight") &&
        ((Object.keys(data)[currentIndex]) !== "currentWeight") &&
        ((Object.keys(data)[currentIndex]) !== "importantEventDate") &&
        ((Object.keys(data)[currentIndex]) !== "visibleResultProgress") &&
        ((Object.keys(data)[currentIndex]) !== "achievedProgress") &&
        ((Object.keys(data)[currentIndex]) !== "goalsPlanProgress") &&
        (data[Object.keys(data)[currentIndex]].value)
      )
      {
        stepsWithInput = true;
        return stepsWithInput;
      }

    // return stepsWithInput;
  }

  return (
    <>

        { checkstepsWithInput() && (
          <button
            className={styles.toNextStepButton}
            onClick={onToNextStep}
          >
            <img src={next} alt="next" />
          </button>
        ) }

    </>
  )
}

export default withStepsState(withAppStepsState(ToNextStepButton))
