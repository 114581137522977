import { isNull, get } from "lodash"

const BOTTOM_LINE_LBS = 44
const TOP_LINE_LBS = 659.9
const BELOW_BOTTOM_LINE_LBS_MESSAGE = "Your weight is too low for this program."
const ABOVE_TOP_LINE_LBS_MESSAGE = "Your weight is too big to work on this program."

const BOTTOM_LINE_KG = 20
const TOP_LINE_KG = 299.9
const BELOW_BOTTOM_LINE_KG_MESSAGE = "Your weight is too low for this program."
const ABOVE_TOP_LINE_KG_MESSAGE = "Your weight is too big to work on this program."

export const checkIsValidLbsCurrentWeight = data => {
    return validateCurrentWeightLbsStep(get(data, ["currentWeight", "value"], "")) && !data.currentWeight.isValid
}

export const checkIsValidKgCurrentWeight = data => {
    return validateCurrentWeightKgStep(get(data, ["currentWeight", "value"], "")) && !data.currentWeight.isValid
}

export const checkIsChoosedCurrentWeightType = (data, type) => {
    return get(data, ["currentWeight", "type"], null) === type
}

export const isFailureLbsValidation = data => {
    return !validateCurrentWeightLbsStep(get(data, ["currentWeight", "value"], ""))
        && get(data, ["currentWeight", "validString"], "") !== getValidateLbsMessage(get(data, ["currentWeight", "value"], ""))
}

export const isFailureKgValidation = data => {
    return !validateCurrentWeightKgStep(get(data, ["currentWeight", "value"], ""))
    && get(data, ["currentWeight", "validString"], "") !== getValidateKgMessage(get(data, ["currentWeight", "value"], ""))
}

export const validateCurrentWeightKgStep = value => {
    return !isNull(value)
        ? value >= BOTTOM_LINE_KG && value <= TOP_LINE_KG
        : false
}

export const getValidateKgMessage = value => {
    let message = ""

    if (!isNull(value))
        if (value <= BOTTOM_LINE_KG)
            message = BELOW_BOTTOM_LINE_KG_MESSAGE
        else if (value >= TOP_LINE_KG)
            message = ABOVE_TOP_LINE_KG_MESSAGE

    return message
}

export const validateCurrentWeightLbsStep = value => {
    return !isNull(value)
        ? value >= BOTTOM_LINE_LBS && value <= TOP_LINE_LBS
        : false
}

export const getValidateLbsMessage = value => {
    let message = ""

    if (!isNull(value))
        if (value <= BOTTOM_LINE_LBS)
            message = BELOW_BOTTOM_LINE_LBS_MESSAGE
        else if (value >= TOP_LINE_LBS)
            message = ABOVE_TOP_LINE_LBS_MESSAGE

    return message
}

export const currentWeightTypeData = {
    lbs: {
        value: "lbs",
        title: "LBS",
    },
    kg: {
        value: "kg",
        title: "KG",
    },
}