import React, { Component } from "react";
import { get, isNull, has } from "lodash";
import { withStepsState } from "./../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "./../../../shared/services/service.step";
import { resultData } from "./static.resultData";
import { steps } from "./../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./result.module.scss";
import logoImage from "./img/logo.svg";
import blockContent from "./../../../utils/blockContent";

class Result extends Component {
  constructor() {
    super();

    this.state = {
      isOpenResult: false,
    };
    this.indexResult = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.result) &&
      !this.state.isOpenResult
    )
      this.setState({ isOpenResult: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onClearStep, onSetStepData } = this.props;
    const { isOpenResult } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.result,
      isOpen: isOpenResult,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenResult && !has(data, ["result"])) onSetStepData("result");

    updateOpenStepState({ params });

    if(isOpenResult && this.indexResult === 0) {
      this.indexResult = 1;
      this.dataLayerAddEventScreen();
    }
    if(!isOpenResult) {
      this.indexResult = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenResult: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {

    let endTime = new Date();

    window.dataLayer.push({
      'event': 'quiz_funnel',
      'quiz_activity': 'start_quiz'
    });

    window.dataLayer.push({
      event: "user_answers",
      quiz_question: "goal",
      quiz_answer: answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });

    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "goal",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenResult } = this.state;
    const params = {
      className: "wrapperResult",
      currentIndex: currentIndex,
      stepIndex: steps.result,
    };

    let startTime = new Date();

    return isOpenResult ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <div className={styles.stepContentContainer}>
            <img className={styles.logoImage} src={logoImage} alt="logo" />
            <p className={styles.logoTitle}>Weight Loss Running by Slimkit</p>
            <h2 className={styles.subtitle}>Get a new you in 16 weeks</h2>
            <h1 className={styles.title}>
              Find out what you can<br/>achieve with our running app
            </h1>
            <p className={styles.text}>To start please select a goal:</p>

            <div className={styles.buttonsWrapper}>
              <div className={styles.button}>
                <RadioCheckButton
                  isChecked={
                    get(data, ["result", "value"], null) === "improve life"
                  }
                  onClick={
                    get(data, ["result", "value"], null) !== "improve life"
                      ? onUpdateStepData.bind(
                          this,
                          "result",
                          "value",
                          "improve life"
                        )
                      : () => {}
                  }
                  identify={`result${0}`}
                  onClickNextStep={
                    onToNextStep !== false
                      ? () => {
                        blockContent();
                          setTimeout(() => {
                            onToNextStep();
                            this.dataLayerAddEvent("improve life", startTime);
                          }, 400);
                        }
                      : () => {}
                  }
                >
                  Improve my
                  <br />
                  health for life
                </RadioCheckButton>
              </div>

              <div className={styles.button}>
                <RadioCheckButton
                  isChecked={
                    get(data, ["result", "value"], null) === "weight loss"
                  }
                  onClick={
                    get(data, ["result", "value"], null) !== "weight loss"
                      ? onUpdateStepData.bind(
                          this,
                          "result",
                          "value",
                          "weight loss"
                        )
                      : () => {}
                  }
                  identify={`result${1}`}
                  onClickNextStep={
                    onToNextStep !== false
                      ? () => {
                        blockContent();
                          setTimeout(() => {
                            onToNextStep();
                            this.dataLayerAddEvent("weight loss", startTime);
                          }, 400);
                        }
                      : () => {}
                  }
                >
                  Lose weight
                  <br />
                  for life
                </RadioCheckButton>
              </div>
            </div>
          </div>
          <p className={styles.description}>
            Results vary depending on your starting point, goals and effort.
          </p>
        </div>
      </div>
    ) : null;
  }
}

export default withStepsState(Result);
