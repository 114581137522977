import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { relateToMotivationData } from "./static.relateToMotivationData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import "./relateToMotivation.scss";

class RelateToMotivation extends Component {
  constructor() {
    super();

    this.state = {
      isOpenRelateToMotivation: false,
    };
    this.indexRelateToMotivation = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.relateToMotivation) &&
      !this.state.isOpenRelateToMotivation
    )
      this.setState({ isOpenRelateToMotivation: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenRelateToMotivation } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.relateToMotivation,
      isOpen: isOpenRelateToMotivation,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenRelateToMotivation && this.indexRelateToMotivation === 0) {
      this.indexRelateToMotivation = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenRelateToMotivation) {
      this.indexRelateToMotivation = 0;
    }

    if (isOpenRelateToMotivation && !has(data, ["relateToMotivation"])) onSetStepData("relateToMotivation");

    updateOpenStepState({ params });
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenRelateToMotivation: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'statementNeedMotivation',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "statementNeedMotivation",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenRelateToMotivation } = this.state;
    const params = {
      className: "wrapperRelateToMotivation",
      currentIndex: currentIndex,
      stepIndex: steps.relateToMotivation,
    };
    let startTime = new Date();

    return isOpenRelateToMotivation ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>
            Do you relate to the following statement?
          </FirstTitle>
          <div className="relateToContent">
            <p>“I need some <span>extra motivation</span> to workout regularly”</p>
          </div>
          <div className="relateToButtonWrapper">
            {Object.keys(relateToMotivationData).map((key, index) => {
              return (
                <RadioCheckButton
                  key={index}
                  isChecked={
                    get(data, ["relateToMotivation", "value"], null) ===
                    relateToMotivationData[key].value
                  }
                  onClick={
                    get(data, ["relateToMotivation", "value"], null) !==
                      relateToMotivationData[key].value
                      ? onUpdateStepData.bind(
                        this,
                        "relateToMotivation",
                        "value",
                        relateToMotivationData[key].value
                      )
                      : () => { }
                  }
                  identify={`relateToMotivation${index}`}
                  onClickNextStep={onToNextStep !== false ? () => {
                    setTimeout(() => {
                      onToNextStep();
                      this.dataLayerAddEvent(get(relateToMotivationData, [key, "title"]), startTime
                      )
                    }, 400);
                  } : () => { }
                  }
                >
                  {relateToMotivationData[key].title}
                </RadioCheckButton>
              );
            })}
            <p>Not at all</p>
            <p>Totally</p>
          </div>
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(RelateToMotivation);
