import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { workHardData } from "./static.workHardData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import blockContent from "./../../../utils/blockContent";

class WorkHard extends Component {
  constructor() {
    super();

    this.state = {
      isOpenWorkHard: false,
    };
    this.indexWorkHard = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.workHard) &&
      !this.state.isOpenWorkHard
    )
      this.setState({ isOpenWorkHard: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenWorkHard } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.workHard,
      isOpen: isOpenWorkHard,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenWorkHard && !has(data, ["workHard"])) onSetStepData("workHard");

    updateOpenStepState({ params });

    if(isOpenWorkHard && this.indexWorkHard === 0) {
      this.indexWorkHard = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      }) 
    }
    if(!isOpenWorkHard) {
      this.indexWorkHard = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenWorkHard: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();
    
    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'workFrequency',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "workFrequency",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenWorkHard } = this.state;
    const params = {
      className: "wrapperWorkHard",
      currentIndex: currentIndex,
      stepIndex: steps.workHard,
    };
    let startTime = new Date();

    return isOpenWorkHard ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>How often would you like to run?</FirstTitle>

          {Object.keys(workHardData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["workHard", "value"], null) ===
                  workHardData[key].value
                }
                onClick={
                  get(data, ["workHard", "value"], null) !==
                  workHardData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "workHard",
                        "value",
                        workHardData[key].value
                      )
                    : () => {}
                }
                icon={workHardData[key].icon}
                identify={`work${index}`}
                onClickNextStep={onToNextStep !== false ? () => {
                  blockContent();
                  setTimeout(() => {
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(workHardData, [key, "title"]), startTime
                    )}, 400); } : () => {}
                }
              >
                {workHardData[key].title}
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(WorkHard);
