import React from 'react';

import { withStepsState } from "./../../../shared/containers/containerStepsState"

import { steps } from "./../../../shared/static.steps"

import styles from "./progressSteps.module.scss"

const ProgressSteps = props => {
  const { currentIndex } = props;

  return (
    <div className={styles.progressSteps}>      
      {
          Object.keys(steps).map((key, index) => {
            return (
              <div
                className={steps[key] <= currentIndex - 1  ? styles.progressItemActive : styles.progressItem}
                key={index}
              />
            )
          })
        }
    </div>
  )
}

export default withStepsState(ProgressSteps);