export const steps = {
  result: 0,
  fitnesLevel: 1,
  workHard: 2,
  workTime: 3,
  typicalDay: 4,
  sleep: 5,
  water: 6,
  jobActive: 7,
  sex: 8,
  visibleResultProgress: 9,
  old: 10,
  bodyType: 11,
  height: 12,
  currentWeight: 13,
  goalWeight: 14,
  importantEvent: 15,
  importantEventDate: 16,
  lifestyle: 17,
  helps: 18,
  idealWeight: 19,
  relateToLevel: 20,
  relateToSurrounding: 21,
  relateToTime: 22,
  relateToWeight: 23,
  relateToMotivation: 24,
  achievedProgress: 25,
  ailments: 26,
  medications: 27,
  goalsPlanProgress: 28,
  goalsPlan: 29,
  workouts: 30,
  successStory: 31,
  successStoryResult: 32,
  mail: 33,
};
