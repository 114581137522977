import { isNull, get } from "lodash"

import td1 from "./img/td1.png"
import td2 from "./img/td2.png"
import td3 from "./img/td3.png"
import td4 from "./img/td4.png"

export const checkIsValidTypicalDay = data => {
    return validateTypicalDayStep(get(data, ["typicalDay", "value"], "")) && !data.typicalDay.isValid
}

export const validateTypicalDayStep = value => {
    return !isNull(value)
}

export const typicalDayData = {
    td1: {
        value: "At the office",
        title: "At the office",
        icon: td1,
    },
    td2: {
        value: "Daily long walks",
        title: "Daily long walks",
        icon: td2,
    },
    td3: {
        value: "Physical work",
        title: "Physical work",
        icon: td3,
    },
    td4: {
        value: "Mostly at home",
        title: "Mostly at home",
        icon: td4,
    },
}

export const defaultFasting = false