import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  toNextStep as onToNextStep,
  toPrevStep as onToPrevStep,
  setStepData as onSetStepData,
  updateStepData as onUpdateStepData,
  setFailValidate as onSetFailValidate,
  setSuccessValidate as onSetSuccessValidate,
  clearStep as onClearStep,
  openResultPage as onOpenResultPage,
} from '../../actions/stepsStateActions'

const mapStateToProps = (state) => ({
  currentIndex: get(state, ["stepsState", "currentIndex"], 0),
  openLoaderResult: get(state, ["stepsState", "openLoaderResult"], false),
  openSuccessStory: get(state, ["stepsState", "openSuccessStory"], false),
  openChart: get(state, ["stepsState", "openChart"], false),
  data: get(state, ["stepsState", "data"], []),
});

const mapDispatchToProps = {
  onToNextStep,
  onToPrevStep,
  onSetStepData,
  onUpdateStepData,
  onSetFailValidate,
  onSetSuccessValidate,
  onClearStep,
  onOpenResultPage,
}

export const withStepsState = Component => connect(mapStateToProps, mapDispatchToProps)(Component)