import { isNull, get } from "lodash"

import wh1 from "./img/wh1.png"
import wh2 from "./img/wh2.png"
import wh3 from "./img/wh3.png"
import wh4 from "./img/wh4.png"

export const checkIsValidWorkHard = data => {
    return validateWorkHardStep(get(data, ["workHard", "value"], "")) && !data.workHard.isValid
}

export const validateWorkHardStep = value => {
    return !isNull(value)
}

export const workHardData = {
    minimum: {
        value:"minimum",
        title: "2 times a week",
        a: 1.375,
        icon: wh1,
    },
    normal: {
        value:"normal",
        title: "3 times a week",
        a: 1.55,
        icon: wh2,
    },
    maximum: {
        value:"maximum",
        title: "4 times a week",
        a: 1.725,
        icon: wh3,
    },
    vervDecide: {
        value:"vervDecide",
        title: "I don’t know",
        a: 1.375,
        icon: wh4,
    },
}

export const defaultFasting = false