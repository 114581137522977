import React from "react"

import styles from "./stepControl.module.scss"

const StepControl = props =>{
    return (
        <div className={`${styles.stepControl} stepControl`}>
            {props.children}
        </div>
    )
}

export default StepControl;