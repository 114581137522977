import { isNull, get } from "lodash";

import iw1 from "./img/iw1.png";
import iw2 from "./img/iw2.png";
import iw3 from "./img/iw3.png";
import iw4 from "./img/iw4.png";

export const checkIsValidIdealWeight = data => {
    return validateIdealWeightStep(get(data, ["idealWeight", "value"], "")) && !data.idealWeight.isValid
}

export const validateIdealWeightStep = value => {
    return !isNull(value)
}

export const idealWeightData = {
    one: {
        value:"My life would do a complete 180 and I would be a brand new person",
        title: "My life would do a complete 180 and I would be a brand new person",
        icon: iw1,
    },
    two: {
        value:"I would be more confident and be able to do more",
        title: "I would be more confident and be able to do more",
        icon: iw2,
    },
    three: {
        value:"I would be much happier and even take up new activities",
        title: "I would be much happier and even take up new activities",
        icon: iw3,
    },
    four: {
        value:"My life wouldn’t change",
        title: "My life wouldn’t change",
        icon: iw4,
    },
}

export const defaultFasting = false