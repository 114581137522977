import { isNull, get } from "lodash"

import bt1 from "./img/bt1.png"
import bt2 from "./img/bt2.png"
import bt3 from "./img/bt3.png"
import bt4 from "./img/bt4.png"
import bt5 from "./img/bt5.png"

import btm1 from "./img/btm1.png"
import btm2 from "./img/btm2.png"
import btm3 from "./img/btm3.png"
import btm4 from "./img/btm4.png"
import btm5 from "./img/btm5.png"

export const checkIsValidBodyType = data => {
    return validateBodyTypeStep(get(data, ["bodyType", "value"], "")) && !data.bodyType.isValid
}

export const validateBodyTypeStep = value => {
    return !isNull(value)
}

const bodyTypeFemale = {
    bodyType1: {
        value: "Apple-shaped",
        title: "Apple-shaped",
        description: "Naturally wide torso and broad shoulders",
        icon: bt1,
    },
    bodyType2: {
        value: "Pear-shaped",
        title: "Pear-shaped",
        description: "Naturally slimmer shoulders and thicker thighs",
        icon: bt2,
    },
    bodyType3: {
        value: "Square-shaped",
        title: "Square-shaped",
        description: "Naturally wide shoulders and thighs",
        icon: bt3,
    },
    bodyType4: {
        value: "Hourglass",
        title: "Hourglass",
        description: "Wide bust and hips, a narrow waist",
        icon: bt4,
    },
    bodyType5: {
        value: "Inverted-triangle",
        title: "Inverted triangle",
        description: "Wider shoulders and slimmer hips",
        icon: bt5,
    }
}

const bodyTypeMale = {
    bodyType1: {
        value: "Apple-shaped",
        title: "Apple-shaped",
        description: "Naturally wide torso and broad shoulders",
        icon: btm1,
    },
    bodyType2: {
        value: "Pear-shaped",
        title: "Pear-shaped",
        description: "Naturally slimmer shoulders and thicker thighs",
        icon: btm2,
    },
    bodyType3: {
        value: "Square-shaped",
        title: "Square-shaped",
        description: "Naturally wide shoulders and thighs",
        icon: btm3,
    },
    bodyType4: {
        value: "Hourglass",
        title: "Hourglass",
        description: "Wide bust and hips, a narrow waist",
        icon: btm4,
    },
    bodyType5: {
        value: "Inverted-triangle",
        title: "Inverted triangle",
        description: "Wider shoulders and slimmer hips",
        icon: btm5,
    }
}

export const defaultFasting = false

export const bodyTypeData = {
    female: bodyTypeFemale,
    male: bodyTypeMale,
}