import React, { Component } from 'react';
import { get } from "lodash";

import { withStepsState } from "../../../shared/containers/containerStepsState";
import { withLoader } from "../../../shared/containers/containerLoader";

import { checkIsValidGoalsPlan } from "./static.goalsPlanData";
import ButtonFill from "../../../shared/items/controls/buttonFill";
import styles from "./goalsPlan.module.scss";
import blockContent from "./../../../utils/blockContent"

class GoalsPlanNextStepButton extends Component {
  componentDidUpdate() {
    const { data, onSetSuccessValidate } = this.props

    if (checkIsValidGoalsPlan(data))
      onSetSuccessValidate("goalsPlan")
  }

  render() {
    const { onToNextStep, dataLayerAddEvent } = this.props;
    let startTime = new Date();

    return (
      <div className={styles.goalsPlanButtonWrapper}>
        <ButtonFill 
          onClick={onToNextStep !== false ? () => {
            blockContent();
            setTimeout(() => {
              onToNextStep();
              dataLayerAddEvent(startTime);
              }, 400); } : () => {}
          }
        >
          {this.props.children}
        </ButtonFill>
      </div>
    )
  }
}

export default withStepsState(withLoader(GoalsPlanNextStepButton))