import { flatMap } from "lodash";
import React, { useState } from "react";
import "./successStoryModal.scss";

const SuccessStoryModal = ({props, title, onClick}) => {


  return (
    <div className="SuccessStoryModal">
      <div className="SuccessStoryModalBody">
        <h2>{title}</h2>
        <div className="SuccessStoryModalItems">
          {props.map((prop, index) =>
              <button className={"SuccessStoryModalItem"}
              key={ Math.random()}
              index={index}
              onClick={() =>  onClick(prop.cont)}
              >
                <p>{prop.cont}</p>
              </button>
            )}
        </div>
      </div>
    </div>
  )
}

export default SuccessStoryModal;
