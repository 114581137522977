import { isNull, get } from "lodash";

import wd1 from "./img/wd1.png";
import wd2 from "./img/wd2.png";
import wd3 from "./img/wd3.png";
import wd4 from "./img/wd4.png";

export const checkIsValidWater = data => {
    return validateWaterStep(get(data, ["water", "value"], "")) && !data.water.isValid
}

export const validateWaterStep = value => {
    return !isNull(value)
}

export const waterData = {
    one: {
        value:"I only have coffee or tea",
        title: "I only have coffee or tea",
        icon: wd1,
    },
    two: {
        value:"About 2 glasses (16 oz)",
        title: "About 2 glasses (16 oz)",
        icon: wd2,
    },
    three: {
        value:"2 to 6 glasses (16-48 oz)",
        title: "2 to 6 glasses (16-48 oz)",
        icon: wd3,
    },
    four: {
        value:"More than 6 glasses",
        title: "More than 6 glasses",
        icon: wd4,
    },
}

export const defaultFasting = false