import { isNull, get } from "lodash"

import wt1 from "./img/wt1.png"
import wt2 from "./img/wt2.png"
import wt3 from "./img/wt3.png"
import wt4 from "./img/wt4.png"

export const checkIsValidWorkTime = data => {
    return validateWorkTimeStep(get(data, ["workTime", "value"], "")) && !data.workTime.isValid
}

export const validateWorkTimeStep = value => {
    return !isNull(value)
}

export const workTimeData = {
    minimum: {
        value:"minimum",
        title: "Less than 10 min",
        icon: wt1,
    },
    normal: {
        value:"normal",
        title: "10-20 min",
        icon: wt2,
    },
    maximum: {
        value:"maximum",
        title: "More than 20 min",
        icon: wt3,
    },
    vervDecide: {
        value:"vervDecide",
        title: "Let us decide",
        icon: wt4,
    },
}

export const defaultFasting = false