import { isNull, get } from "lodash"

import wt1 from "./img/wt1.png"
import wt2 from "./img/wt2.png"

export const checkIsValidSuccessStoryResult = data => {
    return validateSuccessStoryResultStep(get(data, ["successStoryResult", "value"], "")) && !data.successStoryResult.isValid
}

export const validateSuccessStoryResultStep = value => {
    return !isNull(value)
}

export const successStoryResultData = {
    minimum: {
        value:"value 1",
        title: "title 1",
        icon: wt1,
    },
    normal: {
        value:"value 2",
        title: "title 2",
        icon: wt2,
    },
}

export const defaultFasting = false