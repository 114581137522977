import { isNull, get } from "lodash"

import a1 from "./img/a1.png"
import a2 from "./img/a2.png"
import a3 from "./img/a3.png"
import a4 from "./img/a4.png"

export const checkIsValidAilments = data => {
    return validateAilmentsStep(get(data, ["ailments", "value"], "")) && !data.ailments.isValid
}

export const validateAilmentsStep = value => {
    return !isNull(value)
}

export const ailmentsData = {
    diabetes: {
        value: "diabetes",
        title: "I have diabetes",
        icon: a1,
    },
    astma: {
        value: "astma",
        title: "I have astma",
        icon: a2,
    },
    joint: {
        value: "joint",
        title: "I have joint diseases",
        icon: a3,
    },
    none: {
        value: "none",
        title: "None of the above",
        icon: a4,
    },
}

export const defaultFasting = false