import variables from "./../../assets/styles/values.scss"

export const isCurrentStep = (currentIndex, stepIndex) => {
  return currentIndex === stepIndex
}

export const stateStepClassName = (currentIndex, stepIndex) => {
  return isCurrentStep(currentIndex, stepIndex)
    ? "-IsCurrent"
    : "-IsHiding"
}

export const buildStepClassName = ({ params: { className, currentIndex, stepIndex } }) => {
  const string = className
    + "-step_animation"
    + stateStepClassName(currentIndex, stepIndex)

  return string
}

export const updateOpenStepState = ({ params: { currentIndex, prevIndex, stepIndex, isOpen, handleChange } }) => {
  let timeOut

  if (!isCurrentStep(currentIndex, stepIndex) && prevIndex !== currentIndex && prevIndex === stepIndex) {
    clearInterval(timeOut)
    timeOut = setTimeout(function () {
      if (isOpen)
        handleChange(false)
    }.bind(this), variables.animateSteps)
  }

  if (isCurrentStep(currentIndex, stepIndex) && prevIndex !== currentIndex) {
    clearInterval(timeOut)
    handleChange(true)
  }
}