import React, { Component } from 'react'
import { get, has } from "lodash"
import { Input } from 'antd';
import { debounce } from 'throttle-debounce';

import variables from "./../../../assets/styles/values.scss"
import styles from "./inputWrapperMail.module.scss"

class InputWrapperMail extends Component {
  constructor(props) {
    super(props);
    // this.inputFocusRef = React.createRef();   
    this.handleChangeDebounce = debounce(variables.delayInputTrigger, !!this.props.onChange ? this.props.onChange.bind(this) : () => { })
  }

  componentDidUpdate() {
    // this.inputFocusRef.current.focus();
  }

  componentDidMount() {
    const { name, onChange, keyName, focus } = this.props
    let tag = document.getElementById(name)

    tag.setAttribute("spellcheck", "false");
    if(name === 'mail') {
      tag.setAttribute('type', 'email');
    }

    if (!has(this.props, "valueStore") && onChange)
      onChange(name, keyName, get(this.props, "defaultValue", ""))

      // if (focus)
      //   tag.focus()
      // this.inputFocusRef.current.focus();
      if(name === 'mail') {
        tag.addEventListener('focus', () => {
          window.dataLayer.push({
            event: "quiz_funnel",
            quiz_activity: "keyboard_activation",
          });
          console.log(window.dataLayer);
        })
      }
  }

  handleChange(e) {
    const { name, keyName, valueStore } = this.props

    if (has(e, ["target", "value"]))
      if (e.target.value.length !== valueStore)
        this.handleChangeDebounce(name, keyName, e.target.value)
  }

  handleBlur(e) {
    const { name, keyName } = this.props

    if (e.target.value !== get(this.props, "valueStore") && has(this.props, "valueStore"))
      this.handleChangeDebounce(name, keyName, e.target.value)
  }

  render() {
    const { name } = this.props

    return (
      <div className={styles.inputWrapper}>
        <Input
          className={styles.input}
          id={name}
          placeholder={get(this.props, "placeholder", "")}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          defaultValue={get(this.props, "valueStore", "")}
          disabled={get(this.props, ["disabled"], false)}
          // ref={this.inputFocusRef}
        />

      { this.props.label &&
        <div className={styles.inputLabel}>{this.props.label}</div>
      }

      </div>
    )
  }
}

export default React.memo(InputWrapperMail)