import React, { Component } from "react";
import { get } from "lodash";
import aes from "crypto-js/aes";
import { withLoader } from "../../../shared/containers/containerSuccess";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import {
  getValidateMailMessage,
  checkIsValidMail,
  isFailureMailValidation,
} from "./static.mailData";
import fixFocus from "./../../../utils/fixFocus";
import ButtonFill from "../../../shared/items/controls/buttonFill";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\$&%#.,;:\s@\"]+(\.[^<>()[\]\\$&%#.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(email);
}

var timeUnixNow = Date.now();
let onboardingV1 = 'true';

class MailNextStepButton extends Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);

    this.state = {
      linkFromOnboarding: "",
    };
  }

  getParameterFromURL(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  setFbp() {
    let fbpTime = +new Date();
    let fbpRandomNumbers = Math.floor(Math.random() * 10000000000);
    return `fb.1.${fbpTime}.${fbpRandomNumbers}`;
  }

  generateOneLink(data) {
    this.setState({
      linkFromOnboarding: data.data.onelink,
    });
  }

  componentDidUpdate(prevProps) {
    const { data, onSetSuccessValidate, onSetFailValidate } = this.props;

    if (checkIsValidMail(data)) {
      onSetSuccessValidate("mail");
    }

    if (isFailureMailValidation(data))
      onSetFailValidate(
        "mail",
        getValidateMailMessage(get(data, ["mail", "value"], null))
      );

    
      let pidValue;

      if (this.getParameterFromURL('af_pid')) {
        pidValue = this.getParameterFromURL('af_pid');
      } else if (this.getParameterFromURL('utm_source')) {
        pidValue = this.getParameterFromURL('utm_source');
      }

      if (pidValue === '') {
        pidValue = document.referrer;
      }

      let campaignValue = null;

      if (this.getParameterFromURL('af_c')) {
          campaignValue = this.getParameterFromURL('af_c');
      } else if (this.getParameterFromURL('utm_campaign')) {
          campaignValue = this.getParameterFromURL('utm_campaign');
      }

      let key;

      if (onboardingV1 === 'true') {
        key = 'running-paid-web-onboarding'
      } else {
        key = 'running-free-web-onboarding'
      }

      if (campaignValue === null) {
        if (onboardingV1 === 'true') {
          campaignValue = 'running-paid-web-onboarding'
        } else {
          campaignValue = 'running-free-web-onboarding'
        }
      }

      let fbp = this.readCookie('_fbp');

      let fbclidValue = this.getParameterFromURL('fbclid');

      let fbcFinal = null;
  
      if (fbclidValue) fbcFinal = `fb.1.${timeUnixNow}.${fbclidValue}`;

      let fbc = this.readCookie('_fbc') ? this.readCookie('_fbc') : fbcFinal;

      let af_prt = this.getParameterFromURL('af_prt');
      let af_adset = this.getParameterFromURL('utm_term');
      let af_ad = this.getParameterFromURL('utm_content');

      let onelink_params = {
        pid: pidValue,
        c: campaignValue,
        landingType: 'running-free-web-onboarding',
        ...(af_prt) && {af_prt: af_prt},
        ...(af_adset) && {af_adset: af_adset},
        ...(af_ad) && {af_ad: af_ad},
        deep_link_value: 'running-free-web-onboarding',
      };

      let dataToSend = {
        'bundle_id': 'runningforweightlosspro',
        'email': this.props.data.mail.value,
        'extra_data': {
          'campaign_name': campaignValue,
          'pid': pidValue,
          'gender': this.props.data.sex.value,
          'language': window.navigator.language,
          'source_key': key,
          'source_url': window.location.href,
          ...(fbp) && {fbp: fbp},
        },
        'onelink_query_params': onelink_params,
        ...(fbc) && {fbclickid: fbc},
      };

      if (onboardingV1 !== 'true') {

        fetch(`https://api-test.verv.com/v4/web_extra_data`, { 
          method: "POST",
          body: JSON.stringify(dataToSend)
            }).then((response) => {
              return response.json();
        })
        .then((data) => {
          this.generateOneLink(data);
      })

      }

      if (onboardingV1 === 'true') {
        let fbclidValue = this.getParameterFromURL('fbclid');

        if (campaignValue === null) {
          campaignValue = 'running-paid-web-onboarding';
        }
  
        let fbcFinal = null;
  
        if (fbclidValue) fbcFinal = `fb.1.${timeUnixNow}.${fbclidValue}`;
  
        let fbc = this.readCookie('_fbc') ? this.readCookie('_fbc') : fbcFinal;
        let data = this.props.data;
        let emailValue = this.props.data.mail ? this.props.data.mail.value : false;
        let cw = data.currentWeight.value ? Math.round(data.currentWeight.value).toFixed(0) : false;
        let cwu = data.currentWeight.type ? data.currentWeight.type : false;
        let gw = data.goalWeight.value ? Math.round(data.goalWeight.value).toFixed(0) : false;
        let gwu = data.goalWeight.type ? data.goalWeight.type : false;
        let uGoal = data.result.value ? data.result.value : false;

        if (emailValue) {
          emailValue = aes.encrypt(emailValue, '9213812384').toString();
        }
  
        let link = `${process.env.REACT_APP_LINK_TO_STRIPE}/?` +
          `fbc=${fbc}&` +
          `gender=${this.props.data.sex.value}&` +
          `c=${campaignValue}&` +
          `source=running-paid-web-onboarding&` +
          `cw=${cw}&` +
          `cwu=${cwu}&` +
          `gw=${gw}&` +
          `gwu=${gwu}&` +
          `uGoal=${uGoal}&` +
          `pid=${pidValue}`;

        if (af_prt != null) link += `&af_prt=${af_prt}`;
        if (af_adset != null) link += `&utm_term=${af_adset}`;
        if (af_ad != null) link += `&utm_content=${af_ad}`;
        if (fbp) link += `&fbp=${fbp}`;
        if (emailValue != null) link += `&ev=${emailValue}`;

        if(prevProps.data !== this.props.data) {
          this.setState({ linkFromOnboarding: link});
        }
        
      }

  }

  validate(data) {
    let isLengthValid =
      get(data, ["mail", "value"]) && get(data, ["mail", "value"]).length < 63;
    let isMailValid = validateEmail(get(data, ["mail", "value"]));

    if (isLengthValid === true && isMailValid === true) {
      return false;
    } else return true;
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'send_email',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
   // console.log(window.dataLayer);
  }

  render() {
    const { linkFromOnboarding } = this.state
    const { data, startTimeMail} = this.props;

    function goNext() {
      // window.dataLayer.push({
      //   event: "send_email",
      //   email: get(data, ["mail", "value"]),
      // });
      fixFocus(false);
      window.location = linkFromOnboarding;
    }

    return (
      <ButtonFill
      disabled={this.validate(data)}
      onClick={() => {
        this.dataLayerAddEvent(get(data, ["mail", "value"]), startTimeMail);
        goNext();
      }}
    >
        {this.props.children}
      </ButtonFill>
    );
  }
}

export default withStepsState(withLoader(MailNextStepButton));