import React, { Component } from 'react'
import { get } from "lodash"

import {
  heightTypeData,
  getValidateCmMessage,
  getValidateFtMessage,
  checkIsValidFtHeight,
  checkIsValidCmHeight,
  checkIsChoosedHeightType,
  isFailureFtValidation,
  isFailureCmValidation,
} from "./static.heightData"

import { withStepsState } from "../../../shared/containers/containerStepsState"

import ButtonFill from "../../../shared/items/controls/buttonFill"


class HeightNextStepButton extends Component {
  componentDidUpdate() {
    const { data } = this.props

    if (checkIsChoosedHeightType(data, heightTypeData.ft.value)) {
      this.checkvValidHeightFt();
    }
      

    else if (checkIsChoosedHeightType(data, heightTypeData.cm.value))
      this.checkvValidHeightCm()
  }

  checkvValidHeightFt() {
    const { data, onSetFailValidate, onSetSuccessValidate } = this.props

    if (isFailureFtValidation(data))
      onSetFailValidate("height", getValidateFtMessage(get(data, ["height", "value"], null), get(data, ["height", "value2"], null)))

    else if (checkIsValidFtHeight(data))
      onSetSuccessValidate("height")
  }

  checkvValidHeightCm() {
    const { data, onSetFailValidate, onSetSuccessValidate } = this.props

    if (isFailureCmValidation(data))
      onSetFailValidate("height", getValidateCmMessage(get(data, ["height", "value"], null)))

    else if (checkIsValidCmHeight(data))
      onSetSuccessValidate("height")
  }

  dataLayerAddEvent(answer, answerUnit, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'height',
      'quiz_answer': `${answer} ${answerUnit}`,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
    
  }

  dataLayerAddEventFt(answer, answerUnit, answer2, startTime) {
    let endTime = new Date();

    if(answer2 === undefined || answer2 === '') {
      answer2 = 0;
    }
    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'height',
      'quiz_answer': `${answer} ${answerUnit} ${answer2} in`,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
    
  }

  render() {
    const { data, onToNextStep, startTimeHeight } = this.props

    return (
      <ButtonFill disabled={!get(data, ["height", "isValid"], false)} onClick={
         () => {
            onToNextStep();
            if(data.height.type === 'cm') {
              this.dataLayerAddEvent(get(data, ["height", "value"]), get(data, ["height", "type"]), startTimeHeight)
            }
            if(data.height.type === 'ft') {
              this.dataLayerAddEventFt(get(data, ["height", "value"]), get(data, ["height", "type"]), get(data, ["height", "value2"]), startTimeHeight)
            }
         }
        }
      >
        {this.props.children}
      </ButtonFill>
    )
  }
}

export default withStepsState(HeightNextStepButton)