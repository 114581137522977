import React, { Component } from 'react'
import { get, has, isNull, set } from "lodash"
import { InputNumber } from 'antd';
import { debounce } from 'throttle-debounce';

import variables from "./../../../assets/styles/values.scss"
import styles from "./inputNumberWrapperFt.module.scss"

class InputNumberWrapperFt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.placeholder
    }
    this.inputFocusRef = React.createRef();    
    this.handleChangeDebounce = debounce(variables.delayInputTrigger, !!this.props.onChange ? this.props.onChange.bind(this) : () => { })
  }

  componentDidUpdate() {
    // this.inputFocusRef.current.focus();
  }

  componentDidMount() {
    const { name, onChange, keyName, focus, placeholder } = this.props
    let tags = document.querySelectorAll(`[name=${name}]`);

    if(tags) {
      for(let i = 0; i < tags.length; i++) {
        let tag = tags[i];
        tag.setAttribute("type", "tel");
        tag.setAttribute("pattern", "[0-9]*");
        tag.setAttribute("spellcheck", "false");
        // tag.setAttribute('placeholder', this.placeholder);
        if (!has(this.props, "valueStore") && onChange)
        onChange(name, keyName, get(this.props, "defaultValue", ""))
      }
    }

    if(keyName === 'value') {
      this.inputFocusRef.current.focus();
    }

    // if(tags) {
    //   for(let i = 0; i < tags.length; i++) {
    //     let tag = tags[i];
    //     if(tag.onFocus) {
    //       console.log('s')
    //     }
    //   }
    // }

    // if(this.inputFocusRef.current.focus()) {
    //   this.inputFocusRef.current.placeholder('')
    // }

    // if (focus)
    //   tag.focus()
    
  }

  handleChange(value) {
    const { name, keyName, valueStore } = this.props

    if (value !== valueStore && !isNull(value)) {
      this.handleChangeDebounce(name, keyName, value)
    }

  }

  render() {
    const { name, placeholder, id } = this.props

    return (
      <div className={styles.inputNumberWrapperFt}>
        <InputNumber
          className={styles.input}
          name={name}
          id={id}
          placeholder={this.state.placeholder}
          onChange={this.handleChange.bind(this)}
          value={get(this.props, "valueStore", "")}
          disabled={get(this.props, "disabled", false)}
          ref={this.inputFocusRef}
          onFocus={() => {
            this.setState({placeholder: ''});
          }}
          onBlur={()=> {
            this.setState({placeholder: placeholder})
          }}
          // autoFocus
        />
      </div>
    )
  }
}

export default React.memo(InputNumberWrapperFt)