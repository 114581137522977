import React from "react"

import styles from "./stepContentWrapper.module.scss"

const StepContentWrapper = props =>{
    return (
        <div className={styles.stepContentWrapper}>
            {props.children}
        </div>
    )
}

export default StepContentWrapper;