import React, { Component } from 'react'
import { get, has, isNull } from "lodash"
import { InputNumber } from 'antd';
import { debounce } from 'throttle-debounce';

import variables from "./../../../assets/styles/values.scss";
import styles from "./inputDateWrapper.module.scss";

class InputDateWrapper extends Component {
  constructor(props) {
    super(props);
    // this.inputFocusRef = React.createRef();    
    this.handleChangeDebounce = this.props.onChange.bind(this)
  }

  componentDidUpdate() {
    // this.inputFocusRef.current.focus();
  }

  componentDidMount() {
    const { name, onChange, keyName, focus, type, data } = this.props
    let tag = document.getElementById(name);

    tag.setAttribute("placeholder", 'dd/mm/yyyy')

    if (!has(this.props, "valueStore") && onChange)
      onChange(name, keyName, get(this.props, "defaultValue", ""))

    // this.inputFocusRef.current.focus();
    if(get(data, ['importantEventDate', 'value'])) {
      tag.value = get(data, ['importantEventDate', 'value']);
      tag.style.setProperty('--sq-index', '-1');
    }
  }

  handleChange(value) {
    const { name, keyName, valueStore } = this.props

    let tag = document.getElementById(name);
    if(tag.value) {
      tag.style.setProperty('--sq-index', '-1')
    }
    if(!tag.value) {
      tag.style.setProperty('--sq-index', '1')
    }

    this.props.onChange.bind(this, 'importantEventDate', 'value', tag.value)
    if (value !== valueStore && !isNull(value)) {
      this.handleChangeDebounce(name, keyName, tag.value)
    }

  }

  render() {
    const { name, type, onChange, min } = this.props

    return (
      <div className={styles.inputNumberWrapper}>
        <input
          className={styles.input}
          id={name}
          onChange={this.handleChange.bind(this)}
          // value={get(this.props, "valueStore", "")}
          disabled={get(this.props, "disabled", false)}
          // ref={this.inputFocusRef}
          type={type}
          min={min}
        />
      </div>
    )
  }
}

export default React.memo(InputDateWrapper)