import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { ailmentsData } from "./static.ailmentsData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";

class Ailments extends Component {
  constructor() {
    super();

    this.state = {
      isOpenAilments: false,
    };
    this.indexAilments = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.ailments) &&
      !this.state.isOpenAilments
    )
      this.setState({ isOpenAilments: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenAilments } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.ailments,
      isOpen: isOpenAilments,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenAilments && this.indexAilments === 0) {
      this.indexAilments = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenAilments) {
      this.indexAilments = 0;
    }

    if (isOpenAilments && !has(data, ["ailments"])) onSetStepData("ailments");

    updateOpenStepState({ params });
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenAilments: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'ailments',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "ailments",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenAilments } = this.state;
    const params = {
      className: "wrapperAilments",
      currentIndex: currentIndex,
      stepIndex: steps.ailments,
    };
    let startTime = new Date();

    return isOpenAilments ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>Does any of the following apply to you?</FirstTitle>

          {Object.keys(ailmentsData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["ailments", "value"], null) ===
                  ailmentsData[key].value
                }
                onClick={
                  get(data, ["ailments", "value"], null) !==
                  ailmentsData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "ailments",
                        "value",
                        ailmentsData[key].value
                      )
                    : () => {}
                }
                icon={ailmentsData[key].icon}
                identify={`ailments${index}`}
                onClickNextStep={onToNextStep !== false ? () => {
                  setTimeout(() => {
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(ailmentsData, [key, "title"]), startTime
                    )}, 400); } : () => {}
                }
              >
                {ailmentsData[key].title}
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(Ailments);
