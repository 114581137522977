import React, { Component } from 'react'
import { get } from "lodash"

import {
  goalWeightTypeData,
  getValidateKgMessage,
  getValidateLbsMessage,
  checkIsValidLbsGoalWeight,
  checkIsValidKgGoalWeight,
  checkIsChoosedGoalWeightType,
  isFailureLbsValidation,
  isFailureKgValidation,
} from "./static.goalWeightData"

import { withStepsState } from "../../../shared/containers/containerStepsState"
import { withLoader } from "../../../shared/containers/containerChart"
import fixFocus from "./../../../utils/fixFocus"
import ButtonFill from "../../../shared/items/controls/buttonFill"


class GoalWeightNextStepButton extends Component {
  constructor() {
    super();

    this.openChart = this.openChart.bind(this);
  }

  componentDidUpdate() {
    const { data } = this.props

    if (checkIsChoosedGoalWeightType(data, goalWeightTypeData.lbs.value)) {
      this.checkvValidGoalWeightLbs()
    }

    else if (checkIsChoosedGoalWeightType(data, goalWeightTypeData.kg.value)) {
      this.checkvValidGoalWeightKg()
    }
  }

  openChart() {
    this.props.onOpenChart()

    setTimeout(() => {
      this.props.onToNextStep();
    }, 2300);

    setTimeout(() => {
      this.props.onCloseChart();
    }, 5300);
  }

  checkvValidGoalWeightLbs() {
    const { data, onSetFailValidate, onSetSuccessValidate } = this.props

    if (isFailureLbsValidation(data))
      onSetFailValidate("goalWeight", getValidateLbsMessage(get(data, ["goalWeight", "value"], null)))

    else if (checkIsValidLbsGoalWeight(data))
      onSetSuccessValidate("goalWeight")
  }

  checkvValidGoalWeightKg() {
    const { data, onSetFailValidate, onSetSuccessValidate } = this.props

    if (isFailureKgValidation(data))
      onSetFailValidate("goalWeight", getValidateKgMessage(get(data, ["goalWeight", "value"], null)))

    else if (checkIsValidKgGoalWeight(data))
      onSetSuccessValidate("goalWeight")
  }

  dataLayerAddEvent(answer, answerUnit, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'goalWeight',
      'quiz_answer': `${answer} ${answerUnit}`,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
    // fixFocus(false);
  }

  render() {
    const { data, onToNextStep, startTimeGoalWeight } = this.props

    return (
      <ButtonFill disabled={!get(data, ["goalWeight", "isValid"], false)}  onClick={
        () => {
          setTimeout(() => {
            onToNextStep(); 
            this.dataLayerAddEvent(get(data, ["goalWeight", "value"]), get(data, ["goalWeight", "type"]), startTimeGoalWeight)
          }, 100); }
      }>
        {this.props.children}
      </ButtonFill>
    )
  }
}

export default withStepsState(withLoader(GoalWeightNextStepButton))