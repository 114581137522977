import React from 'react';
import { Link } from "react-router-dom"

import FirstTitle from "./../../shared/items/static/firstTitle"
import Paragraph from "./../../shared/items/static/paragraph"
import Icon from "./../../shared/items/static/icon"
import HeaderAppSteps from "../steps/stepsWrapper/headerAppSteps"

import styles from './contacts.module.scss'

const Contacts = () => {
  return (
    <>
    <HeaderAppSteps stepSex={true} />

    <div className={styles.contacts}>
      <div className={styles.container_firstTitle}>
        <FirstTitle>Contact us</FirstTitle>
      </div>

      <div className={styles.contactsData}>
        <div className={styles.contactItem}>
          <div className={styles.container_icon}>
            <div className={styles.icon}>
              <Icon name="feedback" />
            </div>
          </div>
          <div className={styles.container_content}>
            <span className={styles.title}>Feedback</span>
            <div className={styles.container_contentWrapper}>
              <Paragraph>Please share your feedback and suggestions at <a href="mailto:support@verv.com">support@verv.com</a> - they help us create better experience for you.</Paragraph>
            </div>
          </div>
        </div>

        <div className={styles.contactItem}>
          <div className={styles.container_icon}>
            <div className={styles.icon}>
              <Icon name="partnership" />
            </div>
          </div>
          <div className={styles.container_content}>
            <span className={styles.title}>Partnership</span>
            <div className={styles.container_contentWrapper}>
              <Paragraph>If you're interested in partnership with <a href="https://verv.com">Verv</a>, contact us at <a href="mailto:partnership@verv.com">partnership@verv.com</a>. We'll be glad to discuss it.</Paragraph>
            </div>
          </div>
        </div>

        <div className={styles.contactItem}>
          <div className={styles.container_icon}>
            <div className={styles.icon}>
              <Icon name="career" />
            </div>
          </div>
          <div className={styles.container_content}>
            <span className={styles.title}>Career</span>
            <div className={styles.container_contentWrapper}>
              <Paragraph>Interested in working with us? Drop us your CV at <a href="mailto:hr@verv.com">hr@verv.com</a> - we're happy to welcome new Ververs in our team.</Paragraph>
            </div>
          </div>
        </div>

        <div className={styles.contactItem}>
          <div className={styles.container_icon}>
            <div className={styles.icon}>
              <Icon name="press" />
            </div>
          </div>
          <div className={styles.container_content}>
            <span className={styles.title}>Press</span>
            <div className={styles.container_contentWrapper}>
              <Paragraph>For all media and press related inquiries, email us at <a href="mailto:press@verv.com">press@verv.com</a>.</Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Contacts;