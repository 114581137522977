import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  openMenu as onOpenMenu,
  closeMenu as onCloseMenu,
} from '../../actions/appStateActions'

const mapStateToProps = (state) => ({
  isOpenMenu: get(state, ["appState", "isOpenMenu"], false),
});

const mapDispatchToProps = {
  onOpenMenu,
  onCloseMenu,
}

export const withMenuState = Component => connect(mapStateToProps, mapDispatchToProps)(Component)