import { isNull, get } from "lodash"

import sleep1 from "./img/sleep1.png"
import sleep2 from "./img/sleep2.png"
import sleep3 from "./img/sleep3.png"

export const checkIsValidSleep = data => {
    return validateSleepStep(get(data, ["sleep", "value"], "")) && !data.sleep.isValid
}

export const validateSleepStep = value => {
    return !isNull(value)
}

export const sleepData = {
    food: {
        value:"I sleep less than 7 hours",
        title: "I sleep less than 7 hours",
        icon: sleep1,
    },
    veget: {
        value:"I sleep 7-9 hours",
        title: "I sleep 7-9 hours",
        icon: sleep2,
    },
    vegan: {
        value:"I sleep more than 9 hours",
        title: "I sleep more than 9 hours",
        icon: sleep3,
    }
}

export const defaultFasting = false