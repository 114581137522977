import { isNull, get } from "lodash"

export const checkIsValidRelateToLevel = data => {
    return validateRelateToLevelStep(get(data, ["relateToLevel", "value"], "")) && !data.relateToLevel.isValid
}

export const validateRelateToLevelStep = value => {
    return !isNull(value)
}

export const relateToLevelData = {
    one: {
        value:"1",
        title: "1",
    },
    two: {
        value:"2",
        title: "2",
    },
    three: {
        value:"3",
        title: "3",
    },
    four: {
        value:"4",
        title: "4",
    },
    five: {
        value:"5",
        title: "5",
    },
}

export const defaultFasting = false