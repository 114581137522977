import React from 'react'

import styles from "./radioButton.module.scss"

const RadioButton = props => {
  const { isChecked, onClick } = props

  return (
    <button
      className={isChecked ? styles.radioButtonChecked : styles.radioButton}
      onClick={onClick}
    >
      {props.children}
    </button>
  )
}

export default React.memo(RadioButton)