import React from 'react';

import MenuItem from "./../../shared/items/controls/menuItem"

import { withMenuState } from "./../../shared/containers/containerMenuState"

import styles from "./menu.module.scss"

const Menu = props => {
  const { isOpenMenu, onCloseMenu } = props

  return (
    <div className={styles.wrapp}>
      <div className={isOpenMenu ? styles.menuOpen : styles.menu}>
        <div className={styles.container_menuItems}>
          <MenuItem to="/" title="Home" />
          <MenuItem to="/contacts" title="Contact Us" />
          <MenuItem to="/faq" title="FAQ" />

          <a className={styles.menuItemDirect} href="https://verv.com/privacy-policy-web-nov-2020/" target="_blank" onClick={onCloseMenu}>Privacy policy</a>
          <a className={styles.menuItemDirect} href="https://verv.com/terms-conditions/" target="_blank" onClick={onCloseMenu}>Terms & Conditions</a>

          {/* <MenuItem to="/terms-and-policies" title="Terms & policies" /> */}
          {/* <MenuItem to="/styleGuides" title="StyleGuides" /> */}
        </div>
      </div>

      {
        isOpenMenu
          ? <div className={styles.milkMenuBlock} onClick={onCloseMenu}></div>
          : null
      }
    </div>
  )
}

export default withMenuState(Menu);