import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { goalsPlanProgressData } from "./static.goalsPlanProgressData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./goalsPlanProgress.module.scss";
import illust1 from "./img/illust1.png";
import illust2 from "./img/illust2.png";
import illust3 from "./img/illust3.png";
import bg from "./img/bg.svg";

class GoalsPlanProgress extends Component {
  constructor() {
    super();
    this.meaning = 0.0;
    this.state = {
      isOpenGoalsPlanProgress: false,
      progress: 0,
      intervalStarted: false,
      scale: {transform: `scaleX(${this.meaning})`}
    };
    this.stepContentWrapper1 = styles.stepContentWrapper1;
    this.stepContentWrapper2 = styles.stepContentWrapper;
    this.stepContentWrapper3 = styles.stepContentWrapper;
    this.interval = null;
    this.bg = styles.bg;
    this.indexGoalsPlanProgress = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.goalsPlanProgress) &&
      !this.state.isOpenGoalsPlanProgress
    )
      this.setState({ isOpenGoalsPlanProgress: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData, onToNextStep } = this.props;
    const { isOpenGoalsPlanProgress } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.goalsPlanProgress,
      isOpen: isOpenGoalsPlanProgress,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenGoalsPlanProgress && this.indexGoalsPlanProgress === 0) {
      this.indexGoalsPlanProgress = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenGoalsPlanProgress) {
      this.indexGoalsPlanProgress = 0;
    }

    if (isOpenGoalsPlanProgress && !has(data, ["goalsPlanProgress"])) onSetStepData("goalsPlanProgress");

    updateOpenStepState({ params });

    let progressTime = 9; // seconds

    if (isOpenGoalsPlanProgress) {
      let startTime = new Date();
      if (this.state.intervalStarted) return;
      this.interval = setInterval(() => {
        this.meaning = this.meaning + 0.01;
        this.setState({
          progress: ++this.state.progress,
          scale:  {transform: `scaleX(${this.meaning})`}
        });
        if (this.state.progress >= 100) {
          clearInterval(this.interval);
          this.meaning = 0;
          onToNextStep();
          this.dataLayerAddEvent(startTime)
          setTimeout(() => {
            this.setState({
              progress: 0,
              intervalStarted: false,
            });
          }, 1000);
        }
      }, progressTime * 10);
      this.setState({ intervalStarted: true });
    }

  }

  handleChangeOpen(state) {
    this.setState({
      isOpenGoalsPlanProgress: state,
    });
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'gettingThingsReady',
      'quiz_answer': 'continueAuto',
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "gettingThingsReady",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenGoalsPlanProgress } = this.state;
    const params = {
      className: "wrapperGoalsPlanProgress",
      currentIndex: currentIndex,
      stepIndex: steps.goalsPlanProgress,
    };
    return isOpenGoalsPlanProgress ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.container}>
          <img className={ this.bg} src={bg}/>
          <div className={this.stepContentWrapper1} onAnimationEnd={()=> {
            this.stepContentWrapper2 = styles.stepContentWrapper2;
            this.bg = styles.bgPosition2;
          }}>
            <h1 className={styles.goalsPlanProgress_title}>
              We will help you achieve your goal easily and confidently
            </h1>
            <img className={styles.illust1} src={illust1} alt="illustration" />
            <p className={styles.goalsPlanProgress_text}>Just a moment...<br />Getting things ready for you</p>
          </div>
          <div className={this.stepContentWrapper2} onAnimationEnd={()=> {
            this.stepContentWrapper3 = styles.stepContentWrapper3
            this.bg = styles.bgPosition3
          }}>
            <h1 className={styles.goalsPlanProgress_title}>
              We create a personalized training plan that perfectly suits your lifestyle
            </h1>
            <img className={styles.illust2} src={illust2} alt="illustration" />
            <p className={styles.goalsPlanProgress_text}>Just a moment...<br />Getting things ready for you</p>
          </div>
          <div className={this.stepContentWrapper3}>
            <h1 className={styles.goalsPlanProgress_title}>
              We prepare detailed statistics to track your progress
            </h1>
            <img className={styles.illust3} src={illust3} alt="illustration" />
            <p className={styles.goalsPlanProgress_text}>Just a moment...<br />Getting things ready for you</p>
          </div>
        </div>
        <div className={styles.goalsPlanProgress_button_wrapper}>
          <div className={styles.goalsPlanProgress_button}>
            <p>Progress {this.state.progress}%</p>
            <div style={this.state.scale} className={styles.goalsPlanProgress_button_overlay}></div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withStepsState(GoalsPlanProgress);
