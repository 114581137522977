import { isNull, get } from "lodash";

import ls1 from "./img/ls1.png";
import ls2 from "./img/ls2.png";

export const checkIsValidLifestyle = data => {
    return validateLifestyleStep(get(data, ["lifestyle", "value"], "")) && !data.lifestyle.isValid
}

export const validateLifestyleStep = value => {
    return !isNull(value)
}

export const lifestyleData = {
    short: {
        value:"My activity level is not good and needs improvement",
        title: "My activity level is not good and needs improvement",
        icon: ls1,
    },
    high: {
        value:"I try to practice healthy lifestyle habits",
        title: "I try to practice healthy lifestyle habits",
        icon: ls2,
    },
}

export const defaultFasting = false