import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { relateToLevelData } from "./static.relateToLevelData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import "./relateToLevel.scss";

class RelateToLevel extends Component {
  constructor() {
    super();

    this.state = {
      isOpenRelateToLevel: false,
    };
    this.indexRelateToLevel = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.relateToLevel) &&
      !this.state.isOpenRelateToLevel
    )
      this.setState({ isOpenRelateToLevel: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenRelateToLevel } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.relateToLevel,
      isOpen: isOpenRelateToLevel,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenRelateToLevel && this.indexRelateToLevel === 0) {
      this.indexRelateToLevel = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenRelateToLevel) {
      this.indexRelateToLevel = 0;
    }

    if (isOpenRelateToLevel && !has(data, ["relateToLevel"])) onSetStepData("relateToLevel");

    updateOpenStepState({ params });
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenRelateToLevel: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'statementReduceStress',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "statementReduceStress",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenRelateToLevel } = this.state;
    const params = {
      className: "wrapperRelateToLevel",
      currentIndex: currentIndex,
      stepIndex: steps.relateToLevel,
    };
    let startTime = new Date();

    return isOpenRelateToLevel ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>
            Do you relate to the following statement?
          </FirstTitle>
          <div className="relateToContentLevel">
            <p>“Running is a good way to <span>reduce stress level</span>”</p>
          </div>
          <div className="relateToButtonWrapperLevel">
            {Object.keys(relateToLevelData).map((key, index) => {
              return (
                <RadioCheckButton
                  key={index}
                  isChecked={
                    get(data, ["relateToLevel", "value"], null) ===
                    relateToLevelData[key].value
                  }
                  onClick={
                    get(data, ["relateToLevel", "value"], null) !==
                      relateToLevelData[key].value
                      ? onUpdateStepData.bind(
                        this,
                        "relateToLevel",
                        "value",
                        relateToLevelData[key].value
                      )
                      : () => { }
                  }
                  identify={`relateToLevel${index}`}
                  onClickNextStep={onToNextStep !== false ? () => {
                    setTimeout(() => {
                      onToNextStep();
                      this.dataLayerAddEvent(get(relateToLevelData, [key, "title"]), startTime
                      )
                    }, 400);
                  } : () => { }
                  }
                >
                  {relateToLevelData[key].title}
                </RadioCheckButton>

              );
            })}
            <p>Not at all</p>
            <p>Totally</p>
          </div>
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(RelateToLevel);
