import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import InputNumberWrapper from "../../../shared/items/controls/inputNumberWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import RadioСhoice from "../../../shared/items/controls/radioСhoice";
import RadioСhoiceWrapper from "../../../shared/radioСhoiceWrapper";
import CurrentWeightNextStepButton from "./currentWeightNextStepButton";
import ValidateMessage from "../../../shared/items/static/validateMessage";
import StepContentWrapper from "./../stepContentWrapper";
import StepControl from "./../stepControl";
import { steps } from "../../../shared/static.steps";
import { currentWeightTypeData } from "./static.currentWeightData";
import { heightTypeData } from "./../height/static.heightData";
import "./../../../shared/services/service.step.scss";
import fixFocus from "./../../../utils/fixFocus"
import styles from "./currentWeight.module.scss";

class CurrentWeight extends Component {
  constructor() {
    super();

    this.state = {
      isOpenCurrentWeight: false,
      isWeightType: 'lbs',
      index: 1,
    };
    this.indexCurrentWeight = 0;
    this.startTimeCurrentWeight = {};
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.currentWeight) &&
      !this.state.isOpenCurrentWeight
    )
      this.setState({ isOpenCurrentWeight: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenCurrentWeight } = this.state;
    if(isOpenCurrentWeight && this.indexCurrentWeight === 0) {
      this.indexCurrentWeight = 1;
      this.startTimeCurrentWeight = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.currentWeight,
      isOpen: isOpenCurrentWeight,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenCurrentWeight && !has(data, ["currentWeight"])) {
      onSetStepData("currentWeight");

      fixFocus("currentWeight");
      // document.getElementById("currentWeight").focus();

    }

    if(isOpenCurrentWeight) { 
      setTimeout(()=> {
        if(data.height.type === 'cm' && this.state.index === 1) {
          this.setState({isWeightType: 'kg'})
          this.setState({index: 2})
        }
        
      })
      
    }

    updateOpenStepState({ params });
    if(!isOpenCurrentWeight) {
      this.indexCurrentWeight = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenCurrentWeight: state,
    });
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "currentWeight",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData } = this.props;
    const { isOpenCurrentWeight } = this.state;
    const params = {
      className: "wrapperCurrentWeight",
      currentIndex: currentIndex,
      stepIndex: steps.currentWeight,
    };
    const defaultType = !has(data, ["currentWeight", "type"])
      ? get(data, ["height", "type"]) === heightTypeData.cm.value
        ? currentWeightTypeData.kg.value
        : currentWeightTypeData.lbs.value
      : currentWeightTypeData.lbs.value;

    return isOpenCurrentWeight ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>Your current weight</FirstTitle>

          <RadioСhoiceWrapper
            name="type"
            valueStore={get(data, ["currentWeight"], null)}
            defaultValue={defaultType}
            handleSetDefault={onUpdateStepData.bind(this, "currentWeight")}
          >
            {Object.keys(currentWeightTypeData).map((key, index) => {
              return (
                <RadioСhoice
                  key={index}
                  isChecked={
                    currentWeightTypeData[key].value ===
                    get(data, ["currentWeight", "type"], null)
                  }
                  onClick={()=> {
                    onUpdateStepData("currentWeight", "type",  currentWeightTypeData[key].value)
                    this.setState({isWeightType: currentWeightTypeData[key].value})
                  }}
                >
                  {currentWeightTypeData[key].title}
                </RadioСhoice>
              );
            })}
          </RadioСhoiceWrapper>

          {this.state.isWeightType === 'lbs' ?
            (<div className={styles.container_inputLBS}>
              <InputNumberWrapper
                name="currentWeight"
                keyName="value"
                placehcurrentWeighter="Your currentWeight?"
                onChange={onUpdateStepData.bind(this)}
                valueStore={get(data, ["currentWeight", "value"], null)}
              />
            </div>) : 
            <div className={styles.container_inputKG}>
              <InputNumberWrapper
                name="currentWeight"
                keyName="value"
                placehcurrentWeighter="Your currentWeight?"
                onChange={onUpdateStepData.bind(this)}
                valueStore={get(data, ["currentWeight", "value"], null)}
              />
            </div>
          }
          

          <div id="messageIdcurrentWeight" className={styles.container_validateMessage}>
            <ValidateMessage
              message={get(data, ["currentWeight", "validString"], null)}
            />
          </div>
        </StepContentWrapper>

        <StepControl>
          <CurrentWeightNextStepButton
          startTimeCurrentWeight={this.startTimeCurrentWeight}
          >Done</CurrentWeightNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(CurrentWeight);
