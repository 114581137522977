import { combineReducers } from 'redux'

// import { reducer as notifReducer } from 'redux-notifications'

import stepsStateReducer from './stepsStateReducer'
import appStateReducer from './appStateReducer'

const reducers = combineReducers({
  // notif: notifReducer,

  appState: appStateReducer,
  stepsState: stepsStateReducer,
})

export default reducers