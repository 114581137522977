import React, { Component } from "react";
import { get, isEmpty } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import SwitcherWrapper from "../../../shared/items/controls/switcherWrapper";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { sleepData } from "./static.sleepData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./sleep.module.scss";

class Sleep extends Component {
  constructor() {
    super();

    this.state = {
      isOpenSleep: false,
    };
    this.indexSleep = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.sleep) &&
      !this.state.isOpenSleep
    )
      this.setState({ isOpenSleep: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenSleep } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.sleep,
      isOpen: isOpenSleep,
      handleChange: this.handleChangeOpen.bind(this),
    };
    if (isOpenSleep && isEmpty(data.sleep)) onSetStepData("sleep");

    updateOpenStepState({ params });

    if(isOpenSleep && this.indexSleep === 0) {
      this.indexSleep = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      }) 
    }
    if(!isOpenSleep) {
      this.indexSleep = 0;
    }
  
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenSleep: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'sleepPatterns',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "sleepPatterns",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenSleep } = this.state;
    const params = {
      className: "wrapperSleep",
      currentIndex: currentIndex,
      stepIndex: steps.sleep,
    };
    let startTime = new Date();

    return isOpenSleep ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>Please, describe your sleep duration</FirstTitle>

          {Object.keys(sleepData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["sleep", "value"], null) ===
                  sleepData[key].value
                }
                onClick={
                  get(data, ["sleep", "value"], null) !==
                  sleepData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "sleep",
                        "value",
                        sleepData[key].value
                      )
                    : () => {}
                }
                icon={sleepData[key].icon}
                identify={`sleep${index}`}
                onClickNextStep={onToNextStep !== false ? () => {
                  setTimeout(() => {
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(sleepData, [key, "title"]), startTime
                    )}, 400); } : () => {}
                }
              >
                {sleepData[key].title}
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(Sleep);
