import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { successStoryResultData } from "./static.successStoryResultData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import logo from "./img/logo.png";
import styles from "./successStoryResult.module.scss";
import StepControl from "../stepControl";
import SuccessStoryResultNextStepButton from "./successStoryResultNextStepButton";
import "./successStoryResult.scss";

class SuccessStoryResult extends Component {
  constructor() {
    super();

    this.state = {
      isOpenSuccessStoryResult: false,
    };
    this.indexSuccessStoryResult = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.successStoryResult) &&
      !this.state.isOpenSuccessStoryResult
    )
      this.setState({ isOpenSuccessStoryResult: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenSuccessStoryResult } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.successStoryResult,
      isOpen: isOpenSuccessStoryResult,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenSuccessStoryResult && this.indexSuccessStoryResult === 0) {
      this.indexSuccessStoryResult = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenSuccessStoryResult) {
      this.indexSuccessStoryResult = 0;
    }

    if (isOpenSuccessStoryResult && !has(data, ["successStoryResult"])) onSetStepData("successStoryResult");

    updateOpenStepState({ params });
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenSuccessStoryResult: state,
    });
  }

  dataLayerAddEvent(answer) {
    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'successStoryResult',
      'quiz_answer': answer
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "disclaimer",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenSuccessStoryResult } = this.state;
    const params = {
      className: "wrapperSuccessStoryResult",
      currentIndex: currentIndex,
      stepIndex: steps.successStoryResult,
    };

    return isOpenSuccessStoryResult ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <img className={styles.logo} src={logo} alt="logo"/>
          <h1 className={styles.title}>
            You should know
          </h1>
          <div className={styles.wrapper}>
            <p>Running is an incredibly effective way to improve your health and well-being - but it’s important to do it correctly. That’s why we are here!</p>
            <p>We will support you every step of the way and help you get the body you’ve envisioned for yourself. We will provide you personalized plan and daily tips to make sure you reach your goals.</p>
            <p>Change your lifestyle with our Weight Loss Running app!</p>
          </div>
        </div>
        <StepControl>
          <SuccessStoryResultNextStepButton>Next</SuccessStoryResultNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(SuccessStoryResult);
