import React, { Component } from 'react';
import { get } from "lodash";

import { withStepsState } from "../../../shared/containers/containerStepsState";
import { withLoader } from "../../../shared/containers/containerLoader";

import { checkIsValidWorkouts } from "./static.workoutsData";
import ButtonFill from "../../../shared/items/controls/buttonFill";
import styles from "./workouts.module.scss";
import blockContent from "./../../../utils/blockContent"

class WorkoutsNextStepButton extends Component {
  componentDidUpdate() {
    const { data, onSetSuccessValidate } = this.props

    if (checkIsValidWorkouts(data))
      onSetSuccessValidate("workouts")
  }

  render() {
    const { onToNextStep, dataLayerAddEvent } = this.props;
    let startTime = new Date();

    return (
      <div className={styles.workoutsButtonWrapper}>
        <ButtonFill 
          onClick={onToNextStep !== false ? () => {
            blockContent();
            setTimeout(() => {
              onToNextStep();
              dataLayerAddEvent(startTime);
              }, 400); } : () => {}
          }
        >
          {this.props.children}
        </ButtonFill>
      </div>
    )
  }
}

export default withStepsState(withLoader(WorkoutsNextStepButton))