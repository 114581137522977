import React, { Component } from 'react'
import { get, has } from "lodash"
import { Switch } from 'antd';

import 'antd/dist/antd.css';
import styles from './switcherWrapper.module.scss'

class SwitcherWrapper extends Component {
  handleChange() {
    const { name, onChange, checked } = this.props

    if (has(this.props, ["onChange"]))
      onChange(name, !checked)
  }

  render() {
    const { disabled, label } = this.props

    return (
      <div className={label ? styles.switcherLabel : styles.switcher}>
        <span className={styles.label}>
          {label}
        </span>

        <div className={styles.container_switcher}>
          <Switch
            disabled={disabled ? disabled : false}
            checked={get(this.props, ["checked"], false)}
            onChange={this.handleChange.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default React.memo(SwitcherWrapper)