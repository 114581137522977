import { isNull, get } from "lodash"

import wt1 from "./img/wt1.png"
import wt2 from "./img/wt2.png"

export const checkIsValidImportantEventDate = data => {
    return validateImportantEventDateStep(get(data, ["importantEventDate", "value"], "")) && !data.importantEventDate.isValid
}

export const validateImportantEventDateStep = value => {
    if(value === '' || isNull(value)) {
        return false
    } else {
       return true
    }
}

export const importantEventDateData = {
    minimum: {
        value:"value 1",
        title: "title 1",
        icon: wt1,
    },
    normal: {
        value:"value 2",
        title: "title 2",
        icon: wt2,
    },
}

export const defaultFasting = false