import { get } from 'lodash';
import { connect } from 'react-redux'

import {
  openLoader as onOpenLoader,
} from '../../actions/stepsStateActions'

const mapStateToProps = (state) => ({
  openLoaderResult: get(state, ["stepsState", "openLoaderResult"], false),
});

const mapDispatchToProps = {
  onOpenLoader,
}

export const withLoader = Component => connect(mapStateToProps, mapDispatchToProps)(Component)