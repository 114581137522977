import React from "react";
import { get, has } from "lodash";
import { withStepsState } from "./containers/containerStepsState";
import { withAppStepsState } from "./containers/containerAppStepsState";
import styles from "./toPrevStepButton.module.scss";
import back from "./img/back.svg";

const ToPrevStepButton = (props) => {
  const { onToPrevStep, onCloseApp, currentIndex, data } = props;
  let stepsWithInput;

  function checkstepsWithInput() {

    if (
      data[Object.keys(data)[currentIndex]] &&
      Object.keys(data)[currentIndex] === "visibleResultProgress" &&
      (!has(data, ["old"]))
    ) {
      document.querySelector(".toPrevStepButton").classList.add("disabledButtonStyle");
      setTimeout(() => {
        document.querySelector(".toPrevStepButton").classList.remove("disabledButtonStyle")
      }, 5600);
      // stepsWithInput = true;
      // return stepsWithInput;
    }

    else if (
      data[Object.keys(data)[currentIndex]] &&
      Object.keys(data)[currentIndex] === "achievedProgress" &&
      (!has(data, ["ailments"]))
    ) {
      document.querySelector(".toPrevStepButton").classList.add("disabledButtonStyle");
      setTimeout(() => {
        document.querySelector(".toPrevStepButton").classList.remove("disabledButtonStyle")
      }, 5600);

      // stepsWithInput = true;
      // return stepsWithInput;
    }

    // return stepsWithInput;
  }

  let disabledButtonStyle;

  return (
    <>

      {!checkstepsWithInput() && (

        <button
          className={`${styles.toPrevStepButton} toPrevStepButton`}
          onClick={currentIndex === 0 ? onCloseApp : onToPrevStep}
        >
          <img src={back} />
        </button>
      )}

    </>
  );
};

export default withStepsState(withAppStepsState(ToPrevStepButton));
