import { isNull, get } from "lodash"

const BOTTOM_LINE_FT = 3
const TOP_LINE_FT = 9.11
const BELOW_BOTTOM_LINE_FT_MESSAGE = "Please double-check and enter valid height"
const ABOVE_TOP_LINE_FT_MESSAGE = "Please double-check and enter valid height"


const BOTTOM_LINE_IN = 0
const TOP_LINE_IN = 12
const BELOW_BOTTOM_LINE_IN_MESSAGE = "Please double-check and enter valid height"
const ABOVE_TOP_LINE_IN_MESSAGE = "Please double-check and enter valid height"


const BOTTOM_LINE_CM = 100
const TOP_LINE_CM = 299
const BELOW_BOTTOM_LINE_CM_MESSAGE = "Please double-check and enter valid height"
const ABOVE_TOP_LINE_CM_MESSAGE = "Please double-check and enter valid height"

export const checkIsValidFtHeight = data => {
    return validateHeightFtStep(get(data, ["height", "value"], ""), get(data, ["height", "value2"], "")) && !data.height.isValid
}

export const checkIsValidCmHeight = data => {
    return validateHeightCmStep(get(data, ["height", "value"], "")) && !data.height.isValid
}

export const checkIsChoosedHeightType = (data, type) => {
    return get(data, ["height", "type"], null) === type
}

export const isFailureFtValidation = data => {
    return !validateHeightFtStep(get(data, ["height", "value"], ""), get(data, ["height", "value2"], ""))
        && get(data, ["height", "validString"], "") !== getValidateFtMessage(get(data, ["height", "value"], ""), get(data, ["height", "value2"], ""))
}

export const isFailureCmValidation = data => {
    return !validateHeightCmStep(get(data, ["height", "value"], ""))
        && get(data, ["height", "validString"], "") !== getValidateCmMessage(get(data, ["height", "value"], ""))
}

export const validateHeightCmStep = value => {
    return !isNull(value)
        ? value >= BOTTOM_LINE_CM && value <= TOP_LINE_CM
        : false
}

export const getValidateCmMessage = value => {
    let message = ""

    if (!isNull(value))
        if (value <= BOTTOM_LINE_CM)
            message = BELOW_BOTTOM_LINE_CM_MESSAGE
        else if (value >= TOP_LINE_CM)
            message = ABOVE_TOP_LINE_CM_MESSAGE

    return message
}

export const validateHeightFtStep = (value, value2) => {
    return (!isNull(value) && !isNull(value2))
        ? value >= BOTTOM_LINE_FT && value <= TOP_LINE_FT && value2 >= BOTTOM_LINE_IN && value2 < TOP_LINE_IN
        : false
}

export const getValidateFtMessage = (value, value2) => {
    let message = ""

    if (!isNull(value) && isNull(value2)) {
        if (value <= BOTTOM_LINE_FT) {
            message = BELOW_BOTTOM_LINE_FT_MESSAGE
        }

        else if (value >= TOP_LINE_FT) {
            message = ABOVE_TOP_LINE_FT_MESSAGE
        }

    } else if (!isNull(value2) && !isNull(value)) {
        if (value <= BOTTOM_LINE_FT || value2 < BOTTOM_LINE_IN) {
            message = BELOW_BOTTOM_LINE_FT_MESSAGE
        }

        else if (value >= TOP_LINE_FT || value2 >= TOP_LINE_IN) {
            message = ABOVE_TOP_LINE_FT_MESSAGE
        }

    } else if (isNull(value) && !isNull(value2)) {
        if (value2 < BOTTOM_LINE_IN) {
            message = BELOW_BOTTOM_LINE_IN_MESSAGE
        }

        else if (value2 >= TOP_LINE_IN) {
            message = ABOVE_TOP_LINE_IN_MESSAGE
        }

    }


    return message
}

export const heightTypeData = {
    ft: {
        value: "ft",
        title: "FT",
    },
    cm: {
        value: "cm",
        title: "CM",
    },
}