import { connect } from 'react-redux'

import {
  clearData as onClearData,
} from '../../actions/stepsStateActions'

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  onClearData,
}

export const withClearStepsData = Component => connect(mapStateToProps, mapDispatchToProps)(Component)