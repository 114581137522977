import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import { withLoader } from "../../../shared/containers/containerLoader";
import FirstTitle from "../../../shared/items/static/firstTitle";
import InputWrapperMail from "../../../shared/items/controls/inputWrapperMail";
import MailNextStepButton from "./mailNextStepButton";
import ValidateMessage from "../../../shared/items/static/validateMessage";
import StepControl from "../stepControl";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { steps } from "../../../shared/static.steps";
import fixFocus from "./../../../utils/fixFocus"
import "./../../../shared/services/service.step.scss";
import styles from "./mail.module.scss";
import lockImage from "./img/lock.svg";
import logo from "./img/logo.svg";

class Mail extends Component {
  constructor() {
    super();

    this.state = {
      isOpenMail: false,
    };
    this.indexMail = 0;
    this.startTimeMail = {};
    this.openKeyboard = false;
    this.initHeight = window.visualViewport.height;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.mail) &&
      !this.state.isOpenMail
    )
      this.setState({ isOpenMail: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenMail } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.mail,
      isOpen: isOpenMail,
      handleChange: this.handleChangeOpen.bind(this),
    };
    if(isOpenMail && this.indexMail === 0) {
      this.indexMail = 1;
      this.startTimeMail = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }

    // if (isOpenMail && !has(data, ["mail"])) {
    //   onSetStepData("mail");
    //   document.activeElement.blur();
    // }

    if (isOpenMail && !has(data, ["mail"])) {
      onSetStepData("mail");

      // fixFocus("mail");
      // document.getElementById("mail").focus();

      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   'event': 'quiz_funnel',
      //   'quiz_activity': 'run_result_loader'
      // });
      // console.log('run_result_loader');
     // console.log(window.dataLayer);
    }


    updateOpenStepState({ params });
    if(!isOpenMail) {
      this.indexMail = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenMail: state,
    });
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "send_email",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenMail } = this.state;
    const params = {
      className: "wrapperMail",
      currentIndex: currentIndex,
      stepIndex: steps.mail,
    };
    
    return isOpenMail ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapperMail}>
        <img src={logo} alt="lock" className={styles.logo} />
          <FirstTitle>
          Your <span className={styles.mailTitleActivity}>
              {data.result.value}
            </span> plan is ready!
          </FirstTitle>

          <p className={styles.subtitle}>Enter your email to get started</p>

          <InputWrapperMail
            name="mail"
            keyName="value"
            placeholder="Enter your email"
            onChange={onUpdateStepData.bind(this)}
            valueStore={get(data, ["mail", "value"], null)}
            // label={"Email"}
            // focus={true}
          />

          <div className={styles.warning}>
            <img src={lockImage} alt="lock" className={styles.image} />
            <p className={styles.emailDescription}>
              Your information is 100% secure. We don’t share your personal information.
            </p>
          </div>

          <div id="messageIdmail" className={styles.container_validateMessage}>
            <ValidateMessage
              message={get(data, ["mail", "validString"], null)}
            />
          </div>
        </div>

        <StepControl>
          <MailNextStepButton
          startTimeMail={this.startTimeMail}
          >Done</MailNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(withLoader(Mail));
