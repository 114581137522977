import React from 'react';

import ButtonFill from "../shared/items/controls/buttonFill"
import InputWrapper from "./../shared/items/controls/inputWrapper"
import MenuButton from "./../shared/items/controls/menuButton"
import ToPrevStepButton from "../shared/toPrevStepButton"
import RadioButton from "../shared/items/controls/radioButton"
import RadioCheckButton from "../shared/items/controls/radioCheckButton"
import RadioСhoice from "../shared/items/controls/radioСhoice"
import RadioСhoiceWrapper from "../shared/radioСhoiceWrapper"
import RadioImageButton from "../shared/items/controls/radioImageButton"
import SwitcherWrapper from "../shared/items/controls/switcherWrapper"

import FirstTitle from "./../shared/items/static/firstTitle"
import Paragraph from "./../shared/items/static/paragraph"

const StyleGuides = () => {
  return (
    <div style={{ maxWidth: "1030px", margin: "0 auto", padding: "30px 30px" }}>
      <div style={{ marginTop: "48px" }}>
        <FirstTitle>48px/56px, Ut enim ad minim veniam, quis nostrud exercitation ullamco poriti laboris nisi ut aliquip.</FirstTitle>
      </div>
      <div style={{ marginTop: "48px" }}>
        <Paragraph>20px/30px, Ut enim ad minim veniam, quis nostrud exercitation ullamco poriti laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in uienply voluptate velit esse cillum dolore eu fugiat nulla pariatur.</Paragraph>
      </div>
      <div style={{ marginTop: "48px" }}>
        <ButtonFill>Continue</ButtonFill>
      </div>
      <div style={{ marginTop: "48px", maxWidth: 335 }}>
        <InputWrapper id="secretKey" />
      </div>
      <div style={{ marginTop: "48px", maxWidth: 335 }}>
        <InputWrapper id="secretKey1" placeholder="What’s your name?" disabled />
      </div>
      <div style={{ marginTop: "48px", maxWidth: 335 }}>
        <InputWrapper id="secretKey2" placeholder="What’s your name?" error helperText="The field is incorrect" />
      </div>
      <div style={{ marginTop: "48px" }}>
        <MenuButton />
      </div>
      <div style={{ marginTop: "48px" }}>
        <MenuButton isOpen />
      </div>
      <div style={{ marginTop: "48px" }}>
        <ToPrevStepButton />
      </div>
      <div style={{ marginTop: "48px" }}>
        <RadioButton >
          Female
          </RadioButton>
      </div>
      <div style={{ marginTop: "48px" }}>
        <RadioButton isChecked>
          Other
        </RadioButton>
      </div>
      <div style={{ marginTop: "48px", maxWidth: "335px" }}>
        <RadioСhoiceWrapper>
          <RadioСhoice>LBS</RadioСhoice>
          <RadioСhoice isChecked>Kg</RadioСhoice>
        </RadioСhoiceWrapper>
      </div>
      <div style={{ marginTop: "48px" }}>
        <RadioCheckButton>
          More than 20 min
        </RadioCheckButton>
      </div>
      <div style={{ marginTop: "48px" }}>
        <RadioCheckButton isChecked>
          Let us decide
        </RadioCheckButton>
      </div>
      <div style={{ marginTop: "48px" }}>
        <RadioImageButton
          image={"./img/zone_item.png"}
        >
          Arms & Back
          </RadioImageButton>
      </div>
      <div style={{ marginTop: "48px" }}>
        <RadioImageButton
          image={"./img/zone_item.png"}
          isChecked
        >
          Abs & Core
          </RadioImageButton>
      </div>
      <div style={{ marginTop: "48px" }}>
        <SwitcherWrapper
          label="Intermittent fasting"
          onChange={() => { }}
        />
      </div>
    </div>
  )
}

export default StyleGuides;

