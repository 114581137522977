import { isNull, get } from "lodash"
import goal1 from "./img/goal1.png"
import goal2 from "./img/goal2.png"
import goal3 from "./img/goal3.png"

export const checkIsValidFitnesLevel = data => {
    return validateFitnesLevelStep(get(data, ["fitnesLevel", "value"], "")) && !data.fitnesLevel.isValid
}

export const validateFitnesLevelStep = value => {
    return !isNull(value)
}

export const fitnesLevelData = {
    beginner: {
        value:"beginner",
        title: "Beginner",
        description: "You’re absolutely new to running",
        icon: goal1,
    },
    intermediate: {
        value:"intermediate",
        title: "Intermediate",
        description: "You run regularly, can run 1-2 miles per session",
        icon: goal2,
    },
    advanced: {
        value:"advanced",
        title: "Advanced",
        description: "You can run 3 or more miles per session",
        icon: goal3,
    },
}

export const defaultFasting = false