import React from "react";
import ToPrevStepButton from "./../../../shared/toPrevStepButton";
import ToNextStepButton from "./../../../shared/toNextStepButton";
import ProgressSteps from "./progressSteps";
import MenuButton from "../../../shared/items/controls/menuButton";
import Menu from "./../../blocks/menu";
import styles from "./headerAppSteps.module.scss";

import { steps } from "./../../../shared/static.steps";
import { isCurrentStep } from "./../../../shared/services/service.step";

const HeaderAppSteps = ({currentIndex, stepResult, stepEmail, successStoryResult}) => {

  const totalSteps = Object.keys(steps).length - 7;

  // manual step settings
  // switch (currentIndex) {

  //   case 1:
  //     progressLineWidth = "3%";
  //     break;
  //   case 2:
  //     progressLineWidth = "7%";
  //     break;
  //   case 3:
  //     progressLineWidth = "12%";
  //     break;
  //   case 4:
  //     progressLineWidth = "17%";
  //     break;
  //   case 5:
  //     progressLineWidth = "24%";
  //     break;
  //   case 6:
  //     progressLineWidth = "29%";
  //     break;
  //   case 7:
  //     progressLineWidth = "35%";
  //     break;
  //   case 8:
  //     progressLineWidth = "43%";
  //     break;
  //   case 9:
  //     progressLineWidth = "50%";
  //     break;
  //   case 10:
  //     progressLineWidth = "55%";
  //     break;

  //   case 11:
  //     progressLineWidth = "50%";
  //     break;
  //   case 12:
  //     progressLineWidth = "56%";
  //     break;
  //   case 13:
  //     progressLineWidth = "59%";
  //     break;
  //   case 14:
  //     progressLineWidth = "62%";
  //     break;
  //   case 15:
  //     progressLineWidth = "66%";
  //     break;
  //   case 16:
  //     progressLineWidth = "68%";
  //     break;
  //   case 17:
  //     progressLineWidth = "71%";
  //     break;
  //   case 18:
  //     progressLineWidth = "77%";
  //     break;
  //   case 19:
  //     progressLineWidth = "77%";
  //     break;
  //   case 20:
  //     progressLineWidth = "82%";
  //     break;

  //   case 21:
  //     progressLineWidth = "84%";
  //     break;
  //   case 22:
  //     progressLineWidth = "86%";
  //     break;
  //   case 23:
  //     progressLineWidth = "88%";
  //     break;
  //   case 24:
  //     progressLineWidth = "90%";
  //     break;
  //   case 25:
  //     progressLineWidth = "92%";
  //     break;
  //   case 26:
  //     progressLineWidth = "94%";
  //     break;
  //   case 27:
  //     progressLineWidth = "96%";
  //     break;
  //   case 28:
  //     progressLineWidth = "98%";
  //     break;
  //   case 29:
  //     progressLineWidth = "98%";
  //     break;
  //   case 30:
  //     progressLineWidth = "100%";
  //     break;

  //   default:
  //     progressLineWidth = "100%";
  // }

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.headerAppSteps}>
        {!stepResult && (
          <div className={styles.container_controls}>
            <ToPrevStepButton />
          </div>
        )}

          <div className={styles.container_progress}>
            {!stepEmail && !stepResult && !successStoryResult && (
              <>
                {/* <ProgressSteps /> */}
                <div className={styles.container_steps}>
                  <div className={styles.container_step}>
                  
                      <div
                        className={styles.container_step_line}
                        style={{'--step': currentIndex, '--totalSteps': totalSteps}}
                      ></div>
                    
                  </div>
                </div>
                <div className={styles.container_dots}>
                  <div
                    className={`${styles.container_dot} ${styles.container_dot_active}`}
                  ></div>

                  {currentIndex < 6 && <div className={styles.container_dot}></div>}
                  {currentIndex >= 6 && (
                    <div
                      className={`${styles.container_dot} ${styles.container_dot_active}`}
                    ></div>
                  )}

                  {currentIndex < 13 && (
                    <div className={styles.container_dot}></div>
                  )}
                  {currentIndex >= 13 && (
                    <div
                      className={`${styles.container_dot} ${styles.container_dot_active}`}
                    ></div>
                  )}

                  {currentIndex < 20 && (
                    <div className={styles.container_dot}></div>
                  )}
                  {currentIndex >= 20 && (
                    <div
                      className={`${styles.container_dot} ${styles.container_dot_active}`}
                    ></div>
                  )}

                  {currentIndex < 27 && (
                    <div className={styles.container_dot}></div>
                  )}
                  {currentIndex >= 27 && (
                    <div
                      className={`${styles.container_dot} ${styles.container_dot_active}`}
                    ></div>
                  )}
                </div>
              </>
            )}
          </div>
        
        <div className={styles.container_controls}>
          <ToNextStepButton />
        </div>

        {stepResult && (
          <div className={styles.container_controls}>
            <MenuButton />
          </div>
        )}
      </div>

      <div className={styles.container_menu}>
        <Menu />
      </div>
    </div>
  );
};

export default HeaderAppSteps;
