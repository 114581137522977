import React from 'react';
import { Link } from "react-router-dom"

import FirstTitle from "./../../shared/items/static/firstTitle"
import Paragraph from "./../../shared/items/static/paragraph"
import HeaderAppSteps from "../steps/stepsWrapper/headerAppSteps"

import styles from './faq.module.scss'

const Faq = () => {
  return (
    <>
    <HeaderAppSteps stepSex={true} />
    <div className={styles.faq}>

      <div className={styles.container_firstTitle}>
        <FirstTitle>FREQUENTLY ASKED QUESTIONS</FirstTitle>
      </div>

      <div className={styles.faqData}>
        <div className={styles.contactItem}>
          <span className={styles.title}>How do I sign up?</span>
          <div className={styles.container_contentWrapper}>
            <Paragraph>To get started, simply go to <Link to="/">this page</Link>, and answer a few quick questions about your workout and nutrition preferences, goals, and overall fitness level. We will also ask for your email: please make sure you’ve entered it correctly. Once we have the necessary information, we will generate a custom meal and workout plan designed just for you, and provide you with the app download link so that you can get started right away.</Paragraph>
          </div>
        </div>
        
        <div className={styles.contactItem}>
          <span className={styles.title}>How will I receive my customized meal and workout plan?</span>
          <div className={styles.container_contentWrapper}>
            <Paragraph>You’ll receive a unique download link that will take you to the AppStore to download the app. Once the app is installed on your phone, you need to sign into the app using your Facebook account (don’t worry, we don’t post anything on your behalf). Your custom meal and workout plan will be available for use right away. If you experience any difficulties with downloading the app or accessing your plan, please contact us at <a href="mailto:support@verv.com">support@verv.com</a>.</Paragraph>
          </div>
        </div>

        <div className={styles.contactItem}>
          <span className={styles.title}>When will I receive my customized meal and workout plan?</span>
          <div className={styles.container_contentWrapper}>
            <Paragraph>Our smart algorithms are highly precise and are able to customize your weight loss plan according to your specific needs. The unique download link will be generated right after your payment. The plan will become available as soon as you download the app using your unique link.</Paragraph>
          </div>
        </div>

        <div className={styles.contactItem}>
          <span className={styles.title}>I do not have access to my subscription any longer, what happened?</span>
          <div className={styles.container_contentWrapper}>
            <Paragraph>As you purchase the plan on the website, you are given various subscription options (1 month, 3 months or 6 months). Once your chosen subscription expires, you lose access to the app. If you wish to continue using the app after your initial subscription expired, you may repurchase it within the app at any time.</Paragraph>
          </div>
        </div>

        <div className={styles.contactItem}>
          <span className={styles.title}>Can I cancel my subscription?</span>
          <div className={styles.container_contentWrapper}>
            <Paragraph>Please note that all payments on <Link to="/">this page</Link> are one-time only, the subscription does not renew automatically, and no money is deducted from your account without your consent. If for any reason you do not wish to use the app for longer than the duration of your subscription, there’s no need to do anything else, as the subscription does not auto-renew.</Paragraph>
          </div>
        </div>

      </div>
    </div>
    </>
  )
}

export default Faq;