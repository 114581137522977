import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./achievedProgress.module.scss";
import imgAchived from "./img/imgAcchived.png";

class AchievedProgress extends Component {
  constructor() {
    super();
    this.meaning = 0.0;
    this.state = {
      isOpenAchievedProgress: false,
      progress: 0,
      intervalStarted: false,
      scale: { transform: `scaleX(${this.meaning})` },
    };
    this.interval = null;
    this.indexAchievedProgress = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.achievedProgress) &&
      !this.state.isOpenAchievedProgress
    )
      this.setState({ isOpenAchievedProgress: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData, onToNextStep } = this.props;
    const { isOpenAchievedProgress } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.achievedProgress,
      isOpen: isOpenAchievedProgress,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenAchievedProgress && this.indexAchievedProgress === 0) {
      this.indexAchievedProgress = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen();
      });
    }
    if (!isOpenAchievedProgress) {
      this.indexAchievedProgress = 0;
    }

    if (isOpenAchievedProgress && !has(data, ["achievedProgress"]))
      onSetStepData("achievedProgress");

    updateOpenStepState({ params });


    if (isOpenAchievedProgress && !has(data, ["achievedProgress"])) {
      let progressTime = 5; // seconds

      if (isOpenAchievedProgress) {
        let startTime = new Date();
        if (this.state.intervalStarted) return;
        this.interval = setInterval(() => {
          this.meaning = this.meaning + 0.01;
          this.setState({
            progress: ++this.state.progress,
            scale: { transform: `scaleX(${this.meaning})` },
          });
          if (this.state.progress >= 100) {
            clearInterval(this.interval);
            this.meaning = 0;
            onToNextStep();
            this.dataLayerAddEvent(startTime);
            setTimeout(() => {
              this.setState({
                progress: 0,
                intervalStarted: false,
              });
            }, 1000);
          }
        }, progressTime * 10);
        this.setState({ intervalStarted: true });
      }
    }

  }

  handleChangeOpen(state) {
    this.setState({
      isOpenAchievedProgress: state,
    });
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      event: "user_answers",
      quiz_question: "infoUsersAchieveGoals",
      quiz_answer: "continueAuto",
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "infoUsersAchieveGoals",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenAchievedProgress } = this.state;
    const params = {
      className: "wrapperAchievedProgress",
      currentIndex: currentIndex,
      stepIndex: steps.achievedProgress,
    };

    // if ( ((Object.keys(data)[currentIndex]) === "achievedProgress")  &&  (!has(data, ["ailments"])) ) {
    //   document.querySelector('#root').classList.add('disabled');
    // }

    return isOpenAchievedProgress ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>
            658 863 happy users like you achieved their goals using our programs
          </FirstTitle>
          <img className={styles.imgAchived} src={imgAchived} alt="chart" />
        </StepContentWrapper>

        { !has(data, ["ailments"]) ? (

          <div className={styles.achievedProgress_button_wrapper}>
          <div className={styles.achievedProgress_button}>
            <p>Progress {this.state.progress}%</p>
            <div
              style={this.state.scale}
              className={styles.achievedProgress_button_overlay}
            ></div>
          </div>
          </div>

          ) : (

          <div className={styles.achievedProgress_button_wrapper}>
          <div className={styles.achievedProgress_button} onClick={onToNextStep}>
          <p className={styles.achievedProgress_button_text}>Next</p>
            <div
              style={this.state.scale}
              className={styles.achievedProgress_button_overlay}
            ></div>
          </div>
          </div>

        ) }

      </div>
    ) : null;
  }
}

export default withStepsState(AchievedProgress);
