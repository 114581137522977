import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./visibleResultProgress.module.scss";

import img1 from "./img/img1.jpg";
import img2 from "./img/img2.jpg";
import stars from "./img/stars.svg";

class VisibleResultProgress extends Component {
  constructor() {
    super();
    this.meaning = 0.0;
    this.state = {
      isOpenVisibleResultProgress: false,
      progress: 0,
      intervalStarted: false,
      scale: { transform: `scaleX(${this.meaning})` },
    };

    this.interval = null;
    this.indexVisibleResultProgress = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.visibleResultProgress) &&
      !this.state.isOpenVisibleResultProgress
    )
      this.setState({ isOpenVisibleResultProgress: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData, onToNextStep } = this.props;
    const { isOpenVisibleResultProgress } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.visibleResultProgress,
      isOpen: isOpenVisibleResultProgress,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenVisibleResultProgress && !has(data, ["visibleResultProgress"]))
      onSetStepData("visibleResultProgress");
    updateOpenStepState({ params });

    if (isOpenVisibleResultProgress && this.indexVisibleResultProgress === 0) {
      this.indexVisibleResultProgress = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen();
      });
    }
    if (!isOpenVisibleResultProgress) {
      this.indexVisibleResultProgress = 0;
    }

    if (isOpenVisibleResultProgress && !has(data, ["visibleResultProgress"])) {
      let progressTime = 5; // seconds

      if (isOpenVisibleResultProgress) {
        let startTime = new Date();
        if (this.state.intervalStarted) return;
        this.interval = setInterval(() => {
          this.meaning = this.meaning + 0.01;
          this.setState({
            progress: ++this.state.progress,
            scale: { transform: `scaleX(${this.meaning})` },
          });
          if (this.state.progress >= 100) {
            clearInterval(this.interval);
            this.meaning = 0;
            onToNextStep();
            this.dataLayerAddEvent(startTime);
            setTimeout(() => {
              this.setState({
                progress: 0,
                intervalStarted: false,
              });
            }, 1000);
          }
        }, progressTime * 10);
        this.setState({ intervalStarted: true });
      }
    }

    // if (isOpenVisibleResultProgress && has(data, ["old"])) {

    //     console.log(has(data, ["old"]));
    //     progressIsDone(1);
    // }
  }

  //  progressIsDone(progressPercentage) {
  //   this.setState({
  //     progress: progressPercentage,
  //   });
  // }

  handleChangeOpen(state) {
    this.setState({
      isOpenVisibleResultProgress: state,
    });
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      event: "user_answers",
      quiz_question: "infoBeforeAfter",
      quiz_answer: "continueAuto",
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "infoBeforeAfter",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenVisibleResultProgress } = this.state;
    const params = {
      className: "wrapperVisibleResultProgress",
      currentIndex: currentIndex,
      stepIndex: steps.visibleResultProgress,
    };

    // if ( ((Object.keys(data)[currentIndex]) === "visibleResultProgress")  &&  (!has(data, ["old"])) ) {
    //   document.querySelector('#root').classList.add('disabled');
    // }

    return isOpenVisibleResultProgress ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <div className={styles.visibleResultProgress_title}>
            Get visible results in
            <br />
            several weeks
          </div>
          {data.sex.value && data.sex.value === "female" ? (
            <div className={styles.visibleResultProgress_card}>
              <img
                className={styles.visibleResultProgress_card_image}
                src={img1}
                alt="female image"
              />
              <div className={styles.visibleResultProgress_card_info}>
                <div className={styles.visibleResultProgress_card_name}>
                  Lisa : -5.2 kg
                </div>
                <img
                  className={styles.visibleResultProgress_card_rate}
                  src={stars}
                  alt="five stars"
                />
              </div>
              <div className={styles.visibleResultProgress_card_text}>
                For a long time I wasn’t able to maintain healthy weight
                long-term. I’d lose some pounds but then I’d get like a double
                back. I was not in the best place. Not until I found this app.
                It’s far more than just a weight loss app, it’s a change in
                lifestyle.
              </div>
            </div>
          ) : (
            <div className={styles.visibleResultProgress_card}>
              <img
                className={styles.visibleResultProgress_card_image}
                src={img2}
                alt="male image"
              />
              <div className={styles.visibleResultProgress_card_info}>
                <div className={styles.visibleResultProgress_card_name}>
                  Patrick M : -4.7 kg
                </div>
                <img
                  className={styles.visibleResultProgress_card_rate}
                  src={stars}
                  alt="five stars"
                />
              </div>
              <div className={styles.visibleResultProgress_card_text}>
                Because of COVID I fell into a routine where I’d put on a bunch
                of weight, and mentally and physically I was tired all the time.
                I’ve discovered this running app and it’s been a life saver. I
                was amazed to see a weight loss of about 10 pounds in the first
                month of using it.
              </div>
            </div>
          )}
        </div>

        { !has(data, ["old"]) ? (

          <div className={styles.visibleResultProgress_button_wrapper}>
            <div className={styles.visibleResultProgress_button}>
              <p>Progress {this.state.progress}%</p>
              <div
                style={this.state.scale}
                className={styles.visibleResultProgress_button_overlay}
              ></div>
            </div>
          </div>

        ) : (

          <div className={styles.visibleResultProgress_button_wrapper}>
          <div className={styles.visibleResultProgress_button} onClick={onToNextStep}>
            <p className={styles.visibleResultProgress_button_text}>Next</p>
            <div
              style={this.state.scale}
              className={styles.visibleResultProgress_button_overlay}
            ></div>
          </div>
        </div>

        ) }
      </div>
    ) : null;
  }
}

export default withStepsState(VisibleResultProgress);
