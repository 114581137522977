import React from 'react'

import styles from "./radioСhoice.module.scss"

const RadioСhoice = props => {
  const { isChecked, onClick } = props

  return (
    <button
      className={isChecked ? styles.radioСhoiceChecked : styles.radioСhoice}
      onClick={onClick}
    >
      {props.children}
    </button>
  )
}

export default React.memo(RadioСhoice)