import React from 'react'

import styles from "./radioImageButton.module.scss"

const RadioImageButton = props => {
  const { isChecked, onClick, image } = props

  return (
    <button
      className={isChecked ? styles.radioImageButtonChecked : styles.radioImageButton}
      onClick={onClick}
    >
      {props.children}

      <span className={styles.itemImage} style={{ backgroundImage: `url(${image})` }}></span>
    </button>
  )
}

export default React.memo(RadioImageButton)