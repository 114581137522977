import React from 'react'
import { Link } from "react-router-dom"

import { withMenuState } from "./../../containers/containerMenuState"

import styles from "./menuItem.module.scss"

const MenuItem = props => {
  const { to, title, onCloseMenu } = props

  return (
    <div className={styles.menuItem}>
      <Link
        to={to}
        className={styles.menuItemLink}
        onClick={onCloseMenu}
      >
        {title}
      </Link>
    </div>
  )
}

export default withMenuState(MenuItem)