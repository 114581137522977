import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import StepControl from "../stepControl";
import GoalsPlanNextStepButton from "./goalsPlanNextStepButton";
import styles from "./goalsPlan.module.scss";
import imgMap from "./img/map.jpg";

class GoalsPlan extends Component {
  constructor() {
    super();

    this.state = {
      isOpenGoalsPlan: false,
      dataLayerAddEvent: this.dataLayerAddEvent.bind(this),
    };
    this.indexGoalsPlan = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.goalsPlan) &&
      !this.state.isOpenGoalsPlan
    )
      this.setState({ isOpenGoalsPlan: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenGoalsPlan } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.goalsPlan,
      isOpen: isOpenGoalsPlan,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenGoalsPlan && this.indexGoalsPlan === 0) {
      this.indexGoalsPlan = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenGoalsPlan) {
      this.indexGoalsPlan = 0;
    }

    if (isOpenGoalsPlan && !has(data, ["goalsPlan"])) onSetStepData("goalsPlan");

    updateOpenStepState({ params });
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenGoalsPlan: state,
    });
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'infoUsersAchieveGoals2',
      'quiz_answer': 'continue',
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "infoUsersAchieveGoals2",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenGoalsPlan } = this.state;
    const params = {
      className: "wrapperGoalsPlan",
      currentIndex: currentIndex,
      stepIndex: steps.goalsPlan,
    };

    return isOpenGoalsPlan ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <h1 className={styles.title}>
            You’re in a good hands
          </h1>
          <img className={styles.img} src={imgMap} alt="map"/>
          <h2 className={styles.subtitle}>
            <span>658 863 happy users</span><br/>
            reached their goals thanks to our Running app
          </h2>
        </div>
        <StepControl>
          <GoalsPlanNextStepButton dataLayerAddEvent={this.state.dataLayerAddEvent}>Next</GoalsPlanNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(GoalsPlan);
