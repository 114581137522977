import { createReducer } from '../utils/helpers';
import {
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_APP_STEPS,
  CLOSE_APP_STEPS,
} from '../actions/appStateActions'

const initialState = {
  isOpenMenu: false,
  isOpenAppSteps: false,
}

const appStateReducer = createReducer(initialState, {
  [OPEN_APP_STEPS.REQUEST]: state => {
    return {
      ...state,
      isOpenAppSteps: true,
    }
  },
  [CLOSE_APP_STEPS.REQUEST]: state => {
    return {
      ...state,
      isOpenAppSteps: false,
    }
  },
  [OPEN_MENU.REQUEST]: state => {
    return {
      ...state,
      isOpenMenu: true,
    }
  },
  [CLOSE_MENU.REQUEST]: state => {
    return {
      ...state,
      isOpenMenu: false,
    }
  },
})

export default appStateReducer
