import { createReducer } from '../utils/helpers';
import { steps } from "./../shared/static.steps"
import {
  TO_NEXT_STEP,
  TO_PREV_STEP,
  OPEN_LOADER,
  OPEN_RESULT_PAGE,
  SET_STEP_DATA,
  UPDATE_STEP_DATA,
  OPEN_SUCCESS_STORY,
  CLOSE_SUCCESS_STORY,
  OPEN_CHART,
  CLOSE_CHART,
  VALIDATE_STEP_DATA,
  CLEAR_STEP_DATA,
  CLEAR_STEP,
} from '../actions/stepsStateActions'

// const testData = {
//   currentWeight: {
//     value: 55,
//     type: "kg",
//   },
//   goalWeight: {
//     value: 55,
//     type: "kg",
//   },
//   name: {
//     value: "nic",
//   },
//   workHard: {
//     value: "minimum",
//     a: 1.55,
//   },
//   height: {
//     value: 155,
//     type: 'cm',
//   },
//   sex: {
//     value: "female",
//   },
//   old: {
//     value: 55,
//   },
// }

const initialState = {
  currentIndex: 0,
  openLoaderResult: false,
  openResultPage: false,
  data: {},
}

const stepsStateReducer = createReducer(initialState, {
  [TO_NEXT_STEP.REQUEST]: state => {
    const nextIndex = state.currentIndex + 1
    const stepsLenght = Object.keys(steps).length
    const rootBlock = document.querySelector('#root') || null;

    function blockPrevButton() {
      if(rootBlock) {
        rootBlock.classList.add('disabled');
        setTimeout(() => {
          rootBlock.classList.remove('disabled')
        }, 700);
      }
    }
    blockPrevButton();

    return nextIndex <= stepsLenght - 1
      ? {
        ...state,
        currentIndex: nextIndex,
      }
      : state
  },
  [TO_PREV_STEP.REQUEST]: state => {
    const nextIndex = state.currentIndex - 1
    const rootBlock = document.querySelector('#root') || null;

    function blockPrevButton() {
      if(rootBlock) {
        rootBlock.classList.add('disabled');
        setTimeout(() => {
          rootBlock.classList.remove('disabled')
        }, 700);
      }
    }
    blockPrevButton();

    return nextIndex >= 0
      ? {
        ...state,
        currentIndex: nextIndex,
      }
      : state
  },
  [OPEN_LOADER.REQUEST]: state => ({
    ...state,
    openLoaderResult: true,
  }),
  [OPEN_SUCCESS_STORY.REQUEST]: state => ({
    ...state,
    openSuccessStory: true,
  }),
  [CLOSE_SUCCESS_STORY.REQUEST]: state => ({
    ...state,
    openSuccessStory: false,
  }),
  [OPEN_CHART.REQUEST]: state => ({
    ...state,
    openChart: true,
  }),
  [CLOSE_CHART.REQUEST]: state => ({
    ...state,
    openChart: false,
  }),
  [OPEN_RESULT_PAGE.REQUEST]: state => ({
    ...state,
    openResultPage: true,
  }),
  [SET_STEP_DATA.REQUEST]: (state, { payload: { name } }) => {
    var nextStepItems = { ...state.data }
    var nextNameData = {
      ...nextStepItems[name],
      value: null,
      validString: "",
      isValid: false,
    }

    return {
      ...state,
      data: {
        ...nextStepItems,
        [name]: nextNameData,
      }
    }
  },
  [UPDATE_STEP_DATA.REQUEST]: (state, { payload: { name, key, value } }) => {
    var nextStepItems = { ...state.data }
    var nextNameData = {
      ...nextStepItems[name],
      [key]: value,
    }

    return {
      ...state,
      data: {
        ...nextStepItems,
        [name]: nextNameData,
      }
    }
  },
  [VALIDATE_STEP_DATA.ERROR]: (state, { payload: { name, message } }) => {
    var nextStepItems = { ...state.data }
    var nextNameData = {
      ...nextStepItems[name],
      validString: message,
      isValid: false,
    }

    return {
      ...state,
      data: {
        ...nextStepItems,
        [name]: nextNameData,
      }
    }
  },
  [VALIDATE_STEP_DATA.SUCCESS]: (state, { payload: { name } }) => {
    var nextStepItems = { ...state.data }
    var nextNameData = {
      ...nextStepItems[name],
      validString: "",
      isValid: true,
    }

    return {
      ...state,
      data: {
        ...nextStepItems,
        [name]: nextNameData,
      }
    }
  },
  [CLEAR_STEP_DATA.REQUEST]: state => {
    return {
      ...state,
      currentIndex: 0,
      openLoaderResult: false,
      openResultPage: false,
      data: {},
    }
  },
  [CLEAR_STEP.REQUEST]: (state, { payload: { name } }) => {
    var nextStepItems = { ...state.data }
    var nextNameData = {
      value: null,
      validString: "",
      isValid: false,
    }

    return {
      ...state,
      data: {
        ...nextStepItems,
        [name]: nextNameData,
      }
    }
  },
})

export default stepsStateReducer
