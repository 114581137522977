import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { oldData } from "./static.oldData";
import { sexData } from "./../sex/static.sexData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./old.module.scss";

class Old extends Component {
  constructor() {
    super();

    this.state = {
      isOpenOld: false,
      itemsData: {},
    };
    this.indexOld = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.old) &&
      !this.state.isOpenOld
    )
      this.setState({ isOpenOld: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenOld } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.old,
      isOpen: isOpenOld,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenOld && !has(data, ["old"])) onSetStepData("old");

    updateOpenStepState({ params });

    if(isOpenOld && this.indexOld === 0) {
      this.indexOld = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }
    if(!isOpenOld) {
      this.indexOld = 0;
    }
  }

  handleChangeOpen(state) {
    const { data } = this.props;
    this.setState({
      isOpenOld: state,
      itemsData: this.switchResultItems(
        get(data, ["sex", "value"], sexData.female.value)
      ),
    });
  }

  switchResultItems(value) {
    switch (value) {
      case sexData.male.value:
        return oldData.male;
      case sexData.female.value:
        return oldData.female;
    }
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'age',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "age",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenOld, itemsData } = this.state;
    const params = {
      className: "wrapperOld",
      currentIndex: currentIndex,
      stepIndex: steps.old,
    };
    let startTime = new Date();

    return isOpenOld ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>What’s your age?</FirstTitle>

          {Object.keys(itemsData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["old", "value"], null) === itemsData[key].value
                }
                onClick={
                  get(data, ["old", "value"], null) !== itemsData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "old",
                        "value",
                        itemsData[key].value
                      )
                    : () => {}
                }
                img={true}
                onClickNextStep={onToNextStep !== false ? () => {

                  setTimeout(() => {
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(itemsData, [key, "title"]), startTime
                    )}, 400); } : () => {}
                 
                }
              >
                <div className={styles.buttonText}>
                  <div className={styles.title}>{itemsData[key].title}</div>
                </div>

                <img
                  className={styles.image}
                  src={itemsData[key].icon}
                  alt="icon"
                />
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(Old);
