export default function (inputNode) {
  let done = false;
  let interval, timeout;

  document.activeElement.blur();

  if (inputNode) {
    document.getElementById(inputNode).addEventListener("blur", () => {
      clearInterval(interval);
      timeout = setTimeout(() => {
        document.querySelector(".application").scrollTo(0, -50);
        window.scrollTo(0, -50);
      }, 100);
    });

    document.getElementById(inputNode).addEventListener("focus", () => {
      interval = setInterval(() => {
        if (done) return;
        if (window.scrollY !== 0) {
          window.scrollTo(0, 0);
        }

        if (window.scrollY === 0) {
          document.querySelector(".application").scrollTo(0, 50);
        }
      }, 1);
    });
  }

  if (inputNode === false) {
    console.log(`CLEAR`);
  }
}
