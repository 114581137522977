import React from 'react'

import Icon from "./../static/icon"

import styles from "./radioCheckButton.module.scss"
import blockContent from "./../../../utils/blockContent"

const RadioCheckButton = props => {
  const { isChecked, onClick, onClickNextStep, icon, identify, clickCheckerDisabled } = props



  return (
    <button
      className={isChecked ? styles.radioCheckButtonChecked : styles.radioCheckButton}
      onClick={() => {
        if(!clickCheckerDisabled) {
          blockContent();
        }
        // blockContent();
        onClick(); 
        if(onClickNextStep) {
          onClickNextStep();
        }
      }}
    >

      { icon &&
        <div className={styles.resultIconWrapper}>
          <img src={icon} className={`${identify}`}/>
        </div>
      }

      {props.children}

      {/* <span className={styles.container_styleIcon}>
        <Icon name="check" />
      </span> */}
    </button>
  )
}

export default React.memo(RadioCheckButton)