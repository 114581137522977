import React, { Component } from 'react'
import { get } from "lodash"

import { withStepsState } from "../../../shared/containers/containerStepsState"
import { withLoader } from "../../../shared/containers/containerLoader"

import { checkIsValidHelps } from "./static.helpsData"
import ButtonFill from "../../../shared/items/controls/buttonFill"
import styles from "./helps.module.scss";

class HelpsNextStepButton extends Component {
  componentDidUpdate() {
    const { data, onSetSuccessValidate, onSetFailValidate, helpsData } = this.props

    if (checkIsValidHelps(data) && !get(data, ["helps", "isValid"], false))
      onSetSuccessValidate("helps")

    if (!checkIsValidHelps(data) && get(data, ["helps", "isValid"], false))
      onSetFailValidate("helps")
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();
    let helps = [];

    if(this.props.data.helps.tips) {
      helps.push(this.props.helpsData.tips.title)
    }
    if(this.props.data.helps.seeing) {
      helps.push(this.props.helpsData.seeing.title)
    }
    if(this.props.data.helps.training) {
      helps.push(this.props.helpsData.training.title)
    }
    if(this.props.data.helps.supportive) {
      helps.push(this.props.helpsData.supportive.title)
    }
    if(this.props.data.helps.guidelines) {
      helps.push(this.props.helpsData.guidelines.title)
    }
    if(this.props.data.helps.appleRings) {
      helps.push(this.props.helpsData.appleRings.title)
    }

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'stayOnTrack',
      'quiz_answer': helps,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
  }

  render() {
    const { data, onToNextStep, startTimeHelps } = this.props;

    return (
      <div className={styles.helpsBth}>
        <ButtonFill disabled={!get(data, ["helps", "isValid"], false)} onClick={
            () => {
              onToNextStep(); 
              this.dataLayerAddEvent(startTimeHelps);
            }
        }>
          {this.props.children}
        </ButtonFill>
      </div>
    )
  }
}

export default withStepsState(HelpsNextStepButton)