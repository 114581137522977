import { isNull, get } from "lodash";

import ed1 from "./img/ed1.png";
import ed2 from "./img/ed2.png";
import ed3 from "./img/ed3.png";
import ed4 from "./img/ed4.png";
import ed5 from "./img/ed5.png";
import ed6 from "./img/ed6.png";
import ed7 from "./img/ed7.png";

export const checkIsValidImportantEvent = data => {
    return validateImportantEventStep(get(data, ["importantEvent", "value"], "")) && !data.importantEvent.isValid
}

export const validateImportantEventStep = value => {
    return !isNull(value)
}

export const importantEventData = {
    vacation: {
        value:"Vacation",
        title: "Vacation",
        icon: ed1,
    },
    wedding: {
        value:"Wedding",
        title: "Wedding",
        icon: ed2,
    },
    summer: {
        value:"Summer",
        title: "Summer",
        icon: ed3,
    },
    familyGathering: {
        value:"Family gathering",
        title: "Family gathering",
        icon: ed4,
    },
    birthday: {
        value:"Birthday party",
        title: "Birthday party",
        icon: ed5,
    },
    notOccasion: {
        value:"I don't have a specific occasion",
        title: "I don't have a specific occasion",
        icon: ed6,
    },
    other: {
        value:"Other",
        title: "Other",
        icon: ed7,
    },
}

export const defaultFasting = false