import React, { Component } from 'react'
import { get } from "lodash"

import {
  currentWeightTypeData,
  getValidateKgMessage,
  getValidateLbsMessage,
  checkIsValidLbsCurrentWeight,
  checkIsValidKgCurrentWeight,
  checkIsChoosedCurrentWeightType,
  isFailureLbsValidation,
  isFailureKgValidation,
} from "./static.currentWeightData"

import { withStepsState } from "../../../shared/containers/containerStepsState"

import ButtonFill from "../../../shared/items/controls/buttonFill"


class CurrentWeightNextStepButton extends Component {
  componentDidUpdate() {
    const { data } = this.props
    if (checkIsChoosedCurrentWeightType(data, currentWeightTypeData.lbs.value)) {
      this.checkvValidCurrentWeightLbs()
    }

    else if (checkIsChoosedCurrentWeightType(data, currentWeightTypeData.kg.value)) {
      this.checkvValidCurrentWeightKg()
    }
  }

  checkvValidCurrentWeightLbs() {
    const { data, onSetFailValidate, onSetSuccessValidate } = this.props

    if (isFailureLbsValidation(data))
      onSetFailValidate("currentWeight", getValidateLbsMessage(get(data, ["currentWeight", "value"], null)))

    else if (checkIsValidLbsCurrentWeight(data))
      onSetSuccessValidate("currentWeight")
  }

  checkvValidCurrentWeightKg() {
    const { data, onSetFailValidate, onSetSuccessValidate } = this.props

    if (isFailureKgValidation(data))
      onSetFailValidate("currentWeight", getValidateKgMessage(get(data, ["currentWeight", "value"], null)))

    else if (checkIsValidKgCurrentWeight(data))
      onSetSuccessValidate("currentWeight")
  }

  dataLayerAddEvent(answer, answerUnit, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'currentWeight',
      'quiz_answer': `${answer} ${answerUnit}`,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
    
  }

  render() {
    const { data, onToNextStep, startTimeCurrentWeight } = this.props;

    return (
      <ButtonFill disabled={!get(data, ["currentWeight", "isValid"], false)} onClick={
        () => {
            onToNextStep(); 
            this.dataLayerAddEvent(get(data, ["currentWeight", "value"]), get(data, ["currentWeight", "type"]), startTimeCurrentWeight)
          }
      }>
        {this.props.children}
      </ButtonFill>
    )
  }
}

export default withStepsState(CurrentWeightNextStepButton)