import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import MedicationsNextStepButton from "./medicationsNextStepButton";
import StepControl from "./../stepControl"
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { medicationsData } from "./static.medicationsData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./medications.module.scss";
import "./medications.scss";

class Medications extends Component {
  constructor() {
    super();

    this.state = {
      isOpenMedications: false,
    };
    this.indexMedications = 0;
    this.startTimeMedications = {};
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.medications) &&
      !this.state.isOpenMedications
    )
      this.setState({ isOpenMedications: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenMedications } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.medications,
      isOpen: isOpenMedications,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if(isOpenMedications && this.indexMedications === 0) {
      this.indexMedications = 1;
      this.startTimeMedications = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }

    if (isOpenMedications && !has(data, ["medications"])) onSetStepData("medications");

    updateOpenStepState({ params });
    if(!isOpenMedications) {
      this.indexMedications = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenMedications: state,
    });
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "medications",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData } = this.props;
    const { isOpenMedications } = this.state;
    const params = {
      className: "wrapperMedications",
      currentIndex: currentIndex,
      stepIndex: steps.medications,
    };

    return isOpenMedications ? (
      <div className={buildStepClassName({ params })}>
        <div className={styles.stepContentWrapper}>
          <FirstTitle>
            Are you taking any of the following supplements/ medications?
          </FirstTitle>
          {Object.keys(medicationsData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={get(data, ["medications", key], false)}
                onClick={
                  !get(data, ["medications", key], false)
                  ? onUpdateStepData.bind(this, "medications", key, true)
                  : onUpdateStepData.bind(this, "medications", key, false)
                }     
                icon={medicationsData[key].icon}
                identify={`medications${index}`}
                clickCheckerDisabled={true}
              >
                {medicationsData[key].title}
              </RadioCheckButton>
            );
          })}
        </div>
        <StepControl>
          <MedicationsNextStepButton
          startTimeMedications={this.startTimeMedications}
           medicationsData={medicationsData}>Next</MedicationsNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(Medications);
