import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  openApp as onOpenApp,
  closeApp as onCloseApp,
} from '../../actions/appStateActions'

const mapStateToProps = (state) => ({
  isOpenAppSteps: get(state, ["appState", "isOpenAppSteps"], false),
});

const mapDispatchToProps = {
  onOpenApp,
  onCloseApp,
}

export const withAppStepsState = Component => connect(mapStateToProps, mapDispatchToProps)(Component)