import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import RadioCheckButton from "../../../shared/items/controls/radioCheckButton";
import StepContentWrapper from "./../stepContentWrapper";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import { waterData } from "./static.waterData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import "./water.scss";
import blockContent from "./../../../utils/blockContent";

class Water extends Component {
  constructor() {
    super();

    this.state = {
      isOpenWater: false,
    };
    this.indexWater = 0;
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.currentIndex, steps.water) &&
      !this.state.isOpenWater
    )
      this.setState({ isOpenWater: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenWater } = this.state;
    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.water,
      isOpen: isOpenWater,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenWater && !has(data, ["water"])) onSetStepData("water");

    updateOpenStepState({ params });

    if(isOpenWater && this.indexWater === 0) {
      this.indexWater = 1;
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      }) 
    }
    if(!isOpenWater) {
      this.indexWater = 0;
    }

  }

  handleChangeOpen(state) {
    this.setState({
      isOpenWater: state,
    });
  }

  dataLayerAddEvent(answer, startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'waterIntake',
      'quiz_answer': answer,
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer);
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "waterIntake",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenWater } = this.state;
    const params = {
      className: "wrapperWater",
      currentIndex: currentIndex,
      stepIndex: steps.water,
    };
    let startTime = new Date();

    return isOpenWater ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          <FirstTitle>
            What’s your daily water intake?
          </FirstTitle>

          {Object.keys(waterData).map((key, index) => {
            return (
              <RadioCheckButton
                key={index}
                isChecked={
                  get(data, ["water", "value"], null) ===
                  waterData[key].value
                }
                onClick={
                  get(data, ["water", "value"], null) !==
                  waterData[key].value
                    ? onUpdateStepData.bind(
                        this,
                        "water",
                        "value",
                        waterData[key].value
                      )
                    : () => {}
                }
                icon={waterData[key].icon}
                identify={`water${index}`}
                onClickNextStep={onToNextStep !== false ? () => {
                  blockContent();
                  setTimeout(() => {
                    onToNextStep(); 
                    this.dataLayerAddEvent(get(waterData, [key, "title"]), startTime
                    )}, 400); } : () => {}
                }
              >
                {waterData[key].title}
              </RadioCheckButton>
            );
          })}
        </StepContentWrapper>
      </div>
    ) : null;
  }
}

export default withStepsState(Water);
