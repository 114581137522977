import { createActionTypesOf } from '../utils/helpers';

export const OPEN_MENU = createActionTypesOf("OPEN_MENU")
export const CLOSE_MENU = createActionTypesOf("CLOSE_MENU")
export const OPEN_APP_STEPS = createActionTypesOf("OPEN_APP_STEPS")
export const CLOSE_APP_STEPS = createActionTypesOf("CLOSE_APP_STEPS")

export const openApp = () => ({
  type: OPEN_APP_STEPS.REQUEST
})

export const closeApp = () => ({
  type: CLOSE_APP_STEPS.REQUEST
})

export const openMenu = () => ({
  type: OPEN_MENU.REQUEST,
})

export const closeMenu = () => ({
  type: CLOSE_MENU.REQUEST,
})