import React from 'react'

import styles from "./buttonFill.module.scss"

const ButtonFill = props => {
  const { onClick, disabled } = props

  return (
    <button
      className={styles.buttonFill}
      onClick={onClick}
      disabled={disabled}
    >
      {props.children}
    </button>
  )
}

export default React.memo(ButtonFill)