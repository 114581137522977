import { isNull, get } from "lodash"

import male from "./img/male2.png"
import female from "./img/female2.png"

export const checkIsValidSex = data => {
    return validateSexStep(get(data, ["sex", "value"], "")) && !data.sex.isValid
}

export const validateSexStep = value => {
    return !isNull(value)
}

export const sexData = {
    female: {
        value: "female",
        title: "Female",
        img: female
    },
    male: {
        value: "male",
        title: "Male",
        img: male
    },
}