import React, { Component } from "react";
import { get, has } from "lodash";
import { withStepsState } from "../../../shared/containers/containerStepsState";
import FirstTitle from "../../../shared/items/static/firstTitle";
import InputDateWrapper from "../../../shared/items/controls/inputDateWrapper";
import StepContentWrapper from "./../stepContentWrapper";
import ImportantEventDateNextStepButton from "./importantEventDateNextStepButton";
import {
  isCurrentStep,
  buildStepClassName,
  updateOpenStepState,
} from "../../../shared/services/service.step";
import StepControl from "./../stepControl";
import { importantEventDateData } from "./static.importantEventDateData";
import { steps } from "../../../shared/static.steps";
import "./../../../shared/services/service.step.scss";
import styles from "./importantEventDate.module.scss";

const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};
const disableDate = disablePastDate();

class ImportantEventDate extends Component {
  constructor() {
    super();

    this.state = {
      isOpenImportantEventDate: false,
      type: 'date',
    };
    this.indexImportantEventDate = 0;
    this.startTimeImportantEventDate = {};
  }

  componentDidMount() {
    if (
      isCurrentStep(this.props.ImportantEventDate, steps.sex) &&
      !this.state.isOpenImportantEventDate
    )
      this.setState({ isOpenImportantEventDate: true });
  }

  componentDidUpdate(prevProps) {
    const { currentIndex, data, onSetStepData } = this.props;
    const { isOpenImportantEventDate } = this.state;

    if(isOpenImportantEventDate && this.indexImportantEventDate === 0) {
      this.indexImportantEventDate = 1;
      this.startTimeImportantEventDate = new Date();
      setTimeout(() => {
        this.dataLayerAddEventScreen()
      });
    }

    const params = {
      currentIndex: currentIndex,
      prevIndex: prevProps.currentIndex,
      stepIndex: steps.importantEventDate,
      isOpen: isOpenImportantEventDate,
      handleChange: this.handleChangeOpen.bind(this),
    };

    if (isOpenImportantEventDate && !has(data, ["importantEventDate"])) {
      onSetStepData("importantEventDate");
    } 
    
    updateOpenStepState({ params });
    if(!isOpenImportantEventDate) {
      this.indexImportantEventDate = 0;
    }
  }

  handleChangeOpen(state) {
    this.setState({
      isOpenImportantEventDate: state,
    });
  }

  dataLayerAddEvent(startTime) {
    let endTime = new Date();

    window.dataLayer.push({
      'event': 'user_answers',
      'quiz_question': 'EventDate',
      'quiz_answer': 'skip question',
      screen_time: (endTime.getTime() - startTime.getTime()) / 1000,
    });
    // console.log(window.dataLayer)
  }

  dataLayerAddEventScreen() {
    window.dataLayer.push({
      event: "screen_change_event",
      screen_open: "EventDate",
    });
  }

  render() {
    const { currentIndex, data, onUpdateStepData, onToNextStep } = this.props;
    const { isOpenImportantEventDate } = this.state;
    const params = {
      className: "wrapperImportantEventDate",
      currentIndex: currentIndex,
      stepIndex: steps.importantEventDate,
    };

    return isOpenImportantEventDate ? (
      <div className={buildStepClassName({ params })}>
        <StepContentWrapper>
          {(data.importantEvent.value === "Vacation" || data.importantEvent.value === "Summer" || data.importantEvent.value === "I don't have a specific occasion" || data.importantEvent.value === "Other") ? 
          (
          <h1 className={styles.firstTitle}>
            When do you want your body to change?
          </h1>
          ) : 
          (data.importantEvent.value === "Birthday party") 
          ? (
          <h1 className={styles.firstTitle}>
            When is the birthday?
          </h1>) :
            (
          <h1 className={styles.firstTitle}>
            When’s the <span>{data.importantEvent.value}</span> day?
          </h1>)}
          <div className={styles.container_input}>
            <InputDateWrapper
              data={data}
              name="importantEventDate"
              keyName="value"
              onChange={onUpdateStepData.bind(this)}
              valueStore={get(data, ["importantEventDate", "value"], '') ===
              this.state.value}
              type={this.state.type}
              min={disableDate}
            />
          </div>

        </StepContentWrapper>
        <div className={styles.nextStepText}>
          <button
          onClick={() => {
            setTimeout(() => {
            onToNextStep();
            this.dataLayerAddEvent(this.startTimeImportantEventDate);
            }, 100);
          } }
          >Skip this question</button>
        </div>
        <StepControl>
          <ImportantEventDateNextStepButton
          startTimeImportantEventDate={this.startTimeImportantEventDate}
          >Done</ImportantEventDateNextStepButton>
        </StepControl>
      </div>
    ) : null;
  }
}

export default withStepsState(ImportantEventDate);
